import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react'
import axios from "axios"
import { Trans } from "react-i18next"

// Dispatcher
import mapDispatchToProps from '../../reducers/dispatcher';

// Utils
import urls from '../../utils/urls';

// Components
import EmptyBoard from '../board/EmptyBoard';
import CashflowPopup from '../board/cashflowBlocks/CashflowPopup';

class OptionalCashFlow extends React.Component {

	state = {
		isModalOpen: false,
		questionsList: [],
		selectedQuestion: {},
		correctAnswers: {}
	}

	onToggleModal = () => {
		this.setState({ isModalOpen: !this.state.isModalOpen })
	}

	onSelectQuestion = (questionIndex) => {
		this.setState({ selectedQuestion: this.state.questionsList[questionIndex] })
	}

	componentDidMount() {
		this.fetchQuestions()
	}

	fetchQuestions = () => {
		axios.get(urls.backendURL + "/cashflow/list?langId=" + this.props.view.selectedLanguage)
			.then(response => {
				this.setState({ questionsList: response.data.data })
				//this.props.restoreStrategies(response.data.data);
			})
	}

	onQuestionAnswer = (cash, profit) => {
		let correctAnswers = this.state.correctAnswers
		if (this.state.selectedQuestion.cashCorrectAnswer === cash && this.state.selectedQuestion.profitCorrectAnswer === profit) {
			correctAnswers[this.state.selectedQuestion.id] = "correct"
		}else{
			correctAnswers[this.state.selectedQuestion.id] = "wrong"
		}

		this.setState({ correctAnswers: correctAnswers })
	}



	render() {
		const dir = this.props.view.selectedLanguage == 8 ? "rtl" : "ltr"
		return (
			<Container className="borardContainer">
				<div align={dir === "rtl" ? "right" : "left"} className="optionalCashflowTitle">
					<Trans>CashFlow__How does changes affect cash and profit?</Trans>
				</div>
				<EmptyBoard
					correctAnswers={ this.state.correctAnswers }
					onSelectQuestion={ this.onSelectQuestion.bind(this) }
					onToggleModal={ this.onToggleModal }
				/>

				<CashflowPopup 
					selectedQuestion={ this.state.selectedQuestion }
					isModalOpen={ this.state.isModalOpen } 
					onToggleModal={ this.onToggleModal }
					onQuestionAnswer={ this.onQuestionAnswer }
				/>
			</Container>
		);
	}
}
/*

*/

const mapStateToProps = (state) => {
	return {
		view: state.view
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionalCashFlow);
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// Components
import DroppableField from '../../common/DroppableField';

// CSS
import '../../../css/boardLenders.css';

class DevelopmentBlocks extends React.Component {

	draggingHandler = (objectType, isDragging, data) => {
		// Update Socket
		//RealtimeConnection.containerDragging("development", objectType, isDragging, data)
	}

	addContainer = (alias, id, coins) => {
		this.props.addContainer("development", alias, id, coins);
	}

	removeContainer = (alias, id) => {
		this.props.removeContainer("development", alias, id);
	}

	removeModifiedContainer = (alias, id) => {
		this.props.removeModifiedContainer("development", alias, id);
	}

	revertModifiedContainer = (alias, id) => {
		this.props.revertModifiedContainer("development", alias, id);
	}

	addCoin = (alias, id, value) => {
		this.props.addCoin("development", alias, id, value);
	}

	removeCoin = (alias, id) => {
		this.props.removeCoin("development", alias, id);
	}

	increaseCoin = (alias, id) => {
		this.props.increaseCustomCoin("development", alias, id, 1);
	}

	decreaseCoin = (alias, id) => {
		this.props.decreaseCustomCoin("development", alias, id, 1);
	}

	render() {
		return (
			<>
				<div className="overheadBlock">
					<Grid className="overheadGrid">
						<Grid.Row>
							<Grid.Column style={{ display: "flex", flexDirection: "row-reverse" }}>
								<Popup position='top center' content={ <Trans>Glossary__Overhead</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row style={{ marginTop: "0px !important" }}>
							<Grid.Column>
								{
									<DroppableField 
										id={ -1 }
										field={ this.props.board.development.overhead }
										block={ "development" }
										alias={ "overhead" }
										drawCoins={ false }
										maxCoins={ 100 }
										addContainer={ this.addContainer.bind(this) }
										removeContainer={ this.removeContainer.bind(this) }
										removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
										revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
										addCoin={ this.addCoin.bind(this) }
										removeCoin={ this.removeCoin.bind(this) }
										increaseCoin={ this.increaseCoin.bind(this) }
										decreaseCoin={ this.decreaseCoin.bind(this) }
										isDraggingHandler={ this.draggingHandler }
										boardState={ this.props.board }
									/>
								}
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<p><Trans>Board__Overhead</Trans></p>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
				<div className="developmentBlock">
					<Grid className="developmentBlockGrid">
						<Grid.Row>
							<Grid.Column style={{ display: "flex", flexDirection: "row-reverse" }}>
								<Popup position='top center' content={ <Trans>Glossary__Development</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row style={{ marginTop: "0px !important" }}>
							<Grid.Column>
								{
									<DroppableField 
										id={ -1 }
										field={ this.props.board.development.development }
										block={ "development" }
										alias={ "development" }
										drawCoins={ false }
										maxCoins={ 100 }
										addContainer={ this.addContainer.bind(this) }
										removeContainer={ this.removeContainer.bind(this) }
										removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
										revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
										addCoin={ this.addCoin.bind(this) }
										removeCoin={ this.removeCoin.bind(this) }
										increaseCoin={ this.increaseCoin.bind(this) }
										decreaseCoin={ this.decreaseCoin.bind(this) }
										isDraggingHandler={ this.draggingHandler }
										boardState={ this.props.board }
									/>
								}
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<p><Trans>Board__Development</Trans></p>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DevelopmentBlocks);
import React from 'react';
import { connect } from 'react-redux';
import { Image, Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// CSS
import '../../../css/boardInvestment.css';

// Images
import bigYellowArrow from "../../../assets/Images/big-yellow-arrow.png";
import propertyIcon from "../../../assets/Images/property-icon.svg";
import gearIcon from "../../../assets/Images/gear-icon.svg";
import diamondIcon from "../../../assets/Images/diamond-icon.svg";

class Investment extends React.Component {

	render() {
		const dir = this.props.view.selectedLanguage == 8 ? "rtl" : "ltr"

		return (
			<div className="yellowBlock">
				<div className="blockArrow">
					<Image src={ bigYellowArrow } />
				</div>
				<div className="atp-grid">
					<div className="atp-row">
						<div className="atp-column" style={{ flex: 1, maxWidth: "130.5px" }}>
							<div className="atp-row">
								<div className="atp-column" style={{ flex: 1 }}>
									<p className="blockTitle"><Trans>Board__Investments</Trans></p>
								</div>
							</div>
							<div className="atp-row developmentRow">
								<div className="atp-column" style={{ flex: 1 }}>
									<Popup position='top center' content={ <Trans>Glossary__Investments</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ width: "50px", display: "flex" }}>
							<div className={ `droppableField` }></div>
						</div>
						<div className="atp-column" style={{ flex: 1, maxWidth: "130.5px" }}>
							<div className="atp-row" style={{ marginTop: "3px" }}>
								<div className="atp-column" style={{ display: "flex", height: "28px", paddingRight: "10px" }}>
									<Image src={ propertyIcon } className="propertyIcon" style={{ marginRight: "10px" }} />
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle"><Trans>Board__Real Property</Trans></p>
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ width: "50px", display: "flex" }}>
							<div className={ `droppableField` } style={{ position: 'relative' }}>
								<div className="plantField cashflowPointer" onClick={ () => { this.props.onSelectQuestion(7); this.props.onToggleModal() }}>
									{
									(this.props.view.showCashflowExerciseResult) ?
										<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["8"] }` }>8</div>
										:
										<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["8"]) ? 'finished': '' }` }>8</div>
									}
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ flex: 1, maxWidth: "130.5px" }}>
							<div className="atp-row" style={{ marginTop: "3px" }}>
								<div className="atp-column" style={{ display: "flex", height: "28px", paddingRight: "10px", position: 'relative' }}>
									<Image src={ gearIcon } style={{ marginRight: "10px" }} />
									<div className="dottedBorder"></div>

									<div className="plantText cashflowPointer" onClick={ () => { this.props.onSelectQuestion(6); this.props.onToggleModal() }}>
										{
										(this.props.view.showCashflowExerciseResult) ?
											<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["7"] }` }>7</div>
											:
											<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["7"]) ? 'finished': '' }` }>7</div>
										}
									</div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle"><Trans>Board__Plant & Equipment</Trans></p>
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ width: "50px", display: "flex" }}>
							<div className={ `droppableField` }></div>
						</div>
						<div className="atp-column" style={{ flex: 1 }}>
							<div className="atp-row" style={{ marginTop: "3px" }}>
								<div className="atp-column" style={{ display: "flex", height: "28px" }}>
									<Image src={ diamondIcon } style={{ marginRight: "10px" }} />
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle"><Trans>Board__Intellectual Property</Trans></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		view: state.view,
		session: state.session
	}
}

export default connect(mapStateToProps)(Investment);
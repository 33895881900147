import React from "react";
import { Icon, Image } from 'semantic-ui-react'

// Images
import popupCloseIcon from '../../assets/Images/popup-close-icon.svg';

// Components
import DraggableCustomValueContainer from "./DraggableCustomValueContainer";

export default function PopupCoins(props) {

	let dragObjData = {}
	dragObjData.alias = props.alias
	dragObjData.id = props.id
	dragObjData.coins = props.coins

	return (
		<div className="coinsPopup">
			<div className="closeContainer">
				<div className="closeBtn" onClick={ () => { 
					if(props.canPlayerDrag) {
						props.revertModifiedContainer(); 
						props.closePopup();
						props.isDraggingHandler("POPUP_CONTAINER", false, dragObjData); 
					}
				} }>
					<Image src={popupCloseIcon} />
				</div>
			</div>
			<div className="valuesActions">
				<div className="decreaseContainer">
					<div className="decreaseBtn" onClick={ () => { if(props.canPlayerDrag && props.coins > 0) props.decreaseCoin(props.alias, props.id) } }>
						<Icon name="minus" className={ (props.coins === 0) ? "disable" : "" } />
					</div>
				</div>
				<div className="containerValueContainer">
					<DraggableCustomValueContainer 
						id={ props.id }
						block={ props.block }
						alias={ props.alias }
						coins={ props.coins } 
						removeModifiedContainer={ props.removeModifiedContainer }
						closePopup={ props.closePopup }
						canPlayerDrag={ props.canPlayerDrag }
						isDraggingHandler={ props.isDraggingHandler }
						canDropAction={ props.canDropAction }
					/>
				</div>
				<div className="increaseContainer">
					<div className="increaseBtn" onClick={ () => { if(props.canPlayerDrag) props.increaseCoin(props.alias, props.id) } }>
						<Icon name="plus" />
					</div>
				</div>
			</div>
			<div className="confirmAction">

			</div>
		</div>
	);
}
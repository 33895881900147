import React from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Image, Icon, Popup, Button } from 'semantic-ui-react'
import { Trans } from 'react-i18next';

// Dispatcher
import mapDispatchToProps from '../../reducers/dispatcher';

// Components
import Production from './cashflowBlocks/Production';
import Development from './blocks/Development';
import Investment from './cashflowBlocks/Investment';
import Cash from './cashflowBlocks/Cash';
import Owners from './cashflowBlocks/Owners';
import Lenders from './cashflowBlocks/Lenders';
import Community from './cashflowBlocks/Community';
import CostOfSales from './cashflowBlocks/CostOfSales';
import Depreciation from './cashflowBlocks/Depreciation';
import DevelopmentBlocks from './cashflowBlocks/DevelopmentBlocks';
import Customers from './cashflowBlocks/Customers';

// images
import blueArrow from '../../assets/Images/blue-arrow.png';
import orangeArrow from '../../assets/Images/orange-arrow.png';

import URLs from '../../utils/urls';

class EmptyBoard extends React.Component {

	render() {
		const dir = this.props.view.selectedLanguage == 8 ? "rtl" : "ltr"
		return (
			<div dir={dir} className="atp-grid boardGrid">
				<div className="atp-row boardGridRow">
					<div className="atp-column leftBlockContainer">
						<div className={dir === "ltr" ? "deliveryArrow" : "deliveryArrowAr" }>
							<Image src={ blueArrow } />
						</div>
						<div className={dir === "ltr" ? "deliveryDotted" : "deliveryDottedAr" }></div>
						<div className={dir === "ltr" ? "productionDotted2" : "productionDotted2Ar" }></div>
						<div className={dir === "ltr" ? "productionDotted" : "productionDottedAr" }></div>
						<div className={dir === "ltr" ? "developmentDotted" : "developmentDottedAr" }></div>
						<div className={dir === "ltr" ? "depreciationDotted" : "depreciationDottedAr" }></div>
						<div className={dir === "ltr" ? "purchaseDotted" : "purchaseDottedAr" }></div>
						<div className={dir === "ltr" ? "purchaseText" : "purchaseTextAr"}><Trans>Board__Purchase</Trans></div>
						<div className={dir === "ltr" ? "purchaseArrow" : "purchaseArrowAr" }>
							<Image src={ orangeArrow } />
						</div>
						<div className="cashflowPointer" onClick={ () => { this.props.onSelectQuestion(12); this.props.onToggleModal() }}>
							{
							(this.props.view.showCashflowExerciseResult) ?
								<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["13"] }` }>13</div>
								:
								<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["13"]) ? 'finished': '' }` }>13</div>
							}
						</div>
						<div className="suppliesContainer">
							<div className="materialContainer">
								<div className="matrialBlock">
									<div className={ `materialDrop` }></div>
								</div>
								<p><Trans>Board__Buy Material</Trans></p>
							</div>
							<div className="suppliesText">
								<p>
									<Trans>Board__Suppliers</Trans> 
									<Popup position='top center' content={ <Trans>Glossary__Suppliers</Trans> } size='mini' trigger={<Icon name='plus circle' style={{ marginTop: "10px" }} />} />
								</p>
							</div>
						</div>
					</div>
					<div className="atp-column centerBlockContainer">
						<Container className="centerBlock">
							<Production 
								correctAnswers={ this.props.correctAnswers }
								onSelectQuestion={ this.props.onSelectQuestion }
								onToggleModal={ this.props.onToggleModal }
							/>
							<Development />
							<Investment 
								correctAnswers={ this.props.correctAnswers }
								onSelectQuestion={ this.props.onSelectQuestion }
								onToggleModal={ this.props.onToggleModal }
							/>
							<div className="whiteBlock">
								{
									(window.isDemo) ?
										<div className="demoWatermark">
											<h3><Trans>Board__Demo Version</Trans></h3>
											<p><Trans>Board__This version is not for sale.</Trans></p>
										</div>
										:
										null
								}
								{
									(window.partnerLogo) ?
										<div className="demoWatermark">
											<img src={ URLs.backendURL + window.partnerLogo } />
										</div>
										:
										null
								}
							</div>
							<Cash 
								correctAnswers={ this.props.correctAnswers }
								onSelectQuestion={ this.props.onSelectQuestion }
								onToggleModal={ this.props.onToggleModal }
							/>
						</Container>
					</div>
					<div className="atp-column rightBlocksContainers">
						<div className="rightBlocks">
							<div className="blocksContainer">
								<div className="cashflowPointer" onClick={ () => { this.props.onSelectQuestion(4); this.props.onToggleModal() }}>
									{
									(this.props.view.showCashflowExerciseResult) ?
										<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["5"] }` }>5</div>
										:
										<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["5"]) ? 'finished': '' }` }>5</div>
									}
								</div>
								<div className={dir === "ltr" ? "productionArrow" : "productionArrowAr" }>
									<Image src={ blueArrow } />
								</div>
								<div className={dir === "ltr" ? "productionArrowDotted" : "productionArrowDottedAr" }></div>
								<div className={dir === "ltr" ? "productionDotted" : "productionDottedAr" }></div>
								<div className={dir === "ltr" ? "developmentDotted" : "developmentDottedAr" }></div>
								<div className={dir === "ltr" ? "depreciationDotted" : "depreciationDottedAr" }></div>
								<Grid columns={3}>
									<Grid.Column className="depreciationContainer">
										<Depreciation />
									</Grid.Column>
									<Grid.Column className="developmentContainer">
										<DevelopmentBlocks 
											correctAnswers={ this.props.correctAnswers }
											onSelectQuestion={ this.props.onSelectQuestion }
											onToggleModal={ this.props.onToggleModal }
										/>
									</Grid.Column>
									<Grid.Column className="costOfSalesContainer">
										<CostOfSales />
									</Grid.Column>
								</Grid>
							</div>
							<div className="salesDashContainer">
								<div className="borderBox"></div>
								<div className="purchaseArrow">
									<Image src={ orangeArrow } />
								</div>
							</div>
						</div>
						<Customers />
					</div>
				</div>
				<div className="atp-row boardGridRow">
					<div className="atp-column leftBlockContainer"></div>
					<div className="atp-column centerBlockContainer bottomCenterBlock">
						<div className="atp-grid">
							<div className="atp-row">
								<div className="atp-column" style={{ width: "33%" }}>
									<Owners />
								</div>
								<div className="atp-column" style={{ width: "1%" }}></div>
								<div className="atp-column" style={{ width: "66%" }}>
									<div className="atp-grid">
										<div className="atp-row">
											<div className="atp-column" style={{ width: "50%" }}>
												<Lenders 
													correctAnswers={ this.props.correctAnswers }
													onSelectQuestion={ this.props.onSelectQuestion }
													onToggleModal={ this.props.onToggleModal }
												/>
											</div>
											<div className="atp-column" style={{ width: "3px" }}></div>
											<div className="atp-column" style={{ width: "50%" }}>
												<Community />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="atp-column boardCelemiLogo">
						<Button 
							onClick={ () => this.props.showCashflowAnswer() }
							disabled={ (this.props.session.enableOptionalCashFlowShowResultBtn) ? false : true }
						>
							<Trans>CashFlow__See answers</Trans>
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		view: state.view,
		session: state.session
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyBoard);
const state = {
	dupont: {
		sales: "63",
		costOfGoodsSold: "24",
		contribution: "39",
		commonCosts: "27",
		operatingProfit: "12",
		interestAndTaxes: "8",
		netProfit: "4",
		returnONAssets: "12%",
		returnONEquity: "10%",
		currentAssets: "71",
		fixedAssets: "28",
		totalAssets: "99",
		equity: "37",
		liabilities: "62",
	},
	indicators: {
		contribution: "39",
		contributionSales: "63",
		operatingProfit: "12",
		operatingSales: "63",
		equity: "37",
		assets: "99"
	}
}

export default state;

import React from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// CSS
import '../../../css/boardLenders.css';

class Depreciation extends React.Component {

	render() {
		return (
			<div className="depreciationBlock">
				<Grid className="depreciationGrid">
					<Grid.Row>
						<Grid.Column style={{ display: "flex", flexDirection: "row-reverse" }}>
							<Popup position='top center' content={ <Trans>Glossary__Depreciation</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{ marginTop: "0px !important" }}>
						<Grid.Column>
							<div className={ `droppableField` }></div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<p><Trans>Board__Depreciation</Trans></p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Depreciation);
import React from 'react';
import { connect } from 'react-redux';
import { Header, Icon, Image, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next';

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// Components
import DroppableField from '../../common/DroppableField';

// CSS
import '../../../css/boardProduction.css';

// Images
import bigBlueArrow from "../../../assets/Images/big-blue-arrow.png";

class Production extends React.Component {

	draggingHandler = (objectType, isDragging, data) => {
		// Update Socket
		//RealtimeConnection.containerDragging("production", objectType, isDragging, data)
	}

	addContainer = (alias, id, coins) => {
		this.props.addContainer("production", alias, id, coins);
	}

	removeContainer = (alias, id) => {
		this.props.removeContainer("production", alias, id);
	}

	removeModifiedContainer = (alias, id) => {
		this.props.removeModifiedContainer("production", alias, id);
	}

	revertModifiedContainer = (alias, id) => {
		this.props.revertModifiedContainer("production", alias, id);
	}

	addCoin = (alias, id, value) => {
		this.props.addCoin("production", alias, id, value);
	}

	removeCoin = (alias, id) => {
		this.props.removeCoin("production", alias, id);
	}

	increaseCoin = (alias, id) => {
		this.props.increaseCustomCoin("production", alias, id, 1);
	}

	decreaseCoin = (alias, id) => {
		this.props.decreaseCustomCoin("production", alias, id, 1);
	}

	render() {

		const dir = this.props.selectedLanguage == 8 ? "rtl" : "ltr"

		return (
			<div className="blueBlock" style={{ position: "relative" }}>
				<Header as='h4' className="blockTitle white">
					<Trans>Board__Production</Trans>
					<Popup position='top center' content={ <Trans>Glossary__Production</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
				</Header>
				<div className={dir === "ltr" ? "blockArrow" : "blockArrowAr"}>
					<Image src={bigBlueArrow} />
				</div>
				<div className="atp-grid">
					<div className="atp-row" style={{ height: "20px" }}>
						<div className="atp-column" style={{ width: "128px" }}></div>
						<div className="atp-column" style={{ flex: 1 }}></div>
						{
							(this.props.board.production.manufacturing.length === 0) ?
							<>
								<div className="atp-column" style={{ width: "calc(100% - 332px)"}}>
									<p className="smallSubtitle">
										<Trans>Board__Manufacturing & Assembly</Trans>
									</p>
								</div>
							</>
							:
							<>
								<div className="atp-column" style={{ width: "178px", display: "flex" }}>
									<div style={{ width: "calc(50% - 13px)"}}>
										<p className="smallSubtitle"><Trans>Board__Manufacturing</Trans></p>
									</div>
									<div style={{ width: "26px" }}></div>
									<div style={{ width: "calc(50% - 13px)"}}>
										<p className="smallSubtitle"><Trans>Board__Assembly</Trans></p>
									</div>
								</div>
							</>
						}
						<div className="atp-column" style={{ flex: 1 }}></div>
						<div className="atp-column" style={{ width: "204px" }}></div>
					</div>
					<div className="atp-row">
						<div 
							className="atp-column" 
							style={
								dir === "ltr"
								? { width: 128, marginRight: 5 }
								: { width: 128, marginLeft: 5 }
							}>
							<div className="centerContainers">
								<div className="stockOfMatrialsContainer">
									{(
										this.props.board.production.stockOfMaterial.map( (field, index) => {
											return <DroppableField
												ltr={dir === "ltr"} 
												key={ index }
												field={ field }
												id={ index }
												block={ "production" }
												alias={ "stockOfMaterial" }
												drawCoins={ true }
												maxCoins={ 4 }
												addContainer={ this.addContainer.bind(this) }
												removeContainer={ this.removeContainer.bind(this) }
												removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
												revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
												addCoin={ this.addCoin.bind(this) }
												removeCoin={ this.removeCoin.bind(this) }
												increaseCoin={ this.increaseCoin.bind(this) }
												decreaseCoin={ this.decreaseCoin.bind(this) }
												isDraggingHandler={ this.draggingHandler }
												boardState={ this.props.board }
											/>
										})
									)}
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ flex: 1, display: "flex" }}>
							<div className="dottedBorder"></div>
						</div>
						{
							(this.props.board.production.manufacturing.length === 0) ?
							<>
								<div className="atp-column" style={{ width: "140px"}}>
									<div className="centerContainers">
										<div className="manufacturingContainer">
											{(
												this.props.board.production.assembly.map( (field, index) => {
													return <DroppableField 
														ltr={dir === "ltr"} 
														key={ index }
														field={ field }
														id={ index }
														block={ "production" }
														alias={ "assembly" }
														drawCoins={ true }
														maxCoins={ 4 }
														addContainer={ this.addContainer.bind(this) }
														removeContainer={ this.removeContainer.bind(this) }
														removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
														revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
														addCoin={ this.addCoin.bind(this) }
														removeCoin={ this.removeCoin.bind(this) }
														increaseCoin={ this.increaseCoin.bind(this) }
														decreaseCoin={ this.decreaseCoin.bind(this) }
														isDraggingHandler={ this.draggingHandler }
														boardState={ this.props.board }
													/>
												})
											)}
										</div>
									</div>
								</div>
							</>
							:
							<>
								<div className="atp-column" style={{ width: "178px", display: "flex" }}>
									<div style={{ width: "calc(50%- 13px)" }}>
										<div className="centerContainers">
											<div className="manufacturingContainer">
												{(
													this.props.board.production.manufacturing.map( (field, index) => {
														return <DroppableField
															ltr={dir === "ltr"}
															key={ index }
															field={ field }
															id={ index }
															block={ "production" }
															alias={ "manufacturing" }
															drawCoins={ true }
															maxCoins={ 4 }
															addContainer={ this.addContainer.bind(this) }
															removeContainer={ this.removeContainer.bind(this) }
															removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
															revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
															addCoin={ this.addCoin.bind(this) }
															removeCoin={ this.removeCoin.bind(this) }
															increaseCoin={ this.increaseCoin.bind(this) }
															decreaseCoin={ this.decreaseCoin.bind(this) }
															isDraggingHandler={ this.draggingHandler }
															boardState={ this.props.board }
														/>
													})
												)}
											</div>
										</div>
									</div>
									<div style={{ width: "26px", display: "flex" }}>
										<div className="dottedBorder"></div>
									</div>
									<div style={{ width: "calc(50% - 13px)"}}>
										<div className="centerContainers">
											<div className="manufacturingContainer">
												{(
													this.props.board.production.assembly.map( (field, index) => {
														return <DroppableField
															ltr={dir === "ltr"}
															key={ index }
															field={ field }
															id={ index }
															block={ "production" }
															alias={ "assembly" }
															drawCoins={ true }
															maxCoins={ 4 }
															addContainer={ this.addContainer.bind(this) }
															removeContainer={ this.removeContainer.bind(this) }
															removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
															revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
															addCoin={ this.addCoin.bind(this) }
															removeCoin={ this.removeCoin.bind(this) }
															increaseCoin={ this.increaseCoin.bind(this) }
															decreaseCoin={ this.decreaseCoin.bind(this) }
															isDraggingHandler={ this.draggingHandler }
															boardState={ this.props.board }
														/>
													})
												)}
											</div>
										</div>
									</div>
								</div>
							</>
						}
						<div className="atp-column" style={{ flex: 1, display: "flex" }}>
							<div className="dottedBorder"></div>
						</div>
						<div 
							className="atp-column" 
							style={
								dir === "ltr" 
								? { width: 204, marginLeft: 5 }
								: { width: 204, marginRight: 5 }
							}
						>
							<div className="centerContainers">
								<div className="stockOfMatrialsContainer" style={{ position: "relative" }}>
									{(
										this.props.board.production.finishedGoods.map( (field, index) => {
											return (
												<>
													{
														(index === 4 && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "productionBlock" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step > 0) ?
															<div className="redIndicator2"></div>
															:
															null
													}
													<DroppableField 
														ltr={dir === "ltr"}
														key={ index }
														field={ field }
														id={ index }
														block={ "production" }
														alias={ "finishedGoods" }
														drawCoins={ true }
														maxCoins={ 4 }
														addContainer={ this.addContainer.bind(this) }
														removeContainer={ this.removeContainer.bind(this) }
														removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
														revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
														addCoin={ this.addCoin.bind(this) }
														removeCoin={ this.removeCoin.bind(this) }
														increaseCoin={ this.increaseCoin.bind(this) }
														decreaseCoin={ this.decreaseCoin.bind(this) }
														isDraggingHandler={ this.draggingHandler }
														boardState={ this.props.board }
													/>
												</>
											)
										})
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="atp-row" style={{ height: "25px" }}>
						<div className="atp-column" style={{ width: "128px", display: "flex" }}>
							<p className="containerText"><Trans>Board__Stock of Materials</Trans></p>
						</div>
						<div className="atp-column" style={{ flex: 1 }}></div>
						{
							(this.props.session.year === 2) ?
							<>
								<div className="atp-column" style={{ width: "calc(100% - 332px)", display: "flex" }}>
									<p className="centerContainerText"><Trans>Board__Work in progress</Trans></p>
								</div>
							</>
							:
							<>
								<div className="atp-column" style={{ width: "178px", display: "flex" }}>
									<p className="centerContainerText"><Trans>Board__Work in progress</Trans></p>
								</div>
							</>
						}
						<div className="atp-column" style={{ flex: 1 }}></div>
						<div className="atp-column" style={{ width: "204px", display: "flex" }}>
							<p className="containerText"><Trans>Board__Stock of finished goods</Trans></p>
						</div>
					</div>
				</div>

				{
					(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "productionBlock") ?
						<div className="guidedTourPopup">
							<div className="upArrow"></div>
							<h4>{ this.props.guidedTour.steps[9].title }</h4>
							<p>
								{ this.props.guidedTour.steps[9].message }
							</p>
							<div className="actions">
								{
									(this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 0) ?
										<div className="nextBtnContainer2" onClick={ () => this.props.nextGuidedTour() }>Try it</div>
										:
										null
								}
							</div>
						</div>
						:
						null
				}
				{
					(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "productionBlock" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step > 0) ?
						<div className="guidedTourPopup right2">
							<div className="upArrow custom"></div>
							<h4>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h4>
							<p>
								{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }
							</p>
							<div className="actions">
								{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
								{
									(this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 1 && this.props.board.production.goodsSold[8].coins === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
										<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
										:
										<div className="nextBtnContainer opacity"></div>
								}
							</div>
						</div>
						:
						null
				}
				{
					(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sequence" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 1) ?
						<div className="sequenceGuidedTourPopup">
							<div className="leftArrow"></div>
							<p>
								{ this.props.guidedTour.steps[1].message }
							</p>
							<div className="actions">
								{
									(this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 1 && 
										<>
											{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
											<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
										</>
									)
								}
							</div>
						</div>
						:
						null
				}
				{
					(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sequence2" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 0) ?
						<div className="sequenceGuidedTourPopup2">
							<div className="bottomArrow"></div>
							<p>
								{ this.props.guidedTour.steps[5].message }
							</p>
							<div className="actions">
								{
									(this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 0 && 
										<>
											{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
											<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
										</>
									)
								}
							</div>
						</div>
						:
						null
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board,
		session: state.session,
		selectedLanguage: state.view.selectedLanguage,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Production);
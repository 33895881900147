import React from "react";
import { connect } from "react-redux";
import { Image, Form, Icon, Loader } from "semantic-ui-react";
import Linkify from "react-linkify";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

// Dispatcher
import mapDispatchToProps from "../../reducers/dispatcher";

// Images
import closeIcon from "../../assets/Images/close-icon@2x.png";

class Chat extends React.Component {
	constructor(props) {
		super(props);
		this.state = { chatMessege: "", page: 0 };
	}
	scrollToBottom = () => {
		this.messagesEnd.scrollIntoView({ behavior: "smooth" });
	};

	componentDidMount() {
		this.scrollToBottom();

		this.setState({
			currentTeamID: 0,
			currentUsername: "Player",
			currentSession: 0,
		});

		//this.props.getChats({ teamId, sessionId, page: this.state.page });
	}

	componentDidUpdate() {
		//this.scrollToBottom();
	}
	onChatClosePress = () => {
		this.props.updateChatViewStatus(false);
	}

	handleChange = (e) => {
		this.setState({ chatMessege: e.target.value });
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({ chatMessege: "" });
	};

	scrollUp = () => {
		let page = this.state.page + 1;
		//this.props.getChats({ teamId, sessionId, page: page });
		this.setState({ page });
	};

	render() {
		return (
			<div className={`chatContainer ${this.props.showChat}`}>
				<div className="chatGrid">
					<div className="financial-header chat-header">
						<div className="title-container">
							<div
								className="closeBtn"
								onClick={this.onChatClosePress.bind(this)}
							>
								<Image src={closeIcon} />
							</div>
							<h3>Chat</h3>
						</div>
					</div>
					<div className="chatMessagesContainer">
						<InfiniteScroll
							dataLength={this.props.chats.chat.length}
							next={this.scrollUp}
							inverse={true} //
							hasMore={this.props.chats.hasMore}
							loader={<Loader active />}
							scrollableTarget="scrollableDiv"
						>
							{this.props.chats.chat !== undefined
								? this.props.chats.chat.map((chat, index) => (
										<div
											key={ index }
											className={`ChatBox ${
												chat.username !== window.playerName
													? "leftChatBox"
													: "rightChatBox"
											}`}
										>
											<div className="userName">{chat.username}</div>

											<Linkify>
												{" "}
												<p className="chatMsg">{chat.chatMessege}</p>
											</Linkify>
											<span className="msgDate">
												{moment(chat.date).format("h:mma")}
											</span>
										</div>
									))
								: ""}
							<div
								style={{ float: "left", clear: "both" }}
								ref={(el) => {
									this.messagesEnd = el;
								}}
							/>
						</InfiniteScroll>
					</div>

					<div className="ChatInput">
						<Form onSubmit={this.handleSubmit}>
							<Form.Group>
								<Form.Input
									placeholder="Write a messege.."
									style={{ width: "230px", height: " 46px" }}
									autoComplete="off"
									name="messege"
									value={this.state.chatMessege}
									onChange={this.handleChange}
									icon={
										<Icon
											name="send"
											style={{ top: "10px" }}
											inverted
											circular
											link
											onClick={this.handleSubmit}
										/>
									}
								/>
							</Form.Group>
						</Form>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		chats: state.chat,
		view: state.view
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

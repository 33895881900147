import React from 'react';
import { connect } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// CSS
import '../../../css/boardCommunity.css';

class Community extends React.Component {

	render() {
		return (
			<div className="communityBlock">
				<div className="atp-grid" style={{ height: "100%" }}>
					<div className="atp-row">
						<div className="atp-column" style={{ width: "67%" }}>
							<div className="blockTitle"><Trans>Board__The Community</Trans></div>
						</div>
						<div className="atp-column" style={{ width: "33%", display: "flex" }}>
							<div className={ `droppableField` }></div>
						</div>
					</div>
					<div className="atp-row">
						<div className="atp-column" style={{ width: "67%" }}>
							<Popup position='top center' content={ <Trans>Glossary__The Community</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
						</div>
						<div className="atp-column flexColumn" style={{ width: "33%" }}>
							<p><Trans>Board__Taxes</Trans></p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Community);
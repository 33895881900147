import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid } from "semantic-ui-react";
import { Trans } from "react-i18next";

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';
import RealtimeConnection from '../../../utils/RealtimeConnection';

class IndicatorsFirstYear extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	onFieldChange = (field, value) => {
		if (this.props.session.selectedFormYear.title === this.props.session.year.title) {
			this.props.updateFormWithKey("BusinessReview", "indicators", field, value)

			RealtimeConnection.updateForm(
				this.props.session.year.title,
				"BusinessReview",
				this.props.businessReview,
				true,
				true
			);
		}
	}

	render() {
		let lockForm = false
		if (this.props.session.selectedFormYear.title !== this.props.session.year.title) {
			lockForm = true
		}

		let validateForm = {
			contribution: {
				isValid: true,
				value: this.props.businessReview.indicators.contribution
			},
			contributionSales: {
				isValid: true,
				value: this.props.businessReview.indicators.contributionSales
			},
			operatingProfit: {
				isValid: true,
				value: this.props.businessReview.indicators.operatingProfit
			},
			operatingSales: {
				isValid: true,
				value: this.props.businessReview.indicators.operatingSales
			}
		}

		let playersFormLength = []
		const playerIds = Object.keys(this.props.session.playersForm);
		playerIds.forEach( id => {
			if (this.props.session.playersForm[id].BusinessReview && this.props.session.playersForm[id].BusinessReview.form.indicators) {
				playersFormLength.push(id)
			}
		})
		

		if (this.props.businessReview && this.props.businessReview.indicators && this.props.session.team.SessionPlayers) {
			const playersForm = this.props.session.playersForm;
			for (const playerId in playersForm) {
				if (playersForm[playerId].BusinessReview && playersForm[playerId].BusinessReview.form.indicators) {
					for (const field in playersForm[playerId].BusinessReview.form.indicators) {
						if (validateForm[field] && validateForm[field].value !== "" && playersForm[playerId].BusinessReview.form.indicators[field] !== "") {
							if (parseFloat(validateForm[field].value) !== parseFloat(playersForm[playerId].BusinessReview.form.indicators[field])) {
								validateForm[field].isValid = false;
							}
						}
					}
				}
			}
			
		}

		let contributePercentage = 0
		let operatingPercentage = 0
		if (this.props.businessReview.indicators.contribution !== "" && this.props.businessReview.indicators.contributionSales !== "") {
			contributePercentage = parseFloat(this.props.businessReview.indicators.contribution) / parseFloat(this.props.businessReview.indicators.contributionSales)
			contributePercentage *= 100;
			contributePercentage = parseInt(contributePercentage)
		}
		if (this.props.businessReview.indicators.operatingProfit !== "" && this.props.businessReview.indicators.operatingSales !== "") {
			operatingPercentage = parseFloat(this.props.businessReview.indicators.operatingProfit) / parseFloat(this.props.businessReview.indicators.operatingSales)
			operatingPercentage *= 100;
			operatingPercentage = parseInt(operatingPercentage)
		}


		return (
			<Grid className="indicatorsGrid"  style={{ minHeight: "500px" }}>
				<Grid.Row>
					<Grid.Column width={ 6 } className="indicator1stCol">
						<div className="text">
							<Trans>Forms__In a business meeting at the end of the year, two major questions were discussed:</Trans>
							{" "}
						</div>
						<div className="text">
							• <Trans>Forms__How well have the assets been used?</Trans>
						</div>
						<div className="text">
							• <Trans>Forms__How much has the owners’ value grown</Trans>
						</div>
						<div className="text">
							<p><Trans>Forms__How would you answer these questions?</Trans></p>
						</div>
					</Grid.Column>
					<Grid.Column width={ 4 } className="indicator2ndCol1stYr">
						<span><Trans>Forms__Return on Assets</Trans> = </span>
						<div style={{ height: "174px" }}></div>
						<span><Trans>Forms__Return on Equity</Trans> = </span>
					</Grid.Column>
					<Grid.Column width={ 6 } className="indicator3rdCol">
						<div className="colEquation year1">
							<div>
								<p><Trans>Forms__Operating profit (EBIT)</Trans></p>
								<hr style={{ border: "1px solid" }} />
								<p><Trans>Forms__Total assets</Trans></p>
							</div>
							<div>
								<span>
									<input 
										className={ `indicatorField ${ (!validateForm.contribution.isValid) ? "error" : "" }` }
										value={ this.props.businessReview.indicators.contribution }
										onChange={ e => this.onFieldChange("contribution", e.target.value) } 
										disabled={ lockForm }
									/>
								</span>
								<hr style={{ border: "1px solid" }} />
								<span>
									<input 
										className={ `indicatorField ${ (!validateForm.contributionSales.isValid) ? "error" : "" }` }
										value={ this.props.businessReview.indicators.contributionSales }
										onChange={ e => this.onFieldChange("contributionSales", e.target.value) } 
										disabled={ lockForm }
									/>
								</span>
							</div>
							<div style={{ width: "60px" }}>
								<span> = { contributePercentage } %</span>
							</div>
						</div>
						<div className="colEquation" style={{ height: "100px" }}></div>
						<div className="colEquation year1">
							<div>
								<p><Trans>Forms__Net profit</Trans></p>
								<hr style={{ border: "1px solid" }} />
								<p><Trans>Forms__Total equity</Trans></p>
							</div>
							<div>
								<span>
									<input 
										className={ `indicatorField ${ (!validateForm.operatingProfit.isValid) ? "error" : "" }` }
										value={ this.props.businessReview.indicators.operatingProfit }
										onChange={ e => this.onFieldChange("operatingProfit", e.target.value) } 
										disabled={ lockForm }
									/>
								</span>
								<hr style={{ border: "1px solid" }} />
								<span>
									<input 
										className={ `indicatorField ${ (!validateForm.operatingSales.isValid) ? "error" : "" }` }
										value={ this.props.businessReview.indicators.operatingSales }
										onChange={ e => this.onFieldChange("operatingSales", e.target.value) } 
										disabled={ lockForm }
									/>
								</span>
							</div>
							<div style={{ width: "60px" }}>
								<span> = { operatingPercentage } %</span>
							</div>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		businessReview: state.form.BusinessReview
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorsFirstYear);

import React from "react";
import { connect } from "react-redux";

import "../css/guidedTourStart.css";
import mapDispatchToProps from "../reducers/dispatcher";

import checkMarkImg from "../assets/Images/checkmark.svg";

const GuidedTourEnd = ({ guidedTourView }) => {

    const refreshAction = () => {
        window.location.reload()
    }
    
    return (
        <div className="guidedtour-intro-container">
            <div className="content-container">
                <div className="img-container">
                    <img src={checkMarkImg} alt="loginImg" />
                </div>
                <div className="text-container">
                    <p className="header">Guided tour completed – good job!</p>
                    <p className="text">
                        Now you know the basic controls for your session, so that you can focus on learning and getting a better understanding of business finance through managing Apples & Oranges Inc.
                    </p>
                    <p className="text">
                        We're looking forward to having you onboard. See you in the session!
                    </p>
                    <button className="repeat-guided-tour" onClick={refreshAction}>Take the tour again</button>
                    <div className="closeBrowserTxt">Take the tour again or close browser</div>
                </div>
            </div>
        </div>
    );
};
export default connect(null, mapDispatchToProps)(GuidedTourEnd);

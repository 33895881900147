import React from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// Components
import DroppableField from '../../common/DroppableField';

// CSS
import '../../../css/boardOwners.css';

class Owners extends React.Component {

	draggingHandler = (objectType, isDragging, data) => {
		// Update Socket
		//RealtimeConnection.containerDragging("owners", objectType, isDragging, data)
	}

	addContainer = (alias, id, coins) => {
		this.props.addContainer("owners", alias, id, coins);
	}

	removeContainer = (alias, id) => {
		this.props.removeContainer("owners", alias, id);
	}

	removeModifiedContainer = (alias, id) => {
		this.props.removeModifiedContainer("owners", alias, id);
	}

	revertModifiedContainer = (alias, id) => {
		this.props.revertModifiedContainer("owners", alias, id);
	}

	addCoin = (alias, id, value) => {
		this.props.addCoin("owners", alias, id, value);
	}

	removeCoin = (alias, id) => {
		this.props.removeCoin("owners", alias, id);
	}

	increaseCoin = (alias, id) => {
		this.props.increaseCustomCoin("owners", alias, id, 1);
	}

	decreaseCoin = (alias, id) => {
		this.props.decreaseCustomCoin("owners", alias, id, 1);
	}

	render() {
		return (
			<div className="ownersBlock">
				<Grid columns={3}>
					<Grid.Row>
						<Grid.Column className="noColumnPadding">
							<div className="blockTitle"><Trans>Board__Owners</Trans></div>
						</Grid.Column>
						<Grid.Column className="center">
							{
								<DroppableField 
									id={ -1 }
									field={ this.props.board.owners.dividends }
									block={ "owners" }
									alias={ "dividends" }
									drawCoins={ false }
									maxCoins={ 100 }
									addContainer={ this.addContainer.bind(this) }
									removeContainer={ this.removeContainer.bind(this) }
									removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
									revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
									addCoin={ this.addCoin.bind(this) }
									removeCoin={ this.removeCoin.bind(this) }
									increaseCoin={ this.increaseCoin.bind(this) }
									decreaseCoin={ this.decreaseCoin.bind(this) }
									isDraggingHandler={ this.draggingHandler }
									boardState={ this.props.board }
								/>
							}
						</Grid.Column>
						<Grid.Column className="center">
							{
								<DroppableField 
									id={ -1 }
									field={ this.props.board.owners.equity }
									block={ "owners" }
									alias={ "equity" }
									drawCoins={ false }
									maxCoins={ 100 }
									addContainer={ this.addContainer.bind(this) }
									removeContainer={ this.removeContainer.bind(this) }
									removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
									revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
									addCoin={ this.addCoin.bind(this) }
									removeCoin={ this.removeCoin.bind(this) }
									increaseCoin={ this.increaseCoin.bind(this) }
									decreaseCoin={ this.decreaseCoin.bind(this) }
									isDraggingHandler={ this.draggingHandler }
									boardState={ this.props.board }
								/>
							}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column className="noColumnPadding">
							<Popup position='top center' content={ <Trans>Glossary__Owners</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
						</Grid.Column>
						<Grid.Column className="flexColumn">
							<p><Trans>Board__Dividends</Trans></p>
						</Grid.Column>
						<Grid.Column className="flexColumn">
							<p><Trans>Board__Equity</Trans></p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Owners);
import React from 'react'
import { connect } from 'react-redux'
import { Grid, Checkbox, Image } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Dispatcher
import mapDispatchToProps from '../../reducers/dispatcher';
import RealtimeConnection from '../../utils/RealtimeConnection';

// Images
import DisabledPlayerIcon from '../../assets/Images/disabled-player-icon.svg';
import EnabledPlayerIcon from '../../assets/Images/enabled-player-icon.svg';
import LockIcon from '../../assets/Images/form-lock-icon.svg';
import UnLockIcon from '../../assets/Images/form-unlock-icon.svg';
import formArrow from '../../assets/Images/form-arrow.svg';
import year1ImprovedFormArrow from '../../assets/Images/year-1-improved-arrow.svg';


class BalanceSheet extends React.Component {

	onFieldChange = (field, value) => {
		if (this.props.session.selectedFormYear.title === this.props.session.year.title) {
			this.props.updateForm("BalanceSheet", field, value)

			//if(this.props.session.isFormValidated) {
				RealtimeConnection.updateForm(
					this.props.session.year.title,
					"BalanceSheet",
					this.props.balanceSheet,
					this.props.session.isFormSubmitted.BalanceSheet,
					this.props.session.isFormValidated.BalanceSheet
				);
			//}
		}
	}
	
	onCheckboxChange = (event) => {
		if (this.props.session.selectedFormYear.title === this.props.session.year.title) {
			RealtimeConnection.updateForm(
				this.props.session.year.title,
				"BalanceSheet",
				this.props.balanceSheet,
				this.props.session.isFormSubmitted.BalanceSheet,
				!this.props.session.isFormValidated.BalanceSheet
			);

			this.props.updateValidatingStatus("BalanceSheet", !this.props.session.isFormValidated.BalanceSheet);
		}
	}

	onSubmitFormClicked = () => {
		if (this.props.session.selectedFormYear.title === this.props.session.year.title) {
			RealtimeConnection.updateForm(
				this.props.session.year.title,
				"BalanceSheet",
				this.props.balanceSheet,
				!this.props.session.isFormSubmitted.BalanceSheet,
				this.props.session.isFormValidated.BalanceSheet
			);

			this.props.updateSubmittingStatus("BalanceSheet", !this.props.session.isFormSubmitted.BalanceSheet);
		}
	}

	render() {
		const staticFormValue = this.props.session.forms.BalanceSheet;
		let lockForm = this.props.session.isFormSubmitted.BalanceSheet;

		if (this.props.session.selectedFormYear.title !== this.props.session.year.title) {
			lockForm = true
		}

		let validateForm = {
			cash: {
				isValid: true,
				value: this.props.balanceSheet.cash
			},
			accountRecievable: {
				isValid: true,
				value: this.props.balanceSheet.accountRecievable
			},
			materials: {
				isValid: true,
				value: this.props.balanceSheet.materials
			},
			workInProgress: {
				isValid: true,
				value: this.props.balanceSheet.workInProgress
			},
			finishedGoods: {
				isValid: true,
				value: this.props.balanceSheet.finishedGoods
			},
			totalCurrentAssets: {
				isValid: true,
				value: this.props.balanceSheet.totalCurrentAssets
			},
			plantAndEquipment: {
				isValid: true,
				value: this.props.balanceSheet.plantAndEquipment
			},
			realProperty: {
				isValid: true,
				value: this.props.balanceSheet.realProperty
			},
			totalFixedAssets: {
				isValid: true,
				value: this.props.balanceSheet.totalFixedAssets
			},
			assetsTotal: {
				isValid: true,
				value: this.props.balanceSheet.assetsTotal
			},
			shortTermLiabilities: {
				isValid: true,
				value: this.props.balanceSheet.shortTermLiabilities
			},
			longTermLiabilities: {
				isValid: true,
				value: this.props.balanceSheet.longTermLiabilities
			},
			totalLiabilities: {
				isValid: true,
				value: this.props.balanceSheet.totalLiabilities
			},
			beginningBalance: {
				isValid: true,
				value: this.props.balanceSheet.beginningBalance
			},
			netProfit: {
				isValid: true,
				value: this.props.balanceSheet.netProfit
			},
			totalEquity: {
				isValid: true,
				value: this.props.balanceSheet.totalEquity
			},
			liabilityTotal: {
				isValid: true,
				value: this.props.balanceSheet.liabilityTotal
			}
		}

		let playersFormLength = []
		const playerIds = Object.keys(this.props.session.playersForm);
		playerIds.forEach( id => {
			if (this.props.session.playersForm[id].BalanceSheet) {
				playersFormLength.push(id)
			}
		})
		

		let validateText = <Trans>Forms__Team alignment</Trans>;
		if (this.props.session.isFormValidated.BalanceSheet && this.props.session.team.SessionPlayers) {
			let numberOfErrors = 0
			const playersForm = this.props.session.playersForm;
			for (const playerId in playersForm) {
				if (playersForm[playerId].BalanceSheet && playersForm[playerId].BalanceSheet.isFormValidated) {
					for (const field in playersForm[playerId].BalanceSheet.form) {
						if (validateForm[field].value !== "" && playersForm[playerId].BalanceSheet.form[field] !== "") {
							if (parseFloat(validateForm[field].value) !== parseFloat(playersForm[playerId].BalanceSheet.form[field])) {
								validateForm[field].isValid = false;
								numberOfErrors++;
							}
						}
					}
				}
			}
			
			if (this.props.session.team.SessionPlayers.length === (playersFormLength.length + 1)) {
				if (numberOfErrors === 0) {
					validateText = <Trans>Forms__Team aligned</Trans>
				}else{
					validateText = <Trans>Forms__Team not aligned</Trans>
				}
			}else{
				validateText = <Trans>Forms__Aligning...</Trans>
			}
		}

		const dir = this.props.selectedLanguage == 8 ? "rtl" : "ltr"

		return (
			<div dir={dir}>
				<div className="financial-actions">
					<Grid>
						<Grid.Column width={ 9 } className="form-display-flex">
							<p style={{ textTransform: "uppercase" }} className="title big bold form-display-flex-v-center"><Trans>Forms__Assets</Trans></p>
						</Grid.Column>
						<Grid.Column width={ 1 }>&nbsp;</Grid.Column>
						<Grid.Column width={ 3 } className="columnBG">
							{
								(
									(this.props.session.selectedFormYear.title === "Year 1" && <p><Trans>Board__Year</Trans> 0</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 1 Improved" && <p><Trans>Forms__Before</Trans></p>)
									||
									(this.props.session.selectedFormYear.title === "Year 2" && <p><Trans>Board__Year</Trans> 1</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 3" && <p><Trans>Board__Year</Trans> 2</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 4" && <p><Trans>Board__Year</Trans> 3</p>)
								)
							}
						</Grid.Column>
						<Grid.Column width={ 3 }>
							{
								(
									(this.props.session.selectedFormYear.title === "Year 1" && <p><Trans>Board__Year</Trans> 1</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 1 Improved" && <p><Trans>Forms__After</Trans></p>)
									||
									(this.props.session.selectedFormYear.title === "Year 2" && <p><Trans>Board__Year</Trans> 2</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 3" && <p><Trans>Board__Year</Trans> 3</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 4" && <p><Trans>Board__Year</Trans> 4</p>)
								)
							}
						</Grid.Column>
					</Grid>
				</div>
				<div className="financial-values">
					<Grid columns={4}>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="borderTop form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Cash</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine borderTop form-display-flex">
										<p className="big center">{ staticFormValue.cash }</p>
									</Grid.Column>
									<Grid.Column className="borderTop"></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="borderTop form-display-flex">
										<p className="center">
											<input 
												value={ this.props.balanceSheet.cash }
												onChange={ (e) => this.onFieldChange("cash", e.target.value )}
												className={ `textField ${ (!validateForm.cash.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
									<Grid.Column className="borderTop"></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Accounts Receivable</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.accountRecievable }</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="center">
											<input 
												value={ this.props.balanceSheet.accountRecievable }
												onChange={ (e) => this.onFieldChange("accountRecievable", e.target.value )}
												className={ `textField ${ (!validateForm.accountRecievable.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Materials</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.materials }</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="center">
											<input
												value={ this.props.balanceSheet.materials }
												onChange={ (e) => this.onFieldChange("materials", e.target.value )}
												className={ `textField ${ (!validateForm.materials.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Work in progress</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.workInProgress }</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.balanceSheet.workInProgress }
												onChange={ (e) => this.onFieldChange("workInProgress", e.target.value )}
												className={ `textField ${ (!validateForm.workInProgress.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Finished goods</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.finishedGoods }</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="center">
											<input
												value={ this.props.balanceSheet.finishedGoods }
												onChange={ (e) => this.onFieldChange("finishedGoods", e.target.value )}
												className={ `textField ${ (!validateForm.finishedGoods.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="bottomLine form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__Total current assets</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">=</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="bottomLine fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="big bold center">{ staticFormValue.totalCurrentAssets }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.balanceSheet.totalCurrentAssets }
												onChange={ (e) => this.onFieldChange("totalCurrentAssets", e.target.value )}
												className={ `textField ${ (!validateForm.totalCurrentAssets.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Plant & equipment</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.plantAndEquipment }</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="center">
											<input
												value={ this.props.balanceSheet.plantAndEquipment }
												onChange={ (e) => this.onFieldChange("plantAndEquipment", e.target.value )}
												className={ `textField ${ (!validateForm.plantAndEquipment.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Real property</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.realProperty }</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="center">
											<input
												value={ this.props.balanceSheet.realProperty }
												onChange={ (e) => this.onFieldChange("realProperty", e.target.value )}
												className={ `textField ${ (!validateForm.realProperty.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="bottomLine form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__Total fixed assets</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">=</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="bottomLine columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="big bold center">{ staticFormValue.totalFixedAssets }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.balanceSheet.totalFixedAssets }
												onChange={ (e) => this.onFieldChange("totalFixedAssets", e.target.value )}
												className={ `textField ${ (!validateForm.totalFixedAssets.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__TOTAL</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">=</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="big bold center">{ staticFormValue.assetsTotal }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.balanceSheet.assetsTotal }
												onChange={ (e) => this.onFieldChange("assetsTotal", e.target.value )}
												className={ `textField ${ (!validateForm.assetsTotal.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
							</Grid.Column>
							<Grid.Column width={ 1 }>
							</Grid.Column>
							<Grid.Column width={ 3 }>
							</Grid.Column>
							<Grid.Column width={ 3 }>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
				<div className="financial-actions">
					<Grid>
						<Grid.Column width={ 9 } className="form-display-flex">
							<p className="title big bold form-display-flex-v-center"><Trans>Forms__Liabilities</Trans> & <Trans>Forms__Equity</Trans></p>
						</Grid.Column>
						<Grid.Column width={ 1 }>&nbsp;</Grid.Column>
						<Grid.Column width={ 3 } className="columnBG">
							{
								(
									(this.props.session.selectedFormYear.title === "Year 1" && <p><Trans>Board__Year</Trans> 0</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 1 Improved" && <p><Trans>Forms__Before</Trans></p>)
									||
									(this.props.session.selectedFormYear.title === "Year 2" && <p><Trans>Board__Year</Trans> 1</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 3" && <p><Trans>Board__Year</Trans> 2</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 4" && <p><Trans>Board__Year</Trans> 3</p>)
								)
							}
						</Grid.Column>
						<Grid.Column width={ 3 }>
							{
								(
									(this.props.session.selectedFormYear.title === "Year 1" && <p><Trans>Board__Year</Trans> 1</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 1 Improved" && <p><Trans>Forms__After</Trans></p>)
									||
									(this.props.session.selectedFormYear.title === "Year 2" && <p><Trans>Board__Year</Trans> 2</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 3" && <p><Trans>Board__Year</Trans> 3</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 4" && <p><Trans>Board__Year</Trans> 4</p>)
								)
							}
						</Grid.Column>
					</Grid>
				</div>
				<div className="financial-values">
					<Grid columns={4}>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex borderTop">
								<p className="big form-display-flex-v-center"><Trans>Forms__Short term liabilities</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine borderTop form-display-flex">
										<p className="big center">{ staticFormValue.shortTermLiabilities }</p>
									</Grid.Column>
									<Grid.Column className="borderTop"></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="borderTop form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.balanceSheet.shortTermLiabilities }
												onChange={ (e) => this.onFieldChange("shortTermLiabilities", e.target.value )}
												className={ `textField ${ (!validateForm.shortTermLiabilities.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
									<Grid.Column className="borderTop"></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Long term liabilities</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.longTermLiabilities }</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="center">
											<input
												value={ this.props.balanceSheet.longTermLiabilities }
												onChange={ (e) => this.onFieldChange("longTermLiabilities", e.target.value )}
												className={ `textField ${ (!validateForm.longTermLiabilities.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="bottomLine form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__Total Liabilities</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">=</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="bottomLine fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="big bold center">{ staticFormValue.totalLiabilities }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.balanceSheet.totalLiabilities }
												onChange={ (e) => this.onFieldChange("totalLiabilities", e.target.value )}
												className={ `textField ${ (!validateForm.totalLiabilities.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Beginning Balance</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.beginningBalance }</p>
									</Grid.Column>
									<Grid.Column style={{ display: "flex" }}>
										{
											(this.props.session.selectedFormYear.title === "Year 1 Improved") ?
												<Image src={ year1ImprovedFormArrow } style={{ margin: "auto" }} />
												:
												null
										}
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="center">
											<input
												value={ this.props.balanceSheet.beginningBalance }
												onChange={ (e) => this.onFieldChange("beginningBalance", e.target.value )}
												className={ `textField ${ (!validateForm.beginningBalance.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Net profit/loss this year</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.netProfit }</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="center">
											<input
												value={ this.props.balanceSheet.netProfit }
												onChange={ (e) => this.onFieldChange("netProfit", e.target.value )}
												className={ `textField ${ (!validateForm.netProfit.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="bottomLine form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__Total equity</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">=</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="bottomLine fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex" style={{ position: "relative" }}>
										{
											(this.props.session.selectedFormYear.title !== "Year 1 Improved") ?
												<Image src={ formArrow } style={{ position: "absolute", bottom: 25, left: 18 }} />
												:
												null
										}
										<p className="big bold center">{ staticFormValue.totalEquity }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.balanceSheet.totalEquity }
												onChange={ (e) => this.onFieldChange("totalEquity", e.target.value )}
												className={ `textField ${ (!validateForm.totalEquity.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__TOTAL</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">=</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="big bold center">{ staticFormValue.liabilityTotal }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.balanceSheet.liabilityTotal }
												onChange={ (e) => this.onFieldChange("liabilityTotal", e.target.value )}
												className={ `textField ${ (!validateForm.liabilityTotal.isValid) ? "error" : "" }` }
												disabled={ lockForm }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
				<div className="financial-validations">
					<div className="players-list">
						{
							(this.props.session.team.SessionPlayers) ?
								<>
								{
									this.props.session.team.SessionPlayers.map( (player, index) => {
										if(player.Player.id === window.playerId && this.props.session.isFormValidated.BalanceSheet) {
											return <Image src={ EnabledPlayerIcon } key={ index } />
										}else{
											if (this.props.session.playersForm[player.Player.id] && this.props.session.playersForm[player.Player.id].BalanceSheet) {
												return <Image src={ EnabledPlayerIcon } key={ index } />
											}else{
												return <Image src={ DisabledPlayerIcon } key={ index } />
											}
										}
									})
								}
								</>
								:
								null
						}
					</div>
					<div className="validateSwitch switch-container">
						<Checkbox onChange={ this.onCheckboxChange } toggle checked={ (this.props.session.isFormValidated.BalanceSheet) ? true : false } /> 
					</div>
					<div className={ `validationStatus ${ (this.props.session.isFormValidated.BalanceSheet) ? "validating" : "" }` }>
						{ validateText }
					</div>
					<div className="flex-space"></div>
					<div className="submit-form" onClick={ this.onSubmitFormClicked.bind(this) }>
						{
							(this.props.session.isFormSubmitted.BalanceSheet) ?
								<Image src={ LockIcon } />
								:
								<Image src={ UnLockIcon } />
						}
					</div>
				</div>
			</div>
		)
	}
}

//

const mapStateToProps = (state) => {
	return {
		balanceSheet: state.form.BalanceSheet,
		selectedLanguage: state.view.selectedLanguage
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheet);
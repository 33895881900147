const state = {
	dupont: {
		sales: "60",
		costOfGoodsSold: "24",
		contribution: "36",
		commonCosts: "25",
		operatingProfit: "11",
		interestAndTaxes: "5",
		netProfit: "6",
		returnONAssets: "17%",
		returnONEquity: "14%",
		currentAssets: "42",
		fixedAssets: "24",
		totalAssets: "66",
		equity: "43",
		liabilities: "23",
	},
	indicators: {
		contribution: "36",
		contributionSales: "60",
		operatingProfit: "11",
		operatingSales: "60",
		equity: "43",
		assets: "66"
	}
}

export default state;

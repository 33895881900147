import React from "react";

export default function DraggableCoin(props) {
	return (
		<>
			{
				(props.drawCoins) ?
					<div className="coin-img"></div>
					:
					<div className="coin-value">{ (props.value > 0) ? props.value : "" }</div>
			}
		</>
	);
}
import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Image, Icon } from 'semantic-ui-react'
import Modal from "react-modal";
import { Trans } from "react-i18next"

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// CSS
import '../../../css/boardProduction.css';

// Images

import cashFlowDown from "../../../assets/Images/cashflow-down-btn.svg";
import cashFlowDownSelected from "../../../assets/Images/cashflow-down-btn-selected.svg";
import cashFlowUp from "../../../assets/Images/cashflow-up-btn.svg";
import cashFlowUpSelected from "../../../assets/Images/cashflow-up-btn-selected.svg";
import cashFlowKeep from "../../../assets/Images/cashflow-keep-btn.svg";
import cashFlowKeepSelected from "../../../assets/Images/cashflow-keep-btn-selected.svg";
import correctAnswerFlag from "../../../assets/Images/correctAnswerFlag.svg";


const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -46%)",
		width: 615,
		minHeight: 364,
		padding: 0,
		border: "none",
		borderRadius: 0,
		backgroundColor: "#144E5D",
		overflow: "visible"
	},
};


class CashflowPopup extends React.Component {
	
	state = {
		selectedCashValue: "",
		selectedProfitValue: ""
	}

	// status = "down", "up", "same"
	onCashBtnClick = (status) => {
		this.setState({ selectedCashValue: status })
	}
	onProfitBtnClick = (status) => {
		this.setState({ selectedProfitValue: status })
	}

	onSubmit = () => {
		this.props.onQuestionAnswer(this.state.selectedCashValue, this.state.selectedProfitValue)
		this.props.updateCashflowAnswers(this.props.selectedQuestion.id, this.state.selectedCashValue, this.state.selectedProfitValue)

		if (!this.props.view.formStatus.cashflowInstantFeedback) {
			this.onClose()
		}
	}

	onClose = () => {
		this.setState({
			selectedCashValue: "",
			selectedProfitValue: ""
		}, () => {
			this.props.onToggleModal()
		})
	}

	render() {
		const isSubmitDisabled = (this.state.selectedCashValue !== "" && this.state.selectedProfitValue !== "") ? false : true

		let isQuestionAnswered = false
		let shouldShowResult = false

		let selectedCash = this.state.selectedCashValue
		let selectedProfit = this.state.selectedProfitValue

		if (this.props.session.optionalCashFlow[`${ this.props.selectedQuestion.id }`]) {
			isQuestionAnswered = true
			selectedCash = this.props.session.optionalCashFlow[this.props.selectedQuestion.id].cash
			selectedProfit = this.props.session.optionalCashFlow[this.props.selectedQuestion.id].profit
		}
		if (this.props.view.formStatus.cashflowInstantFeedback || this.props.view.showCashflowExerciseResult) {
			shouldShowResult = true
		}

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				onRequestClose={this.onClose}
				style={ customStyles }
				contentLabel="Optional CashFlow"
				overlayClassName="cashflow-modal-overlay"
			>
				<div className="cashflow-modal-box">
					<div className="flex-container">
						<div className="cashflow-marker">
							<div className="circle">{ this.props.selectedQuestion.id }</div>
						</div>
						<Icon name='plus circle' className='close-button' onClick={this.onClose.bind(this)} />
					</div>

					<div className="cashflow-question-title">
						{ this.props.selectedQuestion.title }
					</div>
					<div className="cashflow-question-text">
						{ this.props.selectedQuestion.description }
					</div>
					<div className="cashflow-content">
						<Grid columns={ 2 }>
							<Grid.Row>
								<Grid.Column>
									<div className="answer-title"><Trans>CashFlow__Cash</Trans></div>
								</Grid.Column>
								<Grid.Column>
									<div className="answer-title"><Trans>CashFlow__Profit</Trans></div>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
										<Grid columns={ 3 }>
											<Grid.Row>
												<Grid.Column style={{ position: 'relative' }}>
													{
														(selectedCash === "up") ?
															<Image src={ cashFlowUpSelected } onClick={ this.onCashBtnClick.bind(this, "up") } />
															:
															<Image src={ cashFlowUp } onClick={ this.onCashBtnClick.bind(this, "up") } />
													}
													{
														(this.props.selectedQuestion.cashCorrectAnswer === "up" && isQuestionAnswered && shouldShowResult) ?
															<Image src={ correctAnswerFlag } className="correctAnswerFlag" />
															:
															null
													}
												</Grid.Column>
												<Grid.Column style={{ position: 'relative' }}>
													{
														(selectedCash === "same") ?
															<Image src={ cashFlowKeepSelected } onClick={ this.onCashBtnClick.bind(this, "same") } />
															:
															<Image src={ cashFlowKeep } onClick={ this.onCashBtnClick.bind(this, "same") } />
													}
													{
														(this.props.selectedQuestion.cashCorrectAnswer === "same" && isQuestionAnswered && shouldShowResult) ?
															<Image src={ correctAnswerFlag } className="correctAnswerFlag" />
															:
															null
													}
												</Grid.Column>
												<Grid.Column style={{ position: 'relative' }}>
													{
														(selectedCash === "down") ?
															<Image src={ cashFlowDownSelected } onClick={ this.onCashBtnClick.bind(this, "down") } />
															:
															<Image src={ cashFlowDown } onClick={ this.onCashBtnClick.bind(this, "down") } />
													}
													{
														(this.props.selectedQuestion.cashCorrectAnswer === "down" && isQuestionAnswered && shouldShowResult) ?
															<Image src={ correctAnswerFlag } className="correctAnswerFlag" />
															:
															null
													}
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</div>
								</Grid.Column>
								<Grid.Column>
									<div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
										<Grid columns={ 3 }>
											<Grid.Row>
												<Grid.Column style={{ position: 'relative' }}>
													{
														(selectedProfit === "up") ?
															<Image src={ cashFlowUpSelected } onClick={ this.onProfitBtnClick.bind(this, "up") } />
															:
															<Image src={ cashFlowUp } onClick={ this.onProfitBtnClick.bind(this, "up") } />
													}
													{
														(this.props.selectedQuestion.profitCorrectAnswer === "up" && isQuestionAnswered && shouldShowResult) ?
															<Image src={ correctAnswerFlag } className="correctAnswerFlag" />
															:
															null
													}
												</Grid.Column>
												<Grid.Column style={{ position: 'relative' }}>
													{
														(selectedProfit === "same") ?
															<Image src={ cashFlowKeepSelected } onClick={ this.onProfitBtnClick.bind(this, "same") } />
															:
															<Image src={ cashFlowKeep } onClick={ this.onProfitBtnClick.bind(this, "same") } />
													}
													{
														(this.props.selectedQuestion.profitCorrectAnswer === "same" && isQuestionAnswered && shouldShowResult) ?
															<Image src={ correctAnswerFlag } className="correctAnswerFlag" />
															:
															null
													}
												</Grid.Column>
												<Grid.Column style={{ position: 'relative' }}>
													{
														(selectedProfit === "down") ?
															<Image src={ cashFlowDownSelected } onClick={ this.onProfitBtnClick.bind(this, "down") } />
															:
															<Image src={ cashFlowDown } onClick={ this.onProfitBtnClick.bind(this, "down") } />
													}
													{
														(this.props.selectedQuestion.profitCorrectAnswer === "down" && isQuestionAnswered && shouldShowResult) ?
															<Image src={ correctAnswerFlag } className="correctAnswerFlag" />
															:
															null
													}
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</div>
								</Grid.Column>
							</Grid.Row>
							{
								(isQuestionAnswered && shouldShowResult) ?
									<Grid.Row>
										<Grid.Column>
											{ this.props.selectedQuestion.cashReason }
										</Grid.Column>
										<Grid.Column>
											{ this.props.selectedQuestion.profitReason }
										</Grid.Column>
									</Grid.Row>
									:
									null
							}
						</Grid>
						{
							(!isQuestionAnswered) ?
								<Grid columns={ 1 }>
									<Grid.Row>
										<Grid.Column>
											<Button onClick={ this.onSubmit.bind(this) } disabled={ isSubmitDisabled }><Trans>CashFlow__Submit</Trans></Button>
										</Grid.Column>
									</Grid.Row>
								</Grid>
								:
								null
						}
					</div>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		view: state.view,
		session: state.session
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CashflowPopup);
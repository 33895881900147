import initState from "./initialStates/session-state";

import profitLossYear0State from "../data/profitLossForm/year_0";
import profitLossYear1State from "../data/profitLossForm/year_1";
import profitLossYear1ImprovedState from "../data/profitLossForm/year_2";
import profitLossYear2State from "../data/profitLossForm/year_3";

import balanceSheetYear0State from "../data/balanceSheet/year_0";
import balanceSheetYear1State from "../data/balanceSheet/year_1";
import balanceSheetYear1ImprovedState from "../data/balanceSheet/year_2";

import businessReviewYear0State from "../data/businessReview/year_0";
import businessReviewYear1State from "../data/businessReview/year_1";
import businessReviewYear1ImprovedState from "../data/businessReview/year_2";
import { addCoin } from "../utils/RealtimeConnection";

const reducer = (state = initState, action) => {
	switch (action.type) {
		case 'TEAMS_NAME': 
			return {
				...state,
				teamsName: action.data
			}
		case 'REQUEST_HELP':
			return {
				...state,
				requestHelp: action.status
			}
		case 'UPDATE_YEAR':
			let profitLossForm = {}
			let balanceSheetForm = {}
			let businessReviewForm = {}
			if (action.title === "Year 1") {
				profitLossForm = profitLossYear0State
				balanceSheetForm = balanceSheetYear0State
				businessReviewForm = businessReviewYear0State
			}else if (action.title === "Year 1 Improved") {
				profitLossForm = profitLossYear1State
				balanceSheetForm = balanceSheetYear1State
				businessReviewForm = businessReviewYear1State
			}else if (action.title === "Year 2") {
				profitLossForm = profitLossYear1ImprovedState
				balanceSheetForm = balanceSheetYear1ImprovedState
				businessReviewForm = businessReviewYear1ImprovedState
			}else if (action.title === "Year 3") {
				profitLossForm = profitLossYear2State
			}

			return {
				...state,
				year: {
					title: action.title,
					value: action.year
				},
				selectedFormYear: {
					title: action.title,
					value: action.year
				},
				forms: {
					...state.forms,
					ProfitLossStatement: profitLossForm,
					BalanceSheet: balanceSheetForm,
					BusinessReview: businessReviewForm
				}
			}
		case 'UPDATE_PERIOD':
			return {
				...state,
				period: action.period,
				selectedPeriod: action.period
			}
		case 'UPDATE_SELECTED_PERIOD':
			return {
				...state,
				selectedPeriod: action.period
			}
		case 'RESTORE_CHECKLIST':
			return {
				...state,
				checklist: action.data
			}
		case 'UPDATE_CURRENT_CHECKLIST': {
			let currentChecklistIndex = state.currentChecklistIndex;
			let completedChecklistIndex = state.currentChecklistIndex;
			if (action.index !== undefined) {
				currentChecklistIndex = action.index
				completedChecklistIndex = action.index - 1
			}else{
				if (currentChecklistIndex <= state.checklist.length) {
					currentChecklistIndex += 1;
				}
			}

			return {
				...state,
				completedChecklistIndex: completedChecklistIndex,
				currentChecklistIndex: currentChecklistIndex
			}
		}
		case 'RESTORE_CURRENT_CHECKLIST': {
			let currentChecklistIndex = action.data;
			if (currentChecklistIndex <= state.checklist.length) {
				currentChecklistIndex += 1;
			}

			return {
				...state,
				completedChecklistIndex: action.data,
				currentChecklistIndex: currentChecklistIndex
			}
		}
		case 'RESTORE_COMPLETED_CHECKLIST': {
			return {
				...state,
				completedChecklistIndex: action.data,
				currentChecklistIndex: action.data
			}
		}
		case 'CONNECTED_PLAYER': {
			return {
				...state,
				players: action.data
			}
		}
		case 'PLAYER_CONNECTED': {
			let players = state.players;
			players.push(action.data);

			return {
				...state,
				players: players
			}
		}
		case 'PLAYER_DISCONNECT': {
			return {
				...state,
				players: state.players.filter( (player) => {
							return (player.socketId !== action.data.socketId)
						})
			}
		}
		case 'PLAYER_FORM': {
			let playersForm = state.playersForm;
			if (!playersForm[action.data.playerId]) {
				playersForm[action.data.playerId] = {};
			}
			playersForm[action.data.playerId][action.data.type] = {
				form: action.data.form,
				isFormValidated: action.data.isFormValidated
			}

			return {
				...state,
				playersForm: playersForm
			}
		}
		case 'REMOVE_PLAYER_FORM': {
			let playersForm = state.playersForm;
			if (playersForm[action.data.playerId]) {
				delete playersForm[action.data.playerId]
			}

			return {
				...state,
				playersForm: playersForm
			}
		}
		case 'UPDATE_VALIDATE_STATUS': {
			return {
				...state,
				isFormValidated: {
					...state.isFormValidated,
					[action.form]: action.value
				}
			}
		}
		case 'UPDATE_SUBMIT_STATUS': {
			return {
				...state,
				isFormSubmitted: {
					...state.isFormSubmitted,
					[action.form]: action.value
				}
			}
		}
		case 'REMOVE_FORM': {
			return {
				...state,
				playersForm: {},
				isFormValidated: {
					ProfitLossStatement: false,
					BalanceSheet: false
				},
				isFormSubmitted: {
					ProfitLossStatement: false,
					BalanceSheet: false
				}
			}
		}
		case 'RESTORE_STRATEGIES':
			return {
				...state,
				strategies: action.data
			}
		case 'SELECTED_STRATEGY':
			return {
				...state,
				selectedStrategy: action.data
			}
		case 'UPDATE_TEAM' :
			return {
				...state,
				team: action.data
			}
		case 'RESTORE_PROJECTS':
			return {
				...state,
				projects: action.data
			}
		case 'RESTORE_PROJECTS_DEPARTMENTS':
			return {
				...state,
				projectsDepartments: action.data
			}
		case 'SELECT_PROJECT':
			let selectedProjects = state.selectedProjects;
			if( !selectedProjects.includes(action.data) ){
				selectedProjects.push(action.data)
			}else{
				selectedProjects = selectedProjects.filter(id => id !== action.data);
			}

			return {
				...state,
				selectedProjects: selectedProjects
			}
		case 'UPDATE_TEAM_PROJECT': {
			let teamProjects = state.teamSelectedProjects
			if(action.status) {
				if (teamProjects[action.playerId]) {
					teamProjects[action.playerId].push(action.project)
				}else{
					teamProjects[action.playerId] = [action.project]
				}
			}else{
				let playerProjects = teamProjects[action.playerId].filter(id => id !== action.project);
				teamProjects[action.playerId] = playerProjects
			}
			
			return {
				...state,
				teamSelectedProjects: teamProjects
			}
		}
		case 'RESTORE_PLAYER_PROJECTS':
			return {
				...state,
				selectedProjects: action.projects
			}
		case 'RESTORE_TEAM_PROJECTS': {
			let teamProjects = state.teamSelectedProjects
			teamProjects[action.playerId] = action.projects

			return {
				...state,
				teamSelectedProjects: teamProjects
			}
		}
		case 'SELECTED_TEAMS_PROJECTS': 
			return {
				...state,
				teamsProjects: action.data
			}
		case 'CHANGE_FORM_YEAR':
			return {
				...state,
				selectedFormYear: {
					title: action.title,
					value: action.year
				}
			}
		case 'UPDATE_CASHFLOW_ANSWER':
			let cashflowValues = state.optionalCashFlow
			if (cashflowValues[action.id] === undefined) {
				cashflowValues[action.id] = {}
			}
			cashflowValues[action.id].cash = action.cash
			cashflowValues[action.id].profit = action.profit

			
			const answeredIds = Object.keys(cashflowValues);
			const btnStatus = (answeredIds.length === 13) ? true : false
			
			return {
				...state,
				optionalCashFlow: cashflowValues,
				enableOptionalCashFlowShowResultBtn: btnStatus
			}
		case 'CHANGE_YEAR_3_DEBRIEF': 
			return {
				...state,
				showYear3Step: {
					...state.showYear3Step,
					[action.step]: action.status
				}
			}
		case 'CHANGE_DOWNLOAD_FILE_STATUS': 
			return {
				...state,
				downloadFile: action.status
			}
		default:
			break;
	}

	return state
}

export default reducer;
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// Components
import DroppableField from '../../common/DroppableField';

// CSS
import '../../../css/boardLenders.css';

class CostOfSales extends React.Component {

	draggingHandler = (objectType, isDragging, data) => {
		// Update Socket
		//RealtimeConnection.containerDragging("production", objectType, isDragging, data)
	}

	addContainer = (alias, id, coins) => {
		this.props.addContainer("production", alias, id, coins);
	}

	removeContainer = (alias, id) => {
		this.props.removeContainer("production", alias, id);
	}

	removeModifiedContainer = (alias, id) => {
		this.props.removeModifiedContainer("production", alias, id);
	}

	revertModifiedContainer = (alias, id) => {
		this.props.revertModifiedContainer("production", alias, id);
	}

	addCoin = (alias, id, value) => {
		this.props.addCoin("production", alias, id, value);
	}

	removeCoin = (alias, id) => {
		this.props.removeCoin("production", alias, id);
	}

	increaseCoin = (alias, id) => {
		this.props.increaseCustomCoin("production", alias, id, 1);
	}

	decreaseCoin = (alias, id) => {
		this.props.decreaseCustomCoin("production", alias, id, 1);
	}

	render() {
		return (
			<div className="costOfSalesBlock">
				<Grid.Row>
					<Grid.Column style={{ display: "flex", flexDirection: "row-reverse" }}>
						<Popup position='top center' content={ <Trans>Glossary__Cost of goods sold</Trans> } size='mini' trigger={<Icon name='plus circle' style={{ marginRight: "0px" }} />} />
					</Grid.Column>
				</Grid.Row>
				<p style={{ marginBotton: 12 }}><Trans>Board__Cost of goods sold</Trans></p>
				<Grid columns={2}>
					{(
						this.props.board.production.goodsSold.map( (field, index) => {
							return <Grid.Column style={{ position: "relative" }} className="goodsSoldColumn" key={ index }>
									{
										(index === 8 && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "productionBlock" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step > 0) ?
											<div className="redIndicator"></div>
											:
											null
									}
									<DroppableField 
										field={ field }
										id={ index }
										block={ "production" }
										alias={ "goodsSold" }
										drawCoins={ false }
										maxCoins={ 20 }
										addContainer={ this.addContainer.bind(this) }
										removeContainer={ this.removeContainer.bind(this) }
										removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
										revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
										addCoin={ this.addCoin.bind(this) }
										removeCoin={ this.removeCoin.bind(this) }
										increaseCoin={ this.increaseCoin.bind(this) }
										decreaseCoin={ this.decreaseCoin.bind(this) }
										isDraggingHandler={ this.draggingHandler }
										boardState={ this.props.board }
									/>
								</Grid.Column>
						})
					)}
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CostOfSales);
import React from "react";
import {useDrag} from 'react-dnd';


export default function DraggableCustomValueContainer(props) {

	const [, drag] = useDrag({
		item: { 
			type: "Container",
			coins: props.coins,
			block: props.block,
			alias: props.alias,
			id: props.id
		},
		collect: monitor => ({
			isDragging: !!monitor.isDragging()
		}),
		end: (dropResult, monitor) => {
			const doppableItem = monitor.getDropResult();
			if (doppableItem !== null) {
				if (doppableItem.type !== undefined && monitor.didDrop()) {

					props.removeModifiedContainer(props.alias, props.id, props.coins);
					props.closePopup();
					
					let dragObjData = {}
					dragObjData.alias = props.alias
					dragObjData.id = props.id
					dragObjData.coins = props.coins
					if (props.isDraggingHandler) {
						props.isDraggingHandler("POPUP_CONTAINER", false, dragObjData);
					}
					
					//props.canDropAction(true);
				}
			}
		}
	});

	let canDrag = (props.canPlayerDrag) ? drag : null

	return (
		<div className="draggableContainer" ref={canDrag}>
			<div className="coin-value">{ props.coins }</div>
		</div>
	);
}
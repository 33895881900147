import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid } from "semantic-ui-react";
import { Trans } from "react-i18next";


// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';
import RealtimeConnection from '../../../utils/RealtimeConnection';

class Indicators extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	onFieldChange = (field, value) => {
		if (this.props.session.selectedFormYear.title === this.props.session.year.title) {
			this.props.updateFormWithKey("BusinessReview", "indicators", field, value)

			RealtimeConnection.updateForm(
				this.props.session.year.title,
				"BusinessReview",
				this.props.businessReview,
				true,
				true
			);
		}
	}

	render() {
		let lockForm = false
		if (this.props.session.selectedFormYear.title !== this.props.session.year.title) {
			lockForm = true
		}

		let prevYearTitle = ""
		let currentYearTitle = ""
		switch(this.props.session.year.title) {
			case "Year 1":
				prevYearTitle = <><Trans>Board__Year</Trans> 0</>
				currentYearTitle = <><Trans>Board__Year</Trans> 1</>
				break;
			case "Year 1 Improved":
				prevYearTitle = <><Trans>Board__Year</Trans> 1 <Trans>Forms__Before</Trans></>
				currentYearTitle = <><Trans>Board__Year</Trans> 1 <Trans>Forms__After</Trans></>
				break
			case "Year 2":
				prevYearTitle = <><Trans>Board__Year</Trans> 1</>
				currentYearTitle = <><Trans>Board__Year</Trans> 2</>
				break
			case "Year 3":
				prevYearTitle = <><Trans>Board__Year</Trans> 2</>
				currentYearTitle = <><Trans>Board__Year</Trans> 3</>
				break
			case "Year 4":
				prevYearTitle = <><Trans>Board__Year</Trans> 3</>
				currentYearTitle = <><Trans>Board__Year</Trans> 4</>
				break
			default:
				break
		}

		const staticFormValue = this.props.session.forms.BusinessReview.indicators;

		let validateForm = {
			contribution: {
				isValid: true,
				value: this.props.businessReview.indicators.contribution
			},
			contributionSales: {
				isValid: true,
				value: this.props.businessReview.indicators.contributionSales
			},
			operatingProfit: {
				isValid: true,
				value: this.props.businessReview.indicators.operatingProfit
			},
			operatingSales: {
				isValid: true,
				value: this.props.businessReview.indicators.operatingSales
			},
			equity: {
				isValid: true,
				value: this.props.businessReview.indicators.equity
			},
			assets: {
				isValid: true,
				value: this.props.businessReview.indicators.assets
			}
		}

		let playersFormLength = []
		const playerIds = Object.keys(this.props.session.playersForm);
		playerIds.forEach( id => {
			if (this.props.session.playersForm[id].BusinessReview && this.props.session.playersForm[id].BusinessReview.form.indicators) {
				playersFormLength.push(id)
			}
		})
		

		if (this.props.businessReview && this.props.businessReview.indicators && this.props.session.team.SessionPlayers) {
			const playersForm = this.props.session.playersForm;
			for (const playerId in playersForm) {
				if (playersForm[playerId].BusinessReview && playersForm[playerId].BusinessReview.form.indicators) {
					for (const field in playersForm[playerId].BusinessReview.form.indicators) {
						if (validateForm[field].value !== "" && playersForm[playerId].BusinessReview.form.indicators[field] !== "") {
							if (parseFloat(validateForm[field].value) !== parseFloat(playersForm[playerId].BusinessReview.form.indicators[field])) {
								validateForm[field].isValid = false;
							}
						}
					}
				}
			}
			
		}

		let staticContributePercentage = 0
		let staticOperatingPercentage = 0
		let staticEquityPercentage = 0
		if (staticFormValue.contribution !== "" && staticFormValue.contributionSales !== "") {
			staticContributePercentage = parseFloat(staticFormValue.contribution) / parseFloat(staticFormValue.contributionSales)
			staticContributePercentage *= 100;
			staticContributePercentage = parseInt(staticContributePercentage)
		}
		if (staticFormValue.operatingProfit !== "" && staticFormValue.operatingSales !== "") {
			staticOperatingPercentage = parseFloat(staticFormValue.operatingProfit) / parseFloat(staticFormValue.operatingSales)
			staticOperatingPercentage *= 100;
			staticOperatingPercentage = parseInt(staticOperatingPercentage)
		}
		if (staticFormValue.equity !== "" && staticFormValue.assets !== "") {
			staticEquityPercentage = parseFloat(staticFormValue.equity) / parseFloat(staticFormValue.assets)
			staticEquityPercentage *= 100;
			staticEquityPercentage = parseInt(staticEquityPercentage)
		}

		let contributePercentage = 0
		let operatingPercentage = 0
		let equityPercentage = 0
		if (this.props.businessReview.indicators.contribution !== "" && this.props.businessReview.indicators.contributionSales !== "") {
			contributePercentage = parseFloat(this.props.businessReview.indicators.contribution) / parseFloat(this.props.businessReview.indicators.contributionSales)
			contributePercentage *= 100;
			contributePercentage = parseInt(contributePercentage)
		}
		if (this.props.businessReview.indicators.operatingProfit !== "" && this.props.businessReview.indicators.operatingSales !== "") {
			operatingPercentage = parseFloat(this.props.businessReview.indicators.operatingProfit) / parseFloat(this.props.businessReview.indicators.operatingSales)
			operatingPercentage *= 100;
			operatingPercentage = parseInt(operatingPercentage)
		}
		if (this.props.businessReview.indicators.equity !== "" && this.props.businessReview.indicators.assets !== "") {
			equityPercentage = parseFloat(this.props.businessReview.indicators.equity) / parseFloat(this.props.businessReview.indicators.assets)
			equityPercentage *= 100;
			equityPercentage = parseInt(equityPercentage)
		}

		const dir = this.props.selectedLanguage == 8 ? "rtl" : "ltr" 

		return (
			<Grid columns={3} className="indicatorsGrid" dir={dir}>
				<Grid.Row>
					<Grid.Column className={(dir ==="rtl" ? "ar " : "") + "indicator1stCol"}>
						<h2><Trans>Forms__Contribution Margin</Trans></h2>
						<h4>
							<p style={{ fontFamily: "NotoSansBold" }}>
								{" "}
								<Trans>Forms__Profitability of the products</Trans>
							</p>
							<Trans>Forms__A matter of how much you get for them (Price) compared to what it
							costs to make them (Cost of goods).</Trans>{" "}
						</h4>
					</Grid.Column>
					<Grid.Column className={(dir ==="rtl" ? "ar " : "") + "indicator2ndCol"}>
						<span>{ prevYearTitle }</span>
						<div className="colEquation">
							<div>
								<p><Trans>Forms__Contribution</Trans></p>
								<p><Trans>Forms__Sales</Trans></p>
							</div>
							<div>
								<span>{ staticFormValue.contribution }</span>
								<hr />
								<span>{ staticFormValue.contributionSales }</span>
							</div>
							<div style={{ width: "60px" }}>
								<span> = { staticContributePercentage } %</span>
							</div>
						</div>
					</Grid.Column>
					<Grid.Column className={(dir ==="rtl" ? "ar " : "") + "indicator3rdCol"}>
						<span>{ currentYearTitle }</span>
						<div className="colEquation">
							<div>
								<p><Trans>Forms__Contribution</Trans></p>
								<p><Trans>Forms__Sales</Trans></p>
							</div>
							<div>
								<span>
									<input 
										className={ `indicatorField ${ (!validateForm.contribution.isValid) ? "error" : "" }` }
										value={ this.props.businessReview.indicators.contribution }
										onChange={ e => this.onFieldChange("contribution", e.target.value) } 
										disabled={ lockForm }
									/>
								</span>
								<hr className="divider" />
								<span>
									<input 
										className={ `indicatorField ${ (!validateForm.contributionSales.isValid) ? "error" : "" }` }
										value={ this.props.businessReview.indicators.contributionSales }
										onChange={ e => this.onFieldChange("contributionSales", e.target.value) } 
										disabled={ lockForm }
									/>
								</span>
							</div>
							<div style={{ width: "60px" }}>
								<span> = { contributePercentage } %</span>
							</div>
						</div>
					</Grid.Column>
				</Grid.Row>
				{/* Second Row */}

				<Grid.Row>
					<Grid.Column className={(dir ==="rtl" ? "ar " : "") + "indicator1stCol"}>
						<h2><Trans>Forms__Operating Margin</Trans></h2>
						<h4>
							<p style={{ fontFamily: "NotoSansBold" }}> <Trans>Forms__Efficiency of the operation</Trans></p>
							<Trans>Forms__How is the total operating cost (Cost of goods and Common cost)
							developing compared to Sales?</Trans>{" "}
						</h4>
					</Grid.Column>
					<Grid.Column className={(dir ==="rtl" ? "ar " : "") + "indicator2ndCol"}>
						<span>{ prevYearTitle }</span>
						<div className="colEquation">
							<div>
								<p><Trans>Forms__Operating profit</Trans></p>
								<p><Trans>Forms__Sales</Trans></p>
							</div>
							<div>
								<span>{ staticFormValue.operatingProfit }</span>
								<hr />
								<span>{ staticFormValue.operatingSales }</span>
							</div>
							<div style={{ width: "60px" }}>
								<span> = { staticOperatingPercentage } %</span>
							</div>
						</div>
					</Grid.Column>
					<Grid.Column className={(dir ==="rtl" ? "ar " : "") + "indicator3rdCol"}>
						<span>{ currentYearTitle }</span>
						<div className="colEquation">
							<div>
								<p><Trans>Forms__Operating profit</Trans></p>
								<p><Trans>Forms__Sales</Trans></p>
							</div>
							<div>
								<span>
									<input 
										className={ `indicatorField ${ (!validateForm.operatingProfit.isValid) ? "error" : "" }` }
										value={ this.props.businessReview.indicators.operatingProfit }
										onChange={ e => this.onFieldChange("operatingProfit", e.target.value) } 
										disabled={ lockForm }
									/>
								</span>
								<hr className="divider" />
								<span>
									<input 
										className={ `indicatorField ${ (!validateForm.operatingSales.isValid) ? "error" : "" }` }
										value={ this.props.businessReview.indicators.operatingSales }
										onChange={ e => this.onFieldChange("operatingSales", e.target.value) } 
										disabled={ lockForm }
									/>
								</span>
							</div>
							<div style={{ width: "60px" }}>
								<span> = { operatingPercentage } %</span>
							</div>
						</div>
					</Grid.Column>
				</Grid.Row>

				{/* Third Row */}

				<Grid.Row>
					<Grid.Column className={(dir ==="rtl" ? "ar " : "") + "indicator1stCol"}>
						<h2><Trans>Forms__Equity Ratio</Trans></h2>
						<h4>
							<p style={{ fontFamily: "NotoSansBold" }}> <Trans>Forms__Proportion of owner capital</Trans></p>
							<Trans>Forms__The higher the ratio, the less vulnerable the business is. You do
							not want to be too dependent on the bank.</Trans>
						</h4>
					</Grid.Column>
					<Grid.Column className={(dir ==="rtl" ? "ar " : "") + "indicator2ndCol"}>
						<span>{ prevYearTitle }</span>
						<div className="colEquation">
							<div>
								<p><Trans>Forms__Equity</Trans></p>
								<p><Trans>Forms__Assets</Trans></p>
							</div>
							<div>
								<span>{ staticFormValue.equity }</span>
								<hr/>
								<span>{ staticFormValue.assets }</span>
							</div>
							<div style={{ width: "60px" }}>
								<span> = { staticEquityPercentage } %</span>
							</div>
						</div>
					</Grid.Column>
					<Grid.Column className={(dir ==="rtl" ? "ar " : "") + "indicator3rdCol"}>
						<span>{ currentYearTitle }</span>
						<div className="colEquation">
							<div>
								<p><Trans>Forms__Equity</Trans></p>
								<p><Trans>Forms__Assets</Trans></p>
							</div>
							<div>
								<span>
									<input 
										className={ `indicatorField ${ (!validateForm.equity.isValid) ? "error" : "" }` }
										value={ this.props.businessReview.indicators.equity }
										onChange={ e => this.onFieldChange("equity", e.target.value) } 
										disabled={ lockForm }
									/>
								</span>
								<hr className="divider" />
								<span>
									<input 
										className={ `indicatorField ${ (!validateForm.assets.isValid) ? "error" : "" }` }
										value={ this.props.businessReview.indicators.assets }
										onChange={ e => this.onFieldChange("assets", e.target.value) } 
										disabled={ lockForm }
									/>
								</span>
							</div>
							<div style={{ width: "60px" }}>
								<span> = { equityPercentage } %</span>
							</div>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		businessReview: state.form.BusinessReview,
		selectedLanguage: state.view.selectedLanguage
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Indicators);

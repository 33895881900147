import React from 'react';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// Components
import DroppableField from '../../common/DroppableField';

// CSS
import '../../../css/boardCash.css';

// Images
import bigOrangeArrow from "../../../assets/Images/big-orange-arrow.png";
import cashIcon from "../../../assets/Images/cash-icon.svg";
import receivableIcon from "../../../assets/Images/receivable-icon.svg";


class Cash extends React.Component {

	draggingHandler = (objectType, isDragging, data) => {
		// Update Socket
		//RealtimeConnection.containerDragging("outgoingCash", objectType, isDragging, data)
	}

	addContainer = (alias, id, coins) => {
		this.props.addContainer("outgoingCash", alias, id, coins);
	}

	removeContainer = (alias, id) => {
		this.props.removeContainer("outgoingCash", alias, id);
	}

	removeModifiedContainer = (alias, id) => {
		this.props.removeModifiedContainer("outgoingCash", alias, id);
	}

	revertModifiedContainer = (alias, id) => {
		this.props.revertModifiedContainer("outgoingCash", alias, id);
	}

	addCoin = (alias, id, value) => {
		this.props.addCoin("outgoingCash", alias, id, value);
	}

	removeCoin = (alias, id) => {
		this.props.removeCoin("outgoingCash", alias, id);
	}

	increaseCoin = (alias, id) => {
		this.props.increaseCustomCoin("outgoingCash", alias, id, 1);
	}

	decreaseCoin = (alias, id) => {
		this.props.decreaseCustomCoin("outgoingCash", alias, id, 1);
	}

	render() {
		const dir = this.props.selectedLanguage == 8 ? "rtl" : "ltr"
		return (
			<div className="orangeBlock" style={{ position: "relative" }}>
				
				<div className={dir === "ltr" ? "blockArrow" : "blockArrowAr"}>
					<Image src={ bigOrangeArrow } />
				</div>
				<div className="atp-grid">
					<div className="atp-row" style={{ marginTop: "4px" }}>
						<div className="atp-column" style={{ flex: 1, display: "flex", height: "28px", maxWidth: "130.5px" }}>
							<div className="dottedBorder"></div>
						</div>
						<div className="atp-column" style={{ width: "50px", display: "flex", position: "relative" }}>
							{
								<DroppableField
									ltr={dir === "ltr"}  
									id={ -1 }
									field={ this.props.board.outgoingCash.cash }
									block={ "outgoingCash" }
									alias={ "cash" }
									drawCoins={ false }
									maxCoins={ 100 }
									addContainer={ this.addContainer.bind(this) }
									removeContainer={ this.removeContainer.bind(this) }
									removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
									revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
									addCoin={ this.addCoin.bind(this) }
									removeCoin={ this.removeCoin.bind(this) }
									increaseCoin={ this.increaseCoin.bind(this) }
									decreaseCoin={ this.decreaseCoin.bind(this) }
									isDraggingHandler={ this.draggingHandler }
									boardState={ this.props.board }
								/>
							}
							{
								(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "orangeBlock") ?
									<div className="guidedTourPopupTop">
										<div className="downArrow"></div>
										<h4>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h4>
										<p>
											{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }
										</p>
										<div className="actions">
											{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
											{
												(this.props.board.development.development.coins === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
													<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
													:
													<div className="nextBtnContainer opacity"></div>
											}
										</div>
									</div>
									:
									null
							}
						</div>
						<div className="atp-column" style={{ flex: 1 }}>
							<div className="atp-row">
								<div 
									className="atp-column" 
									style={
										dir === "ltr"
										? { display: "flex", height: 28, paddingRight: 10 }
										: { display: "flex", height: 28, paddingLeft: 10 }
								}>
									<Image 
										src={ cashIcon } 
										className="cashIcon" 
										style={dir === "ltr" ? { marginRight: 10 } : { marginLeft: 10 }} 
									/>
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle">
										<Trans>Board__Cash</Trans>
									</p>
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ width: "50px", display: "flex", position: "relative" }}>
							{
								<DroppableField
									ltr={dir === "ltr"}
									id={ -1 }
									field={ this.props.board.outgoingCash.accountsReceivable }
									block={ "outgoingCash" }
									alias={ "accountsReceivable" }
									drawCoins={ false }
									maxCoins={ 100 }
									addContainer={ this.addContainer.bind(this) }
									removeContainer={ this.removeContainer.bind(this) }
									removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
									revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
									addCoin={ this.addCoin.bind(this) }
									removeCoin={ this.removeCoin.bind(this) }
									increaseCoin={ this.increaseCoin.bind(this) }
									decreaseCoin={ this.decreaseCoin.bind(this) }
									isDraggingHandler={ this.draggingHandler }
									boardState={ this.props.board }
								/>
							}
							{ 
								(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "accountsReceivable" &&
									<div className="guidedTourPopupTop">
										<div className="downArrow"></div>
										<h4>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h4>
										<p>
											{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }
										</p>
										<div className="actions">
											{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
											{
												(this.props.board.outgoingCash.cash.coins === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value ) ?
													<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
													:
													<div className="nextBtnContainer opacity"></div>
											}
										</div>
									</div>	
								)
							}
						</div>
						<div className="atp-column" style={{ flex: 1, maxWidth: "180.5px" }}>
							<div className="atp-row">
								<div 
									className="atp-column" 
									style={
										dir === "ltr"
										? { display: "flex", height: 28, paddingRight: 10 }
										: { display: "flex", height: 28, paddingLeft: 10 }
								}>
									<Image 
										src={ receivableIcon } 
										style={dir === "ltr" ? { marginRight: 10 } : { marginLeft: 10 }} 
									/>
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle"><Trans>Board__Accounts Receivable</Trans></p>
								</div>
							</div>
						</div>
					</div>
				</div>

				{
					(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sequence" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 4) ?
						<div className="sequenceGuidedTourPopup">
							<div className="leftArrow"></div>
							<p>
								{ this.props.guidedTour.steps[4].message }
							</p>
							<div className="actions">
								{
									(this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 4 && 
										<>
											{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
											<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
										</>
									)
								}
							</div>
						</div>
						:
						null
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board,
		selectedLanguage: state.view.selectedLanguage,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Cash);
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// CSS
import '../../../css/boardLenders.css';

class DevelopmentBlocks extends React.Component {

	render() {
		const dir = this.props.view.selectedLanguage == 8 ? "rtl" : "ltr"
		return (
			<>
				<div className="overheadBlock">
					<Grid className="overheadGrid">
						<Grid.Row>
							<Grid.Column style={{ display: "flex", flexDirection: "row-reverse" }}>
								<Popup position='top center' content={ <Trans>Glossary__Overhead</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row style={{ marginTop: "0px !important" }}>
							<Grid.Column>
								<div className={ `droppableField` } style={{ position: 'relative' }}>
									<div className="cashflowPointer" onClick={ () => { this.props.onSelectQuestion(9); this.props.onToggleModal() }}>
										{
										(this.props.view.showCashflowExerciseResult) ?
											<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["10"] }` }>10</div>
											:
											<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["10"]) ? 'finished': '' }` }>10</div>
										}
									</div>
								</div>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<p><Trans>Board__Overhead</Trans></p>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
				<div className="developmentBlock">
					<Grid className="developmentBlockGrid">
						<Grid.Row>
							<Grid.Column style={{ display: "flex", flexDirection: "row-reverse" }}>
								<Popup position='top center' content={ <Trans>Glossary__Development</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row style={{ marginTop: "0px !important" }}>
							<Grid.Column>
								<div className={ `droppableField` } style={{ position: 'relative' }}>
									<div className="cashflowPointer" onClick={ () => { this.props.onSelectQuestion(8); this.props.onToggleModal() }}>
										{
										(this.props.view.showCashflowExerciseResult) ?
											<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["9"] }` }>9</div>
											:
											<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["9"]) ? 'finished': '' }` }>9</div>
										}
									</div>
								</div>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<p><Trans>Board__Development</Trans></p>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		view: state.view,
		session: state.session
	}
}

export default connect(mapStateToProps)(DevelopmentBlocks);
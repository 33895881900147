import { GET_CHATS, AFTER_POST_MESSAGE } from "./chat-action";

export default function (state = { hasMore: true, chat: [] }, action) {
  switch (action.type) {
    case GET_CHATS:
      return {
        ...state,
        chat: state.chat.concat(action.payload),
        hasMore: action.hasMore,
      };
    case AFTER_POST_MESSAGE:
      return { ...state, chat: state.chat.concat(action.payload) };
    default:
      return state;
  }
}

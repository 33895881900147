import React from 'react';
import { connect } from 'react-redux';
import { Image, Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Utils
import RealtimeConnection from '../../../utils/RealtimeConnection';

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// Components
import DroppableField from '../../common/DroppableField';

// CSS
import '../../../css/boardInvestment.css';

// Images
import bigYellowArrow from "../../../assets/Images/big-yellow-arrow.png";
import propertyIcon from "../../../assets/Images/property-icon.svg";
import gearIcon from "../../../assets/Images/gear-icon.svg";
import diamondIcon from "../../../assets/Images/diamond-icon.svg";

class Investment extends React.Component {

	draggingHandler = (objectType, isDragging, data) => {
		// Update Socket
		//RealtimeConnection.containerDragging("investment", objectType, isDragging, data)
	}

	addContainer = (alias, id, coins) => {
		this.props.addContainer("investment", alias, id, coins);
	}

	removeContainer = (alias, id) => {
		this.props.removeContainer("investment", alias, id);
	}

	removeModifiedContainer = (alias, id) => {
		this.props.removeModifiedContainer("investment", alias, id);
	}

	revertModifiedContainer = (alias, id) => {
		this.props.revertModifiedContainer("investment", alias, id);
	}

	addCoin = (alias, id, value) => {
		this.props.addCoin("investment", alias, id, value);
	}

	removeCoin = (alias, id) => {
		this.props.removeCoin("investment", alias, id);
	}

	increaseCoin = (alias, id) => {
		this.props.increaseCustomCoin("investment", alias, id, 1);
	}

	decreaseCoin = (alias, id) => {
		this.props.decreaseCustomCoin("investment", alias, id, 1);
	}

	render() {
		const dir = this.props.selectedLanguage == 8 ? "rtl" : "ltr"
		return (
			<div className="yellowBlock" style={{ position: "relative" }}>
				<div className={dir === "ltr" ? "blockArrow" : "blockArrowAr"}>
					<Image src={ bigYellowArrow } />
				</div>
				<div className="atp-grid">
					<div className="atp-row">
						<div className="atp-column" style={{ flex: 1, maxWidth: "130.5px" }}>
							<div className="atp-row">
								<div className="atp-column" style={{ flex: 1 }}>
									<p className={dir === "ltr" ? "blockTitle" : "blockTitleAr"}><Trans>Board__Investments</Trans></p>
								</div>
							</div>
							<div className="atp-row developmentRow">
								<div className="atp-column" style={{ flex: 1 }}>
									<Popup position='top center' content={ <Trans>Glossary__Investments</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ width: "50px", display: "flex" }}>
							{
								<DroppableField
									ltr={dir === "ltr"} 
									id={ -1 }
									field={ this.props.board.investment.realProperty }
									block={ "investment" }
									alias={ "realProperty" }
									drawCoins={ false }
									maxCoins={ 100 }
									addContainer={ this.addContainer.bind(this) }
									removeContainer={ this.removeContainer.bind(this) }
									removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
									revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
									addCoin={ this.addCoin.bind(this) }
									removeCoin={ this.removeCoin.bind(this) }
									increaseCoin={ this.increaseCoin.bind(this) }
									decreaseCoin={ this.decreaseCoin.bind(this) }
									isDraggingHandler={ this.draggingHandler }
									boardState={ this.props.board }
								/>
							}
						</div>
						<div className="atp-column" style={{ flex: 1, maxWidth: "130.5px" }}>
							<div className="atp-row" style={{ marginTop: "3px" }}>
								<div 
									className="atp-column" 
									style={
										dir === "ltr" 
										? { display: "flex", height: 28, paddingRight: 10 } 
										: { display: "flex", height: 28, paddingLeft: 10 } 
									}
								>
									<Image 
										src={ propertyIcon } 
										className="propertyIcon" 
										style={dir === "ltr" ? { marginRight: 10 } : {marginLeft: 10}}
										/>
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle"><Trans>Board__Real Property</Trans></p>
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ width: "50px", display: "flex" }}>
							{
								<DroppableField
									ltr={dir === "ltr"} 
									id={ -1 }
									field={ this.props.board.investment.equipment }
									block={ "investment" }
									alias={ "equipment" }
									drawCoins={ false }
									maxCoins={ 100 }
									addContainer={ this.addContainer.bind(this) }
									removeContainer={ this.removeContainer.bind(this) }
									removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
									revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
									addCoin={ this.addCoin.bind(this) }
									removeCoin={ this.removeCoin.bind(this) }
									increaseCoin={ this.increaseCoin.bind(this) }
									decreaseCoin={ this.decreaseCoin.bind(this) }
									isDraggingHandler={ this.draggingHandler }
									boardState={ this.props.board }
								/>
							}
						</div>
						<div className="atp-column" style={{ flex: 1, maxWidth: "130.5px" }}>
							<div className="atp-row" style={{ marginTop: "3px" }}>
								<div className="atp-column" style={dir === "ltr" 
									? { display: "flex", height: 28, paddingRight: 10 } 
									: { display: "flex", height: 28, paddingLeft: 10 } }>
									<Image 
										src={ gearIcon } 
										style={dir === "ltr" ? { marginRight: 10 } : {marginLeft: 10}} 
									/>
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle"><Trans>Board__Plant & Equipment</Trans></p>
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ width: "50px", display: "flex" }}>
							{
								<DroppableField 
									ltr={dir === "ltr"} 
									id={ -1 }
									field={ this.props.board.investment.intellectualProperty }
									block={ "investment" }
									alias={ "intellectualProperty" }
									drawCoins={ false }
									maxCoins={ 100 }
									addContainer={ this.addContainer.bind(this) }
									removeContainer={ this.removeContainer.bind(this) }
									removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
									revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
									addCoin={ this.addCoin.bind(this) }
									removeCoin={ this.removeCoin.bind(this) }
									increaseCoin={ this.increaseCoin.bind(this) }
									decreaseCoin={ this.decreaseCoin.bind(this) }
									isDraggingHandler={ this.draggingHandler }
									boardState={ this.props.board }
								/>
							}
						</div>
						<div className="atp-column" style={{ flex: 1 }}>
							<div className="atp-row" style={{ marginTop: "3px" }}>
								<div className="atp-column" style={{ display: "flex", height: "28px" }}>
									<Image 
										src={ diamondIcon }
										style={dir === "ltr" ? { marginRight: 10 } : {marginLeft: 10}} 
									/>
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle"><Trans>Board__Intellectual Property</Trans></p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sequence" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 3) ?
						<div className="sequenceGuidedTourPopup">
							<div className="leftArrow"></div>
							<p>
								{ this.props.guidedTour.steps[3].message }
							</p>
							<div className="actions">
								{
									(this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 3 && 
										<>
											{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
											<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
										</>
									)
								}
							</div>
						</div>
						:
						null
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board,
		selectedLanguage: state.view.selectedLanguage,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Investment);
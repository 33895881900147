import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react'

// Dispatcher
import mapDispatchToProps from '../reducers/dispatcher';

// Components
import MainBoard from './board/MainBoard';
import SmallBoard from './board/SmallBoard';

class Board extends React.Component {

	render() {
		return (
			<Container className="borardContainer">
				{
					(this.props.view.smallBoardStatus) ? <SmallBoard /> : <MainBoard />
				}
			</Container>
		);
	}
}
/*

*/

const mapStateToProps = (state) => {
	return {
		view: state.view
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Board);
import initState from "./initialStates/view-state";

const reducer = (state = initState, action) => {
	switch (action.type) {
		case 'UPDATE_GUIDED_TOUR_VIEW':
			return {
				...state,
				[action.view]: action.status
			}
		case 'CHANGE_LANGUAGE': 
			return {
				...state,
				selectedLanguage: action.id
			}
		case 'UPDATE_FINANCIAL_STATUS':
			return {
				...state,
				financialStatementStatus: action.status,
				smallBoardStatus: action.status,
				openChat: false,
				financialTab: {
					...state.financialTab,
					businessReview: false,
					cashflow: false
				}
			}
		case 'UPDATE_FINANCIAL_TAB':
			state.financialTab.profit = false;
			state.financialTab.balanceSheet = false;

			return {
				...state,
				financialTab: {
					...state.financialTab,
					[action.tab]: true
				}
			}
		case 'UPDATE_FINSNACIAL_FORM_STATUS':
			state.financialTab.cashflow = false;
			state.financialTab.businessReview = false;

			return {
				...state,
				financialTab: {
					...state.financialTab,
					[action.tab]: action.status
				}
			}
		case 'UPDATE_CHECKLIST_STATUS':
			return {
				...state,
				checkliststatus: action.status
			}
		case 'UPDATE_CHAT_VIEW_STATUS' :
			return {
				...state,
				financialStatementStatus: false,
				openChat: action.status,
				smallBoardStatus: false
			}
		case 'UPDATE_PRESENTATION_STATUS' :
			return {
				...state,
				showPresentationPopup: action.status,
				presentationImg: (action.image) ? action.image : ""
			}
		case "FINISHED_STRATS_INTRO":
			return {
				...state,
				startStrategies: true,
				y2Strategies: {
					intro: false,
					tabs: {
						potential: true,
						risk: false,
						SMEstatment: false,
					}
				},
			};
		case "UPDATE_STRATS_VIEW":
			state.y2Strategies.tabs.potential = false;
			state.y2Strategies.tabs.risk = false;
			state.y2Strategies.tabs.SMEstatment = false;
			
			return {
				...state,
				startStrategies: true,
				y2Strategies: {
					...state.y2Strategies,
					tabs: {
						...state.y2Strategies.tabs,
						[action.tab]: true,
					},
				},
			};
		case "SHOW_STRATS_INTRO":
			return {
				...state,
				startStrategies: true,
				y2Strategies: {
					...state.y2Strategies.tabs,
					intro: true,
				},
			};
		case "CHOOSE_STRATEGY":
			if(state.y2Strategies.tabs) {
				state.y2Strategies.tabs.potential = false;
				state.y2Strategies.tabs.risk = false;
				state.y2Strategies.tabs.SMEstatment = false;
			}
			state.y2Strategies.intro = false

			return {
				...state,
				startStrategies: true,
				y2Strategies: {
					...state.y2Strategies,
					tabs: {
						...state.y2Strategies.tabs,
					},
					selectedStrategy: action.payload,
				},
			};
		case "HIDE_STRATEGIES":
			return {
				...state,
				startStrategies: false,
				y2Strategies: {
					...state.y2Strategies.tabs,
					intro: false,
				},
			};
		case 'PROJECT_APPROVED_BY_ALL' : 
			return {
				...state,
				year3: {
					...state.year3,
					projects: [...state.year3.projects, action.payload],
					teamAgreedOnProjects: state.year3.projects.length + 1 === 4
				}
			}
		case 'UPDATE_PROJECT_VIEW':
			state.year3.intro = false
			state.year3.confirmation = false
			state.year3.waitingView = false
			state.year3.resultView = false
			
			return {
				...state,
				year3: {
					...state.year3,
					[action.view]: action.status
				}
			}
		case 'FORM_STATUS': 
			return {
				...state,
				formStatus: {
					...state.formStatus,
					[action.form]: action.status
				}
			}
		case 'RESTORE_FORM_STATUS':
			return {
				...state,
				formStatus: action.forms
			}
		case 'UPDATE_CHAT_NOTIFICATION':
			return {
				...state,
				numberOfUnrealMessages: state.numberOfUnrealMessages + 1
			}
		case 'CLEAR_CHAT_NOTIFICATION': 
			return {
				...state,
				numberOfUnrealMessages: 0
			}
		case 'SHOW_CASHFLOW_EXERCISE': 
			return {
				...state,
				showCashFlowExercise: action.status
			}
		case 'SHOW_CASH_FLOW_ANSWERS':
			return {
				...state,
				showCashflowExerciseResult: true
			}
		default:
			break;
	}

	return state
}

export default reducer;
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Container, Button, Image, Modal, Icon, Grid } from 'semantic-ui-react'
import axios from "axios"
import moment from "moment"
import { Trans } from "react-i18next"

// CSS
import 'react-toastify/dist/ReactToastify.css';

// Utils
import urls from '../utils/urls';

// Dispatcher
import mapDispatchToProps from '../reducers/dispatcher';
//import RealtimeConnection from '../utils/RealtimeConnection';

// Components
import HeaderComponent from './partials/HeaderComponent';
import ChecklistHeader from './partials/ChecklistHeader';
import Board from './Board';
import Chat from './partials/Chat';
import ProfitLossStatement from './Forms/ProfitLossStatement';
import BalanceSheet from './Forms/BalanceSheet';
import BusinessReview from './Forms/BusinessReview';
import OptionalCashFlow from './partials/OptionalCashFlow';

// Images
import closeIcon from '../assets/Images/close-icon@2x.png';
import leftArrow from '../assets/Images/left-white-arrow.svg';
import rightArrow from '../assets/Images/right-white-arrow.svg';

import GuidedTourStart from './GuidedTourStart';
import GuidedTourEnd from './GuidedTourEnd';

class MainPage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: true,
			counter: {}
		}
	}

	componentDidMount() {
		if (localStorage.langId && localStorage.langId !== this.props.view.selectedLanguage) {
			this.props.setSelectedLanguage(localStorage.langId)
		}

		this.fetchChecklist()

		setInterval( () => this.checkedDropTime() , 5000)
	}

	fetchChecklist = () => {
		axios.post(urls.backendURL + "/checklist/list", {
				selectedLanguage: this.props.view.selectedLanguage,
				year: this.props.session.year.value,
				period: this.props.session.period,
				strategyId: (this.props.session.selectedStrategy.id) ? this.props.session.selectedStrategy.id : "",
				product: "Apples & Oranges Manufacturing"
			})
			.then(response => {
				this.props.restoreChecklist(response.data.data);
			})
	}

	onTabPressed = (tab) => {
		this.props.updateFinancialTabStatus(tab, true);
	}

	onFinancialStatementPress = () => {
		this.props.updateFinancialViewStatus(!this.props.view.financialStatementStatus);
	}

	onBusinessReviewClick = () => {
		if (this.props.view.formStatus.businessReview) {
			this.props.updateFinancialFormStatus("businessReview", !this.props.view.financialTab.businessReview)
		}
	}

	onCashFlowClick = () => {
		if (this.props.view.formStatus.cashFlow) {
			this.props.updateFinancialFormStatus("cashflow", !this.props.view.financialTab.cashflow)
		}
	}

	checkedDropTime = () => {
		const currentDate = moment();

		// Production
		const productionKeys = Object.keys(this.props.board.production);
		productionKeys.forEach( key => {
			this.props.board.production[key].forEach( (object, index) => {
				if (object.droppedDate) {
					const duration = moment.duration(currentDate.diff(object.droppedDate));
					if (duration.as('seconds') > 5) {
						this.props.removeDroppedTime('production', key, index)
					}
				}
			})
		})
		// Development
		const developmentKeys = Object.keys(this.props.board.development);
		developmentKeys.forEach( key => {
			if (this.props.board.development[key].droppedDate) {
				const duration = moment.duration(currentDate.diff(this.props.board.development[key].droppedDate));
				if (duration.as('seconds') > 5) {
					this.props.removeDroppedTime('development', key, -1)
				}
			}
		})
		// Investment
		const investmentKeys = Object.keys(this.props.board.investment);
		investmentKeys.forEach( key => {
			if (this.props.board.investment[key].droppedDate) {
				const duration = moment.duration(currentDate.diff(this.props.board.investment[key].droppedDate));
				if (duration.as('seconds') > 5) {
					this.props.removeDroppedTime('investment', key, -1)
				}
			}
		})
		// Cash
		const outgoingCashKeys = Object.keys(this.props.board.outgoingCash);
		outgoingCashKeys.forEach( key => {
			if (this.props.board.outgoingCash[key].droppedDate) {
				const duration = moment.duration(currentDate.diff(this.props.board.outgoingCash[key].droppedDate));
				if (duration.as('seconds') > 5) {
					this.props.removeDroppedTime('outgoingCash', key, -1)
				}
			}
		})
		// Owners
		const ownersKeys = Object.keys(this.props.board.owners);
		ownersKeys.forEach( key => {
			if (this.props.board.owners[key].droppedDate) {
				const duration = moment.duration(currentDate.diff(this.props.board.owners[key].droppedDate));
				if (duration.as('seconds') > 5) {
					this.props.removeDroppedTime('owners', key, -1)
				}
			}
		})
		// Lenders
		const lendersKeys = Object.keys(this.props.board.lenders);
		lendersKeys.forEach( key => {
			if (this.props.board.lenders[key].droppedDate) {
				const duration = moment.duration(currentDate.diff(this.props.board.lenders[key].droppedDate));
				if (duration.as('seconds') > 5) {
					this.props.removeDroppedTime('lenders', key, -1)
				}
			}
		})
		// community
		const communityKeys = Object.keys(this.props.board.community);
		communityKeys.forEach( key => {
			if (this.props.board.community[key].droppedDate) {
				const duration = moment.duration(currentDate.diff(this.props.board.community[key].droppedDate));
				if (duration.as('seconds') > 5) {
					this.props.removeDroppedTime('community', key, -1)
				}
			}
		})
		// Customers
		const customersKeys = Object.keys(this.props.board.customers);
		customersKeys.forEach( key => {
			if (this.props.board.customers[key].droppedDate) {
				const duration = moment.duration(currentDate.diff(this.props.board.customers[key].droppedDate));
				if (duration.as('seconds') > 5) {
					this.props.removeDroppedTime('customers', key, -1)
				}
			}
		})
	}

	onPrevYear = () => {
		if (this.props.session.selectedFormYear.title !== "Year 1") {
			let newYearTitle = this.props.session.year.title
			let newYear = this.props.session.year.value

			switch(this.props.session.selectedFormYear.title) {
				case "Year 1 Improved": 
					newYearTitle = "Year 1"
					newYear = 1
					break;
				case "Year 2":
					newYearTitle = "Year 1 Improved"
					newYear = 1
					break
				case "Year 3":
					newYearTitle = "Year 2"
					newYear = 2
					break;
				default:
					break
			}
			
			this.props.removeForm()
			this.props.changeFormYear(newYearTitle, newYear)
			//RealtimeConnection.updateFormYear(newYearTitle)
		}
	}

	onNextYear = () => {
		if (this.props.session.selectedFormYear.title !== "Year 3") {
			let newYearTitle = this.props.session.year.title
			let newYear = this.props.session.year.value

			switch(this.props.session.selectedFormYear.title) {
				case "Year 1": 
					newYearTitle = "Year 1 Improved"
					newYear = 1
					break;
				case "Year 1 Improved":
					newYearTitle = "Year 2"
					newYear = 2
					break
				case "Year 2":
					newYearTitle = "Year 3"
					newYear = 3
					break;
				default:
					break
			}
			
			this.props.removeForm()
			this.props.changeFormYear(newYearTitle, newYear)
			//RealtimeConnection.updateFormYear(newYearTitle)
		}
	}

	render() {
		let financialClass = (this.props.view.financialStatementStatus) ? "show" : "";
		let profitTabClass = (this.props.view.financialTab.profit) ? "active" : "";
		let balanceSheetTabClass = (this.props.view.financialTab.balanceSheet) ? "active" : "";
		let showChat = (this.props.view.openChat) ? "show" : "";
		let boardChatClass = (this.props.view.openChat && "moveRight") || (this.props.view.financialStatementStatus && "moveLeft");

		let yearTitle = this.props.session.selectedFormYear.title
		switch(this.props.session.selectedFormYear.title) {
			case "Year 1": 
				yearTitle = <><Trans>Board__Year</Trans> 1</>
				break;
			case "Year 1 Improved":
				yearTitle = <><Trans>Board__Year</Trans> 1 <Trans>Board__Improved</Trans></>
				break
			case "Year 2":
				yearTitle = <><Trans>Board__Year</Trans> 2</>
				break;
			case "Year 3":
				yearTitle = <><Trans>Board__Year</Trans> 3</>
				break;
			case "Year 4":
				yearTitle = <><Trans>Board__Year</Trans> 4</>
				break;
			default:
				break
		}

		const dir = this.props.view.selectedLanguage == 8 ? "rtl" : "ltr"

		return (
			<>
				<div className={ `boardPageContainer ${boardChatClass}` }>
					<Container className="height-100 pageMinWidth">
						{
							(this.props.view.guidedTourIntro) ?
								<GuidedTourStart />
								:
								null
						}
						{
							(this.props.view.guidedTourEnd) ?
								<GuidedTourEnd />
								:
								null
						}
						{
							(!this.props.view.guidedTourIntro && !this.props.view.guidedTourEnd) ?
								<>
									<HeaderComponent languages={ this.props.languages } counter={ this.state.counter } />
									{
										(this.props.view.formStatus.optionalCashflow && this.props.view.showCashFlowExercise) ?
											<OptionalCashFlow />
											:
											<>
												{
													(this.props.view.financialTab.businessReview) ?
														<BusinessReview />
														:
														<>
															<ChecklistHeader fetchChecklist={ this.fetchChecklist } />
															<Board />
														</>
												}
											</>
									}
								</>
								:
								null
						}
					</Container>
				</div>
				<Chat showChat={ showChat } />
				<div className={ `financialStatementContainer ${financialClass}` }>
					<div className="financial-header">
						<div className="title-container">
							<div className="closeBtn" onClick={ this.onFinancialStatementPress.bind(this) }>
								<Image src={ closeIcon } />
							</div>
							<h3><Trans>Board__Financial Statement</Trans></h3>
						</div>
						<div className="action-container">
							
						</div>
					</div>
					<div className="financial-forms-tab">
						<div className="switch-container">
							<Grid>
								<Grid.Row>
									<Grid.Column width={2} onClick={ this.onPrevYear.bind(this) }>
										<Image src={ leftArrow } className="cursor" />
									</Grid.Column>
									<Grid.Column width={12} style={{ padding: '0px' }}>
										<p>{ yearTitle }</p>
									</Grid.Column>
									<Grid.Column width={2} onClick={ this.onNextYear.bind(this) }>
										<Image src={ rightArrow } className="cursor" />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</div>
						<div className="form-actions">
							<div className={ `businessReviewContainer ${ (this.props.view.formStatus.businessReview) ? "enabled" : "" } ${ (this.props.view.financialTab.businessReview) ? "active" : false }` } onClick={ this.onBusinessReviewClick.bind(this) }>
								<Trans>Forms__Business Review</Trans>
							</div>
							<div className={ `cashFlowContainer ${ (this.props.view.formStatus.cashFlow) ? "enabled" : "" } ${ (this.props.view.financialTab.cashflow) ? "active" : false }` } onClick={ this.onCashFlowClick.bind(this) }>
								<Trans>Forms__Cash Flow</Trans>
							</div>
						</div>
					</div>
					<div className="form-buttons-header" dir={dir}>
						<Button 
							className={ profitTabClass }
							size='small'
							basic={true}
							color='grey'
							onClick={ this.onTabPressed.bind(this, "profit") }
						>
							<Trans>Forms__Profit & Loss Statement</Trans>
							{
								(this.props.session.isFormSubmitted.ProfitLossStatement) ?
									<Icon name='lock' style={{ marginLeft: "10px", marginRight: "0px" }} />
									:
									null
							}
						</Button>
						<div style={{ width: "14px" }}></div>
						<Button 
							className={ balanceSheetTabClass }
							size='small'
							basic={true}
							color='grey'
							onClick={ this.onTabPressed.bind(this, "balanceSheet") }
						>
							<Trans>Forms__Balance sheet</Trans>
							{
								(this.props.session.isFormSubmitted.BalanceSheet) ?
									<Icon name='lock' style={{ marginLeft: "10px", marginRight: "0px" }} />
									:
									null
							}
						</Button>
					</div>
					{
						(profitTabClass === "active") ?
							<ProfitLossStatement 
								session={ this.props.session }
							/>
							:
							null
					}
					{
						(balanceSheetTabClass === "active") ?
							<BalanceSheet
								session={ this.props.session }
							/>
							:
							null
					}
				</div>
				<Modal 
					open={ (this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "center") ? true : false }
				>
					<Modal.Header>
						{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }
					</Modal.Header>
					<Modal.Content>
						<p dangerouslySetInnerHTML={{ __html: this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }}></p>
					</Modal.Content>
					<Modal.Actions>
						{
							(this.props.guidedTour.currentStep === 0) ?
								<Button color='black' onClick={() => this.props.nextGuidedTour()}>
									{ 
										(this.props.guidedTour.steps[this.props.guidedTour.currentStep].buttons) ? 
											this.props.guidedTour.steps[this.props.guidedTour.currentStep].buttons.next
											:
											"Next"
									}
								</Button>
								:
								<>
									{
										(this.props.guidedTour.steps[this.props.guidedTour.currentStep].buttons) ? this.props.guidedTour.steps[this.props.guidedTour.currentStep].buttons.next : ""
									}
								</>
						}
					</Modal.Actions>
				</Modal>
				<Modal
					className="presentationPopup"
					size={ 'fullscreen' }
					open={ this.props.view.showPresentationPopup }
				>
					<Modal.Content image>
						<Image src={ this.props.view.presentationImg } wrapped />
					</Modal.Content>
				</Modal>
				<ToastContainer />
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		view: state.view,
		session: state.session,
		board: state.board,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
import React from "react";
const Close = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <g id="Group_1716" data-name="Group 1716" transform="translate(-20 -16)">
        <g id="close" transform="translate(26.19 22.302)">
          <path
            id="Path"
            d="M4.759,4.054,8.682,7.718l-.417.39L4.342,4.444.418,8.108,0,7.718,3.924,4.054,0,.39.417,0,4.342,3.664,8.265,0l.417.39Z"
            transform="translate(0 0)"
            fill="#fff"
            stroke="#144e5d"
            strokeWidth="1"
          />
        </g>
        <g
          id="Ellipse_15"
          data-name="Ellipse 15"
          transform="translate(20 16)"
          fill="none"
          stroke="#144e5d"
          strokeWidth="1"
        >
          <circle cx="10.5" cy="10.5" r="10.5" stroke="none" />
          <circle cx="10.5" cy="10.5" r="10" fill="none" />
        </g>
      </g>
    </svg>
  );
};

export default Close;

import React from "react";
import { connect } from "react-redux";

import "../css/guidedTourStart.css";
import mapDispatchToProps from "../reducers/dispatcher";

import orangeLogoImg from "../assets/Images/orange_logo.svg";

const GuidedTourStart = ({ guidedTourView }) => {

	const startGuidedTour = () => {
		guidedTourView("guidedTourIntro", false)
	}
	
	return (
		<div className="guidedtour-intro-container">
			<div className="content-container">
				<div className="img-container">
					<img src={orangeLogoImg} alt="loginImg" />
				</div>
				<div className="text-container">
					<p className="header">Welcome to a guided tour of Apples & Oranges Inc!</p>
					<p className="text">
						As you have been recruited to the management team here is a chance to understand the basics, 
						so that you can focus on strategy, finances and profitability in your upcoming session.
					</p>
					<p className="text">
						This tour will get you acquainted with A&O Inc. 
						and give you a chance to learn how to perform a few, fairly simple tasks needed to run the business. 
						Are you ready?
					</p>
					<button onClick={startGuidedTour}>Take the tour</button>
				</div>
			</div>
		</div>
	);
};
export default connect(null, mapDispatchToProps)(GuidedTourStart);

const state = {
	sales: "60",
	costOfGoodsSold: "24",
	contribution: "36",
	overhead: "15",
	development: "6",
	depreciation: "4",
	totalCommonCosts: "25",
	ebit: "11",
	interestExpense: "2",
	profitBeforeTaxes: "9",
	taxes: "3",
	netProfit: "6"
}

export default state;

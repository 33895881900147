import moment from "moment"

import initState from "./initialStates/board-state";

const reducer = (state = initState, action) => {
	//console.log(action);
	switch (action.type) {
		case 'UPDATE_STATE':
			state = action.data.state;
			return {
				...state
			}
		case "ADD_CONTAINER":
			if (action.id === -1) {
				if (action.alias === "") {
					const fieldObj = { ...state[action.block] }
					//console.log(fieldObj)
					return {
						...state,
						[action.block]: {
							...state[action.block],
							hasContainer: true,
							coins: fieldObj.coins + action.coins,
							playerId: action.playerId,
							playerName: action.playerName,
							droppedDate: moment()
						}
					}
				}else{
					const fieldObj = { ...state[action.block][action.alias] }
					return {
						...state,
						[action.block]: {
							...state[action.block],
							[action.alias]: {
								...state[action.block][action.alias],
								hasContainer: true,
								coins: fieldObj.coins + action.coins,
								playerId: action.playerId,
								playerName: action.playerName,
								droppedDate: moment()
							}
						}
					}
				}
			}else{
				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: state[action.block][action.alias].map( (content, id) => 
							(id === action.id) ? { ...content, hasContainer: true, coins: content.coins + action.coins, playerId: action.playerId, playerName: action.playerName, droppedDate: moment() } : content
						)
					}
				}
			}
		case "REMOVE_CONTAINER":
			if (action.id === -1) {
				if (action.alias === "") {
					return {
						...state,
						[action.block]: {
							...state[action.block], 
							hasContainer: (state[action.block].modifiedCoins > 0) ? true : false, 
							coins: (state[action.block].modifiedCoins > 0) ? state[action.block].modifiedCoins : 0,
							modifiedCoins: 0,
							droppedDate: undefined
						}
					}
				}else{
					return {
						...state,
						[action.block]: {
							...state[action.block],
							[action.alias]: { 
								...state[action.block][action.alias], 
								hasContainer: (state[action.block][action.alias].modifiedCoins > 0) ? true : false, 
								coins: (state[action.block][action.alias].modifiedCoins > 0) ? state[action.block][action.alias].modifiedCoins : 0,
								modifiedCoins: 0,
								droppedDate: undefined
							}
						}
					}
				}
			}else{
				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: state[action.block][action.alias].map( (content, id) => 
							(id === action.id) ? { 
								...content, 
								hasContainer: (content.modifiedCoins > 0) ? true : false, 
								coins: (content.modifiedCoins > 0) ? content.modifiedCoins : 0,
								modifiedCoins: 0,
								droppedDate: undefined 
							} : content
						)
					}
				}
			}
		case "REMOVE_MODIFIED_CONTAINER": {
			if (action.id === -1) {
				const coins = state[action.block][action.alias].coins;

				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: { 
							...state[action.block][action.alias], 
							modifiedCoins: 0,
							hasContainer: (coins > 0) ? true : false
						}
					}
				}
			}else{
				const coins = state[action.block][action.alias][action.id].coins;

				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: state[action.block][action.alias].map( (content, id) => 
							(id === action.id) ? { 
								...content, 
								modifiedCoins: 0,
								hasContainer: (coins > 0) ? true : false
							} : content
						)
					}
				}
			}
		}
		case "REVERT_MODIFIED_CONTAINER": {
			if (action.id === -1) {
				let coins = state[action.block][action.alias].coins;
				let modifiedCoins = state[action.block][action.alias].modifiedCoins;
				if (action.alias === "liabilities") {
					coins -= modifiedCoins
				}else{
					coins += modifiedCoins
				}

				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: { 
							...state[action.block][action.alias], 
							modifiedCoins: 0,
							coins: coins
						}
					}
				}
			}else{
				let coins = state[action.block][action.alias][action.id].coins;
				let modifiedCoins = state[action.block][action.alias][action.id].modifiedCoins;
				coins += modifiedCoins

				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: state[action.block][action.alias].map( (content, id) => 
							(id === action.id) ? { ...content, modifiedCoins: 0, coins: coins } : content
						)
					}
				}
			}
		}
		case "ADD_COIN": {
			if (action.id === -1) {
				let coins = state[action.block][action.alias].coins;
				coins += action.value

				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: { 
							...state[action.block][action.alias], 
							coins: coins
						}
					}
				}
			}else{
				let coins = state[action.block][action.alias][action.id].coins;
				coins += action.value

				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: state[action.block][action.alias].map( (content, id) => 
							(id === action.id) ? { ...content, coins: coins } : content
						)
					}
				}
			}
		}
		case "REMOVE_COIN": {
			if (action.id === -1) {
				let coins = state[action.block][action.alias].coins;
				if (coins > 0) {
					coins -= 1
				}

				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: { 
							...state[action.block][action.alias], 
							coins: coins,
							hasContainer: (coins > 0) ? true : false
						}
					}
				}
			}else{
				let coins = state[action.block][action.alias][action.id].coins;
				if (coins > 0) {
					coins -= 1
				}

				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: state[action.block][action.alias].map( (content, id) => 
							(id === action.id) ? { 
								...content, 
								coins: coins,
								hasContainer: (coins > 0) ? true : false
							} : content
						)
					}
				}
			}
		}
		case "ADD_CUSTOM_COIN": {
			if (action.id === -1) {
				let modifiedCoins = parseInt(state[action.block][action.alias].modifiedCoins);
				let coins = parseInt(state[action.block][action.alias].coins);

				if (action.alias === "liabilities") {
					modifiedCoins += parseInt(action.value)
					coins += parseInt(action.value)
				}else{
					if (coins > 0) {
						coins += modifiedCoins;
					}
					if (modifiedCoins < coins) {
						modifiedCoins += parseInt(action.value)
						coins -= modifiedCoins
					}
				}
				
				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: { 
							...state[action.block][action.alias], 
							modifiedCoins: modifiedCoins,
							coins: coins
						}
					}
				}
			}else{
				let modifiedCoins = parseInt(state[action.block][action.alias][action.id].modifiedCoins);
				let coins = parseInt(state[action.block][action.alias][action.id].coins);
				if (coins > 0) {
					coins += modifiedCoins;
				}

				if (modifiedCoins < coins) {
					modifiedCoins += parseInt(action.value)
					coins -= modifiedCoins
				}

				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: state[action.block][action.alias].map( (content, id) => 
							(id === action.id) ? { ...content, modifiedCoins: modifiedCoins, coins: coins } : content
						)
					}
				}
			}
		}
		case "REMOVE_CUSTOM_COIN": {
			if (action.id === -1) {
				let coins = parseInt(state[action.block][action.alias].coins);
				let modifiedCoins = parseInt(state[action.block][action.alias].modifiedCoins);
				if (modifiedCoins > 0) {
					modifiedCoins -= parseInt(action.value)

					if (action.alias === "liabilities") {
						coins -= parseInt(action.value)
					}else{
						coins += parseInt(action.value)
					}
				}

				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: { 
							...state[action.block][action.alias], 
							modifiedCoins: modifiedCoins,
							coins: coins
						}
					}
				}
			}else{
				let coins = parseInt(state[action.block][action.alias][action.id].coins);
				let modifiedCoins = parseInt(state[action.block][action.alias][action.id].modifiedCoins);
				if (modifiedCoins > 0) {
					modifiedCoins -= parseInt(action.value)
					coins += parseInt(action.value)
				}

				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: state[action.block][action.alias].map( (content, id) => 
							(id === action.id) ? { ...content, modifiedCoins: modifiedCoins, coins: coins } : content
						)
					}
				}
			}
		}
		case 'DRAGGING': {
			if (action.data.id === -1) {
				if (action.data.alias === "") {
					return {
						...state,
						[action.data.section]: { 
							...state[action.data.section], 
							isDragging: action.data.isDragging,
							draggingField: action.data.objectType,
							playerId: action.data.playerId,
							playerName: action.data.playerName
						}
					}
				}else{
					return {
						...state,
						[action.data.section]: {
							...state[action.data.section],
							[action.data.alias]: { 
								...state[action.data.section][action.data.alias], 
								isDragging: action.data.isDragging,
								draggingField: action.data.objectType,
								playerId: action.data.playerId,
								playerName: action.data.playerName
							}
						}
					}
				}
			}else{
				console.log(action.data);
				return {
					...state,
					[action.data.section]: {
						...state[action.data.section],
						[action.data.alias]: state[action.data.section][action.data.alias].map( (content, id) => 
							(id === action.data.id) ? { ...content, isDragging: action.data.isDragging, playerId: action.data.playerId, playerName: action.data.playerName, draggingField: action.data.objectType } : content
						)
					}
				}
			}
		}
		case "REMOVE_DROP_TIME":
			if (action.id === -1) {
				if (action.alias === "") {
					return {
						...state,
						[action.block]: {
							...state[action.block],
							droppedDate: undefined
						}
					}
				}else{
					return {
						...state,
						[action.block]: {
							...state[action.block],
							[action.alias]: {
								...state[action.block][action.alias],
								droppedDate: undefined
							}
						}
					}
				}
			}else{
				return {
					...state,
					[action.block]: {
						...state[action.block],
						[action.alias]: state[action.block][action.alias].map( (content, id) => 
							(id === action.id) ? { ...content, droppedDate: undefined } : content
						)
					}
				}
			}
		default:
			break;
	}

	return state
}

export default reducer;
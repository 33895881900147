import React from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'


// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// CSS
import '../../../css/boardOwners.css';

class Owners extends React.Component {

	render() {
		return (
			<div className="ownersBlock">
				<Grid columns={3}>
					<Grid.Row>
						<Grid.Column className="noColumnPadding">
							<div className="blockTitle"><Trans>Board__Owners</Trans></div>
						</Grid.Column>
						<Grid.Column className="center">
							<div className={ `droppableField` }></div>
						</Grid.Column>
						<Grid.Column className="center">
							<div className={ `droppableField` }></div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column className="noColumnPadding">
							<Popup position='top center' content={ <Trans>Glossary__Owners</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
						</Grid.Column>
						<Grid.Column className="flexColumn">
							<p><Trans>Board__Dividends</Trans></p>
						</Grid.Column>
						<Grid.Column className="flexColumn">
							<p><Trans>Board__Equity</Trans></p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Owners);
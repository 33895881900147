/*
{
	position: 'center',
	title: "Welcome to a guided tour of Apples & Oranges Inc!",
	message: "As you have been recruited to the management team here is a chance to understand the basics, so that you can focus on strategy, finances and profitability in your upcoming session.",
	buttons: {
		next: "Start"
	},
	validation: {}
},
*/
const initState = {
	currentStep: 0,
	steps: [
		{
			position: 'sequence',
			title: "",
			message: "Inside the grey frame is a schematic view of A&O inc.",
			step: 0,
			validation: {}
		},
		{
			position: 'sequence',
			title: "",
			message: "The blue line represents your full production area.",
			step: 1,
			validation: {}
		},
		{
			position: 'sequence',
			title: "",
			message: "The green line represents your overhead and development.",
			step: 2,
			validation: {}
		},
		{
			position: 'sequence',
			title: "",
			message: "The yellow line represents your long-term assets and investments.",
			step: 3,
			validation: {}
		},
		{
			position: 'sequence',
			title: "",
			message: "The orange line represents your finance department.",
			step: 4,
			validation: {}
		},
		{
			position: 'sequence2',
			title: "",
			message: "Let’s go outside the grey line to learn a little bit more about your suppliers and customers.",
			step: 0,
			validation: {}
		},
		{
			position: 'sequence2',
			title: "",
			message: "This grey area represents your suppliers, from which you buy materials needed for your production",
			step: 1,
			validation: {}
		},
		{
			position: 'sequence2',
			title: "",
			message: "This grey area represents customers who buy your products. In the bottom you can see the current remaining market potential.",
			step: 2,
			validation: {}
		},
		{
			position: 'sequence2',
			title: "",
			message: "Between the customer area and the company is a cost center area, where you record various costs for the year.",
			step: 3,
			validation: {}
		},
		{
			position: 'productionBlock',
			title: "",
			message: "Let’s go back inside your company and look a little closer. Numbers found in circles represent  values within the company. In the blue production line, the icons represent batches of products. When hovering over these icons, the current value of the batch will contain (2, 3 or 4 million), depending on a batches’ progress through your production process. To move a batch, simply drag and drop it from one spot to another.",
			buttons: {
				next: "Try it"
			},
			step: 0
		},
		{
			position: 'productionBlock',
			title: "Try it: Move a batch",
			message: "Grab the indicated batch in Stock of Finished Goods and move it to the indicated spot in Cost of Goods Sold.",
			step: 1,
			validation: {
				type: "coins",
				value: 4
			}
		},
		{
			position: 'accountsReceivable',
			title: "Try it: Move full value",
			message: "Move all the Accounts receivable into Cash. This is done in the same way as for the batches, i.e. grab the ”20” and move to Cash.",
			validation: {
				type: "coins",
				value: 29
			}
		},
		{
			position: 'orangeBlock',
			title: "Try it: Move a specific value",
			message: "You can also move values by using the counter and choose how much to move. Try it out by paying 2M from Cash to Development (in the cost center on the right hand side). Simply click the 29M in Cash, indicate  2M on the counter, and then drag and drop the 2M to Development.",
			validation: {
				type: "coins",
				value: 2
			}
		},
		{
			position: 'boardYear',
			title: "",
			message: "Now let’s have a look at the process of running the operation. This is the current year and the active period.",
			validation: {}
		},
		{
			position: 'checklist',
			title: "Checklist",
			message: "This is your checklist. It will instruct you which move to make on the board during each period. You can click the chevron (down arrow) to expand and collapse the list.",
			validation: {}
		},
		{
			position: 'teamName',
			title: "This is your team name",
			message: "You can click on it to see all members on your management team, sharing the same board",
			buttons: {
				next: "Next"
			},
			validation: {}
		},
		{
			position: 'language',
			title: "",
			message: "Here you can change the language, if you prefer to have the board in a different language",
			buttons: {
				next: "Next"
			},
			validation: {}
		},
		{
			position: 'raiseHand',
			title: "",
			message: "At any time while in breakout rooms you can use click this icon to \"raise your hand\" and get the facilitator's attention.",
			buttons: {
				next: "Next"
			},
			validation: {}
		},
		{
			position: 'chat',
			title: "",
			message: "This is a team chat for everyone sharing this board",
			buttons: {
				next: "Next"
			},
			validation: {}
		},
		{
			position: 'financialStatement',
			title: "",
			message: "As you get to the end of a session year you will have to fill out the financial statements to better understand your business. When clicking on this button they open up for you to fill in individually, and then you can compare results with your team members to see if you align.",
			buttons: {
				next: "Next"
			},
			validation: {}
		},
		{
			position: 'end'
		}
	]
};

export default initState;
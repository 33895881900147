const initState = {
	ProfitLossStatement: {
		sales: "",
		costOfGoodsSold: "",
		contribution: "",
		overhead: "",
		development: "",
		depreciation: "",
		totalCommonCosts: "",
		ebit: "",
		interestExpense: "",
		profitBeforeTaxes: "",
		taxes: "",
		netProfit: ""
	},
	BalanceSheet: {
		cash: "",
		accountRecievable: "",
		materials: "",
		workInProgress: "",
		finishedGoods: "",
		totalCurrentAssets: "",
		plantAndEquipment: "",
		realProperty: "",
		totalFixedAssets: "",
		assetsTotal: "",
		shortTermLiabilities: "",
		longTermLiabilities: "",
		totalLiabilities: "",
		beginningBalance: "",
		netProfit: "",
		totalEquity: "",
		liabilityTotal: ""
	},
	BusinessReview: {
		dupont: {
			sales: "",
			costOfGoodsSold: "",
			contribution: "",
			commonCosts: "",
			operatingProfit: "",
			interestAndTaxes: "",
			netProfit: "",
			returnONAssets: "",
			returnONEquity: "",
			currentAssets: "",
			fixedAssets: "",
			totalAssets: "",
			equity: "",
			liabilities: "",
		},
		indicators: {
			contribution: "",
			contributionSales: "",
			operatingProfit: "",
			operatingSales: "",
			equity: "",
			assets: ""
		}
	},
	CashFlow: {
		workingCapital: {
			accountsReceivable: "",
			materials: "",
			workInProgress: "",
			finishedGoods: "",
			accountsPayable: "",
			workingCapital: "",
			changeInWorkingCapital: ""
		},
		operations: {
			sales: "",
			costOfGoodsSold: "",
			overheads: "",
			development: "",
			taxPaidForYear1: "",
			interestExpenses: "",
			changeInWorkingCapital: "",
			cashFlowFromOperations: ""
		},
		investment: {
			plantAndEquipment: "",
			realProperty: "",
			intellectualProperty: "",
			cashFlowFromInvestments: ""
		},
		financing: {
			changeInLoans: "",
			dividends: "",
			cashFlowFromFinancing: ""
		},
		cashBalance: {
			cashAtTheStart: "",
			cashFlowThisYear: "",
			cashAtYearEnd: ""
		}
	}
};

export default initState;
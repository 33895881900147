const initState = {
	suppliers: {
		hasContainer: false,
		coins: 0,
		modifiedCoins: 0
	},
	production: {
		stockOfMaterial: [
			{
				hasContainer: true,
				coins: 2,
				modifiedCoins: 0
			},
			{
				hasContainer: true,
				coins: 2,
				modifiedCoins: 0
			},
			{
				hasContainer: true,
				coins: 2,
				modifiedCoins: 0
			},
			{
				hasContainer: true,
				coins: 2,
				modifiedCoins: 0
			}
		],
		manufacturing: [
			{
				hasContainer: true,
				coins: 3,
				modifiedCoins: 0
			},
			{
				hasContainer: true,
				coins: 3,
				modifiedCoins: 0
			}
		],
		assembly: [
			{
				hasContainer: true,
				coins: 4,
				modifiedCoins: 0
			},
			{
				hasContainer: true,
				coins: 4,
				modifiedCoins: 0
			}
		],
		finishedGoods: [
			{
				hasContainer: true,
				coins: 4,
				modifiedCoins: 0
			},
			{
				hasContainer: true,
				coins: 4,
				modifiedCoins: 0
			},
			{
				hasContainer: true,
				coins: 4,
				modifiedCoins: 0
			},
			{
				hasContainer: true,
				coins: 4,
				modifiedCoins: 0
			},
			{
				hasContainer: true,
				coins: 4,
				modifiedCoins: 0
			},
			{
				hasContainer: false,
				coins: 0,
				modifiedCoins: 0
			}
		],
		goodsSold: [
			{
				hasContainer: false,
				coins: 0,
				modifiedCoins: 0
			},
			{
				hasContainer: false,
				coins: 0,
				modifiedCoins: 0
			},
			{
				hasContainer: false,
				coins: 0,
				modifiedCoins: 0
			},
			{
				hasContainer: false,
				coins: 0,
				modifiedCoins: 0
			},
			{
				hasContainer: false,
				coins: 0,
				modifiedCoins: 0
			},
			{
				hasContainer: false,
				coins: 0,
				modifiedCoins: 0
			},
			{
				hasContainer: false,
				coins: 0,
				modifiedCoins: 0
			},
			{
				hasContainer: false,
				coins: 0,
				modifiedCoins: 0
			},
			{
				hasContainer: false,
				coins: 0,
				modifiedCoins: 0
			},
			{
				hasContainer: false,
				coins: 0,
				modifiedCoins: 0
			}
		]
	},
	development: {
		development: {
			hasContainer: false,
			coins: 0,
			modifiedCoins: 0
		},
		overhead: {
			hasContainer: false,
			coins: 0,
			modifiedCoins: 0
		}
	},
	investment: {
		realProperty: {
			hasContainer: true,
			coins: 12,
			modifiedCoins: 0
		},
		equipment: {
			hasContainer: true,
			coins: 16,
			modifiedCoins: 0
		},
		intellectualProperty: {
			hasContainer: false,
			coins: 0,
			modifiedCoins: 0
		},
		depreciation: {
			hasContainer: false,
			coins: 0,
			modifiedCoins: 0
		}
	},
	outgoingCash: {
		cash: {
			hasContainer: true,
			coins: 9,
			modifiedCoins: 0
		},
		accountsReceivable: {
			hasContainer: true,
			coins: 20,
			modifiedCoins: 0
		}
	},
	owners: {
		dividends: {
			hasContainer: false,
			coins: 0,
			modifiedCoins: 0
		},
		equity: {
			hasContainer: false,
			coins: 0,
			modifiedCoins: 0
		}
	},
	lenders: {
		interest: {
			hasContainer: false,
			coins: 0,
			modifiedCoins: 0
		},
		liabilities: {
			hasContainer: true,
			coins: 60,
			modifiedCoins: 0
		}
	},
	community: {
		taxes: {
			hasContainer: false,
			coins: 0,
			modifiedCoins: 0
		}
	},
	customers: {
		remainingMarket: {
			hasContainer: true,
			coins: 100,
			modifiedCoins: 0
		}
	}
};

export default initState;
const state = {
	dupont: {
		sales: "60",
		costOfGoodsSold: "24",
		contribution: "36",
		commonCosts: "27",
		operatingProfit: "9",
		interestAndTaxes: "7",
		netProfit: "2",
		returnONAssets: "9%",
		returnONEquity: "5%",
		currentAssets: "76",
		fixedAssets: "24",
		totalAssets: "100",
		equity: "39",
		liabilities: "61",
	},
	indicators: {
		contribution: "36",
		contributionSales: "60",
		operatingProfit: "9",
		operatingSales: "60",
		equity: "39",
		assets: "100"
	}
}

export default state;

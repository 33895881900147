import React from 'react';
import { connect } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';


class Customers extends React.Component {

	render() {
		return (
			<div className="customersContainer">
				<div className="customersText">
					<p>
						<Trans>Board__Customers</Trans> 
						<Popup position='top center' content={ <Trans>Glossary__Customers</Trans> } size='mini' trigger={<Icon name='plus circle' style={{ marginTop: "10px" }} />} />
					</p>
				</div>
				<div className="remainingContainer">
					<p><Trans>Board__Remaining market potential</Trans></p>
					<div className={ `droppableField` }></div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
import axios from "axios";
import URLs from "../utils/urls";

export const GET_CHATS = "get_chat";
export const AFTER_POST_MESSAGE = "after_post_message";

export async function getChats(params) {
	const request = await axios
		.get(URLs.socketEndpoint + "/api/chat/getChats", { params })
		.then((response) => response.data);

	return {
		type: GET_CHATS,
		payload: request,
		hasMore: request.length > 0 ? true : false,
	};
}

export function afterPostMessage(data) {
	return {
		type: AFTER_POST_MESSAGE,
		payload: data,
	};
}

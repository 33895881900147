import React, { useState } from "react";
import {useDrag, useDrop} from 'react-dnd';

import DraggableCoin from "./DraggableCoin";

export default function MainDraggableContainer(props) {
	const [isUpdateSocket, setIsUpdateSocket] = useState(false);

	const [{ isDragging }, drag] = useDrag({
		item: { 
			type: "Container", 
			coins: props.field.coins,
			block: props.block,
			alias: props.alias,
			id: props.id
		},
		collect: monitor => ({
			isDragging: !!monitor.isDragging()
		}),
		end: (_dropResult, monitor) => {
			const doppableItem = monitor.getDropResult();
			if (doppableItem !== null) {
				if (doppableItem.type !== undefined && monitor.didDrop()) {
					props.removeContainer(props.alias, props.id, props.coins);
				}
			}
		}
	});

	const [, drop] = useDrop({
		accept: "Container",
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			//const allCoins = parseInt(props.field.coins) + parseInt(item.coins);
			props.addContainer(props.alias, props.id, item.coins);
			return item
		},
	})
	
	let canPlayerDrag = (window.canEdit) ? drag : null;
	let canPlayerDrop = (window.canEdit) ? drop : null;

	if (window.canEdit) {
		if (isDragging) {
			canPlayerDrop = null
		}
	
		let dragClass = "";
		if (isDragging) {
			dragClass = "dragging";
	
			if (!isUpdateSocket) {
				setIsUpdateSocket(true)
				//props.canDropAction(false);
	
				let dragObjData = {}
				dragObjData.alias = props.alias
				dragObjData.id = props.id
				dragObjData.coins = props.coins
				if (props.isDraggingHandler)
					props.isDraggingHandler("CONTAINER", isDragging, dragObjData);
			}
		}else if (props.isDragging && props.draggingField === "CONTAINER") {
			dragClass = "dragging";
			//props.canDropAction(false);
	
			canPlayerDrag = null;
			canPlayerDrop = null;
	
			if (props.playerId === window.playerId){
				if (isUpdateSocket) {
					setIsUpdateSocket(false)
	
					let dragObjData = {}
					dragObjData.alias = props.alias
					dragObjData.id = props.id
					dragObjData.coins = props.coins
					if (props.isDraggingHandler)
						props.isDraggingHandler("CONTAINER", isDragging, dragObjData);
				}
			}
		}
	
		if (props.isDragging) {
			if (props.playerId !== window.playerId){
				dragClass = "dragging";
				canPlayerDrag = null;
				canPlayerDrop = null;
			}
		}
	}

	return (
		<div className="matrialBlock" ref={canPlayerDrag}>
			<div className={ `materialDrop ${ (props.field.coins > 0) ? "suppliersHasContainer" : "" }` } ref={canPlayerDrop}>
				{
					(props.field.coins > 0) ?
						<DraggableCoin 
							block={props.block}
							alias={ props.alias }
							id={ props.id }
							value={ props.field.coins }
							drawCoins={ props.drawCoins }
						/>
						:
						null
				}
			</div>
		</div>
	);
}
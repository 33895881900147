import React from 'react';
import axios from "axios";
import moment from 'moment';
import { Container, Button, Dropdown, Image } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import i18n from '../../i18n'

// Dispatcher
import mapDispatchToProps from '../../reducers/dispatcher';

// Utils
import URLs from '../../utils/urls';

// Images
import raiseHandIcon from '../../assets/Images/raise-hand.svg';
import activeRaiseHandIcon from '../../assets/Images/active-raise-hand.svg';
import chatIcon from '../../assets/Images/chat-icon.svg';

class HeaderComponent extends React.Component {

	counterInterval = undefined;
	state = {
		counter: {
			title: "Play with guided tour"
		},
		counterTime: "15:00",
		fetchingData: false,
		team: undefined
	}

	componentDidMount() {
		//this.fetchTeam()
		//this.fetchCounter()
	}

	onFinancialStatementPress = () => {
		this.props.updateFinancialViewStatus(!this.props.view.financialStatementStatus);
	}

	onChatPress = () => {
		this.props.updateChatViewStatus(!this.props.view.openChat)
		this.props.clearChatNotification()
	}

	fetchCounter = () => {
		if (!this.state.fetchingData) {
			this.setState({ fetchingData: true })

			axios.post(URLs.backendURL + "/counters/active", {
				sessionId: window.sessionId
			})
			.then(response => {
				this.setState({ fetchingData: false })

				if (response.data.success === true){
					if (response.data.data) {
						let counter = response.data.data;
	
						let currentDate = moment().utc();
						let afterAddingMinutes = moment(counter.createdAt).utc().add(counter.duration, 'm');
						
						let duration = moment.duration(afterAddingMinutes.diff(currentDate))
						if (duration.get('milliseconds') > 0) {
							this.setState({ counter: counter }, () => {
								this.counterInterval = setInterval(this.counterLoop, 1000);
							});
						}
					}else{
						if(this.counterInterval) {
							clearInterval(this.counterInterval);
						}
					}
				}
			})
		}
	}

	/*fetchTeam = () => {
		axios.post(URLs.backendURL + "/teams/details", {
			teamId: window.teamId
		})
		.then(response => {
			let team = response.data.team;
			// Get Player Name
			team.SessionPlayers.forEach( player => {
				if (player.Player.id === window.playerId) {
					window.playerName = player.Player.name
				}
			})
			
			this.setState({ team: team }, () => {
				this.props.updateTeam(this.state.team)
			})
		})
	}*/

	counterLoop = () => {
		let currentDate = moment().utc();
		let afterAddingMinutes = moment(this.state.counter.createdAt).utc().add(this.state.counter.duration, 'm');
		
		let duration = moment.duration(afterAddingMinutes.diff(currentDate))

		if (duration.get('milliseconds') > 0) {
			const minutes = (duration.get('minutes') >= 10) ? duration.get('minutes') : "0" + duration.get('minutes');
			const seconds = (duration.get('seconds') >= 10) ? duration.get('seconds') : "0" + duration.get('seconds');

			this.setState({ counterTime: `${minutes}:${seconds}` });
		}else{
			this.setState({ counter: {}, counterTime: `00:00` });
			clearInterval(this.counterInterval)
		}

	}

	onHandClick = () => {
		let requestHelpStatus = !this.props.session.requestHelp

		this.props.requestHelp(requestHelpStatus)
	}

	onChangeLanguage = (id) => {
		localStorage.langId = id
		this.props.setSelectedLanguage(id)

		i18n.changeLanguage(`${ id }`)
		window.location.reload()
	}

	downloadFileAction = () => {
		window.open(`${ URLs.backendURL }/files/download/${ window.sessionId }/${ localStorage.langId }`, '_blank');
	}

	render() {
		let showFinancialStatement = true

		const selectedLanguage = this.props.languages.filter( language => language.id === parseInt(this.props.view.selectedLanguage))
		let selectedLanguageText = "English"
		if (selectedLanguage.length > 0) {
			selectedLanguageText = selectedLanguage[0].language
		}
		
		return (
			<Container className="header-style">
				<div className="header-Container" style={{ position: "relative" }}>
					<div className="mainSegmentHeader">
						{
							(showFinancialStatement) ?
								<div style={{ position: "relative", float: "left" }}>
									<Button size='small' color='grey' style={{ float: "left" }} basic={true} onClick={ this.onFinancialStatementPress.bind(this) }>
										<Trans>Board__Financial Statement</Trans>
									</Button>
									{ 
										(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "financialStatement" &&
											<div className="guidedTourPopup">
												<div className="upArrow"></div>
												<h4>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h4>
												<p>
													{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }
												</p>
												<div className="actions">
													{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
													<div className="nextBtnContainer" onClick={ () => this.props.guidedTourView("guidedTourEnd", true) }></div>
												</div>
											</div>	
										)
									}
								</div>
								:
								null
						}
						{
							(this.props.view.formStatus.optionalCashflow) ?
								<Button className="cashflowBtn" size='small' color='grey' basic={!this.props.view.showCashFlowExercise} style={{ float: "left" }} onClick={ () => this.props.showCashflowExercise(!this.props.view.showCashFlowExercise) }>
									<Trans>Board__Cashflow Exercise</Trans>
								</Button>
								:
								null
						}
						<div className="header-right-section">
							{
								(!this.props.view.smallBoardStatus && this.props.session.team) ?
									<div style={{ position: "relative" }}>
										<Dropdown className="teamNameDropDown" text={ this.props.session.team.name }>
											<Dropdown.Menu>
												{
													this.props.session.team.SessionPlayers.map( (player, index) =>
														<Dropdown.Item text={ player.Player.name } key={index} />
													)
												}
											</Dropdown.Menu>
										</Dropdown>
										{ 
											(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "teamName" &&
												<div className="guidedTourPopup">
													<div className="upArrow"></div>
													<h4>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h4>
													<p>
														{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }
													</p>
													<div className="actions">
														{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
														<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
													</div>
												</div>	
											)
										}
									</div>
									:
									null
							}
							<div style={{ position: "relative" }}>
								<Dropdown className="languageDropDown" text={ selectedLanguageText }>
									<Dropdown.Menu>
										{
											this.props.languages.map( language => {
												const languageText = `${ language.language } ${ (language.status === 1) ? " (BETA)" : "" }`
												return (
													<Dropdown.Item key={ language.id } onClick={ this.onChangeLanguage.bind(this, language.id) } text={ languageText } />
												)
											})
										}
									</Dropdown.Menu>
								</Dropdown>
								{ 
									(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "language" &&
										<div className="guidedTourPopup">
											<div className="upArrow"></div>
											<h4>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h4>
											<p>
												{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }
											</p>
											<div className="actions">
												{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
												<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
											</div>
										</div>	
									)
								}
							</div>
						</div>
					</div>
					{
						(this.state.counter.id) ?
							<div className="counterContainer">
								<div className="counterContainerClipper">
									<div className="counter-title midnight-color">
										{ this.state.counter.title }
									</div>
									<div className="timer midnight-color">
										{ this.state.counterTime }
									</div>
								</div>
							</div>
							:
							null
					}
					<div className="raisHandSegment" style={{ position: "relative" }}>
						<div className="customHeaderBtn" onClick={ this.onHandClick.bind(this) }>
							{
								(this.props.session.requestHelp) ? 
									<Image src={ activeRaiseHandIcon } />
									:
									<Image src={ raiseHandIcon } />
							}
						</div>
						{ 
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "raiseHand" &&
								<div className="guidedTourPopup right">
									<div className="upArrow"></div>
									<h4>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h4>
									<p>
										{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }
									</p>
									<div className="actions">
										{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
										<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
									</div>
								</div>	
							)
						}
					</div>
					<div className="chatSegment" style={{ position: "relative" }}>
						<div className="customHeaderBtn" onClick={ this.onChatPress.bind(this) }>
							<Image src={ chatIcon } />
						</div>
						{
							(this.props.view.numberOfUnrealMessages > 0) ?
								<div className="chatNotifications">{ this.props.view.numberOfUnrealMessages }</div>
								:
								null
						}
						{ 
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "chat" &&
								<div className="guidedTourPopup right">
									<div className="upArrow"></div>
									<h4>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h4>
									<p>
										{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }
									</p>
									<div className="actions">
										{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
										<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
									</div>
								</div>	
							)
						}
					</div>
				</div>
			</Container>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		view: state.view,
		session: state.session,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
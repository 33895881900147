import React from "react";
import { Trans } from "react-i18next"

const Branching = (props) => {
  return (
    <div style={{ position: "relative", marginTop: props.marginTop }}>
      {props.NoOperator ? (
        ""
      ) : (
        <div
          className={props.ltr ? "minus" : "minusAr"}
          style={{ textIndent: props.string ? "11px" : "" }}
        >
          <p>{props.string ? props.string : <Trans>Board__Minus</Trans>}</p>
        </div>
      )}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.296"
        height="89.306"
        viewBox="0 0 18.296 89.306"
        style={props.style}
      >
        <g
          id="Group_1689"
          data-name="Group 1689"
          transform="translate(-684.5 -238.5)"
        >
          <line
            id="Line_61"
            data-name="Line 61"
            y2="88.929"
            transform="translate(693.5 238.5)"
            fill="none"
            stroke="#144e5d"
            strokeWidth="1"
          />
          <line
            id="Line_62"
            data-name="Line 62"
            x2="9.071"
            transform="translate(693.725 283.676)"
            fill="none"
            stroke="#144e5d"
            strokeWidth="1"
          />
          <line
            id="Line_63"
            data-name="Line 63"
            x1="9.504"
            transform="translate(684.5 239.018)"
            fill="none"
            stroke="#144e5d"
            strokeWidth="1"
          />
          <line
            id="Line_64"
            data-name="Line 64"
            x2="9.354"
            transform="translate(684.5 327.306)"
            fill="none"
            stroke="#144e5d"
            strokeWidth="1"
          />
        </g>
      </svg>
    </div>
  );
};

export default Branching;

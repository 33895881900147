import React from 'react';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// CSS
import '../../../css/boardCash.css';

// Images
import bigOrangeArrow from "../../../assets/Images/big-orange-arrow.png";
import cashIcon from "../../../assets/Images/cash-icon.svg";
import receivableIcon from "../../../assets/Images/receivable-icon.svg";


class Cash extends React.Component {

	render() {
		const dir = this.props.view.selectedLanguage == 8 ? "rtl" : "ltr"
		return (
			<div className="orangeBlock">
				<div className="blockArrow">
					<Image src={ bigOrangeArrow } />
				</div>
				<div className="atp-grid">
					<div className="atp-row" style={{ marginTop: "4px" }}>
						<div className="atp-column" style={{ flex: 1, display: "flex", height: "28px", maxWidth: "130.5px" }}>
							<div className="dottedBorder"></div>
						</div>
						<div className="atp-column" style={{ width: "50px", display: "flex" }}>
							<div className={ `droppableField` }></div>
						</div>
						<div className="atp-column" style={{ flex: 1 }}>
							<div className="atp-row">
								<div className="atp-column" style={{ display: "flex", height: "28px", paddingRight: "10px" }}>
									<Image src={ cashIcon } className="cashIcon" style={{ marginRight: "10px" }} />
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle">
										<Trans>Board__Cash</Trans>
									</p>
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ width: "50px", display: "flex" }}>
							<div className={ `droppableField` } style={{ position: 'relative' }}>
								<div className="cashflowPointer" onClick={ () => { this.props.onSelectQuestion(10); this.props.onToggleModal() }}>
									{
									(this.props.view.showCashflowExerciseResult) ?
										<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["11"] }` }>11</div>
										:
										<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["11"]) ? 'finished': '' }` }>11</div>
									}
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ flex: 1, maxWidth: "180.5px" }}>
							<div className="atp-row">
								<div className="atp-column" style={{ display: "flex", height: "28px", paddingRight: "10px" }}>
									<Image src={ receivableIcon } style={{ marginRight: "10px" }} />
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle"><Trans>Board__Accounts Receivable</Trans></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		view: state.view,
		session: state.session
	}
}

export default connect(mapStateToProps)(Cash);
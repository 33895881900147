import React from 'react';
import { Container, Grid, Image } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

// Dispatcher
import mapDispatchToProps from '../../reducers/dispatcher';
//import RealtimeConnection from '../../utils/RealtimeConnection';


// Images
import leftArrow from '../../assets/Images/left-midnight-arrow.svg';
import rightArrow from '../../assets/Images/right-midnight-arrow.svg';
import downArrow from '../../assets/Images/down-arrow.svg';
import upArrow from '../../assets/Images/up-arrow.svg';
import checkedChecklist from '../../assets/Images/checklist_check.svg';
import uncheckedChecklist from '../../assets/Images/checklist_uncheck.svg';

class ChecklistHeader extends React.Component {

	onChecklistPress = () => {
		if (this.props.session.year.title !== "Year 1 Improved") {
			this.props.updateChecklistStatus(!this.props.view.checkliststatus);
		}
	}

	onCompleteChecklist = () => {
		if (this.props.session.period === this.props.session.selectedPeriod) {
			//RealtimeConnection.updateCompletedChecklist(this.props.session.currentChecklistIndex)

			/*let updateRedux = true;
			if (this.props.session.checklist.length === (this.props.session.currentChecklistIndex + 1)) {
				let period = this.props.session.period
				if (period < 4) {
					RealtimeConnection.updatePeriodNumber((period + 1))
					updateRedux = false
				}
			}

			if(updateRedux) {
				//this.props.updateCurrentChecklist();
				// Update Socket
				RealtimeConnection.updateCurrentChecklist()
			}*/
		}
	}

	onNextChecklist = () => {
		if (this.props.session.period === this.props.session.selectedPeriod) {
			let updateRedux = true;
			if (this.props.session.checklist.length === (this.props.session.currentChecklistIndex + 1)) {
				let period = this.props.session.period
				if (period < 4) {
					//RealtimeConnection.updatePeriodNumber((period + 1))
					updateRedux = false
				}
			}

			if(updateRedux) {
				//this.props.updateCurrentChecklist();
				// Update Socket
				//RealtimeConnection.updateCurrentChecklist(this.props.session.currentChecklistIndex)
			}
		}
	}

	onUncheckChecklist = (checklistNumber) => {
		if (this.props.session.period === this.props.session.selectedPeriod) {
			//RealtimeConnection.undoSessionChecklist(checklistNumber)
		}
	}

	onIncreasePeriod = () => {
		if (this.props.session.selectedPeriod < 4) {
			this.props.updateSelectedPeriod( (this.props.session.selectedPeriod + 1) );
			this.props.fetchChecklist((this.props.session.selectedPeriod + 1))
		}
	}
	onDecreasePeriod = () => {
		if (this.props.session.selectedPeriod > 1) {
			this.props.updateSelectedPeriod( (this.props.session.selectedPeriod - 1) );
			this.props.fetchChecklist((this.props.session.selectedPeriod - 1))
		}
	}

	render() {
		let currentChecklist = undefined
		if (this.props.session.period === this.props.session.selectedPeriod) { 
			if (this.props.session.checklist.length > this.props.session.currentChecklistIndex) {
				currentChecklist = this.props.session.checklist[this.props.session.currentChecklistIndex];
			}
		}else{
			if (this.props.session.checklist.length > 0) {
				currentChecklist = this.props.session.checklist[0];
			}
		}

		const dir = this.props.view.selectedLanguage == 8 ? "rtl" : "ltr"

		return (
			<Container dir={dir} className="checklist-header-style">
				<div className="checklist-header-Container">
					<div className="session-container" style={{ position: "relative" }}>
						<Grid>
							<Grid.Row>
								<Grid.Column width={16}>
									<p className="year"><Trans>Board__Year</Trans> { this.props.session.year.value }</p>
								</Grid.Column>
							</Grid.Row>
							{
								(this.props.session.year.title === "Year 1 Improved") ?
									<Grid.Row>
										<Grid.Column width={16}>
											<p><Trans>Board__Improvements</Trans></p>
										</Grid.Column>
									</Grid.Row>
									:
									<Grid.Row>
										<Grid.Column width={3} onClick={ this.onDecreasePeriod.bind(this) }>
											<Image style={dir ==="rtl" ? {transform: "rotate(180deg)"} : {}} src={ leftArrow } className="cursor" />
										</Grid.Column>
										<Grid.Column width={10}>
											{
												(this.props.session.selectedPeriod === 4) ?
													<p><Trans>Board__End of year</Trans></p>
													:
													<p><Trans>Board__Period</Trans> { this.props.session.selectedPeriod }</p>
											}
										</Grid.Column>
										<Grid.Column width={3} onClick={ this.onIncreasePeriod.bind(this) }>
											<Image src={ rightArrow } style={dir ==="rtl" ? {transform: "rotate(180deg)"} : {}} className="cursor" />
										</Grid.Column>
									</Grid.Row>
							}
						</Grid>
						{ 
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "boardYear" &&
								<div className="guidedTourPopup">
									<div className="upArrow"></div>
									<h4>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h4>
									<p style={{ fontFamily: "'NotoSans' !important" }}>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>
									<div className="actions">
										{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
										<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
									</div>
								</div>	
							)
						}
					</div>
					<div className="separator" onClick={ this.onChecklistPress.bind(this) }>
						{
							(this.props.view.checkliststatus) ? 
								<Image src={ upArrow } />
								:
								<Image src={ downArrow } />
						}
					</div>
					<div className="current-checklist-container" style={{ position: "relative" }}>
						{
							(this.props.session.year.title === "Year 1 Improved") ?
								<Grid style={{ position: "relative" }}>
									<Grid.Row>
										<Grid.Column width={ 1 } className={"checklistIconContainer"}>
											<Image src={ checkedChecklist } className="checklistStatusImg" onClick={ this.onCompleteChecklist.bind(this) } />
										</Grid.Column>
										<Grid.Column width={ 15 } className={"checklistTitleContainer"}>
											<Grid.Row>
												<Grid.Column>
													<div className="checklistTitle"><Trans>Board__Follow Facilitator instructions</Trans></div>
												</Grid.Column>
											</Grid.Row>
											<Grid.Row>
												<Grid.Column className={dir === "ltr" ? "checklistDesc" : "checklistDescAr"}>
												<p>(<Trans>Board__no checklist</Trans>)</p>
												</Grid.Column>
											</Grid.Row>
										</Grid.Column>
									</Grid.Row>
								</Grid>
								:
								<>
								{
									(currentChecklist) ? 
										<Grid style={{ position: "relative" }}>
											<Grid.Row>
												<Grid.Column width={ 1 } className={"checklistIconContainer"}>
													{
														(this.props.session.period === this.props.session.selectedPeriod) ?
															<>
															{
															(	this.props.session.completedChecklistIndex === this.props.session.currentChecklistIndex) ?
																	<Grid>
																		<Grid.Row>
																			<Grid.Column>
																				<Image src={ checkedChecklist } className="checklistStatusImg" onClick={ this.onCompleteChecklist.bind(this) } />
																			</Grid.Column>
																		</Grid.Row>
																		<Grid.Row>
																			<Grid.Column>
																				<div className="checklistNextBtn" onClick={ this.onNextChecklist.bind() }><Trans>Forms__Next</Trans></div>
																			</Grid.Column>
																		</Grid.Row>
																	</Grid>
																	:
																	<Image src={ uncheckedChecklist } className="checklistStatusImg" onClick={ this.onCompleteChecklist.bind(this) } />
															}
															</>
															:
															null
													}
													{
														(this.props.session.period < this.props.session.selectedPeriod) ?
															<Image src={ uncheckedChecklist } className="checklistStatusImg" onClick={ this.onCompleteChecklist.bind(this) } />
															:
															null
													}
													{
														(this.props.session.period > this.props.session.selectedPeriod) ?
															<Image src={ checkedChecklist } className="checklistStatusImg" onClick={ this.onCompleteChecklist.bind(this) } />
															:
															null
													}
												</Grid.Column>
												<Grid.Column width={ 15 } className={"checklistTitleContainer"}>
													<Grid.Row>
														<Grid.Column>
															<div className="checklistTitle">{ currentChecklist.title }</div>
														</Grid.Column>
													</Grid.Row>
													<Grid.Row>
														<Grid.Column className={dir === "ltr" ? "checklistDesc" : "checklistDescAr"}>
															<p>{ currentChecklist.description }</p>
														</Grid.Column>
													</Grid.Row>
												</Grid.Column>
											</Grid.Row>
										</Grid>
										:
										null
								}
								</>
						}
						{
							(this.props.view.checkliststatus) ?
								<div className="checklist-popup">
									{
										this.props.session.checklist.map( (checklist, index) => {
											return (
												<Grid key={ index } className="checklist-list-row">
													<Grid.Row>
														<Grid.Column width={ 1 }>
															{
																(this.props.session.period === this.props.session.selectedPeriod) ?
																	<>
																	{
																		(this.props.session.completedChecklistIndex >= index) ?
																			<Image src={ checkedChecklist } className="checklistStatusImg" onClick={ this.onUncheckChecklist.bind(this, index)} />
																			:
																			<Image src={ uncheckedChecklist } className="checklistStatusImg" />
																	}
																	</>
																	:
																	null
															}
															{
																(this.props.session.period < this.props.session.selectedPeriod) ?
																	<Image src={ uncheckedChecklist } className="checklistStatusImg" />
																	:
																	null
															}
															{
																(this.props.session.period > this.props.session.selectedPeriod) ?
																	<Image src={ checkedChecklist } className="checklistStatusImg" />
																	:
																	null
															}
														</Grid.Column>
														<Grid.Column width={ 15 }>
															<div className="checklistTitle">{ checklist.title }</div>
														</Grid.Column>
													</Grid.Row>
													<Grid.Row>
														<Grid.Column width={ 1 }>
															&nbsp;
														</Grid.Column>
														<Grid.Column width={ 15 } className={dir === "ltr" ? "checklistDesc" : "checklistDescAr"}>
															<p>{ checklist.description }</p>
														</Grid.Column>
													</Grid.Row>
												</Grid>
											)
										})
									}
								</div>
							:
								null
						}
						{ 
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "checklist" &&
								<div className="guidedTourPopup">
									<div className="upArrow"></div>
									<h4>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h4>
									<p>
										{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }
									</p>
									<div className="actions">
										{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
										<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
									</div>
								</div>	
							)
						}
					</div>
				</div>
			</Container>
		);
	}
}
/*
<div className="counter-value green-color">
	<p>15</p>
	<p>min</p>
</div>
*/

const mapStateToProps = (state) => {
	return {
		view: state.view,
		session: state.session,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistHeader);
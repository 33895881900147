import React from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// CSS
import '../../../css/boardLenders.css';

class CostOfSales extends React.Component {

	render() {
		return (
			<div className="costOfSalesBlock">
				<Grid.Row>
					<Grid.Column style={{ display: "flex", flexDirection: "row-reverse" }}>
						<Popup position='top center' content={ <Trans>Glossary__Cost of goods sold</Trans> } size='mini' trigger={<Icon name='plus circle' style={{ marginRight: "0px" }} />} />
					</Grid.Column>
				</Grid.Row>
				<p style={{ marginBotton: 12 }}><Trans>Board__Cost of goods sold</Trans></p>
				<Grid columns={2}>
					<Grid.Column className="goodsSoldColumn">
						<div className={ `droppableField` }></div>
					</Grid.Column>
					<Grid.Column className="goodsSoldColumn">
						<div className={ `droppableField` }></div>
					</Grid.Column>
					<Grid.Column className="goodsSoldColumn">
						<div className={ `droppableField` }></div>
					</Grid.Column>
					<Grid.Column className="goodsSoldColumn">
						<div className={ `droppableField` }></div>
					</Grid.Column>
					<Grid.Column className="goodsSoldColumn">
						<div className={ `droppableField` }></div>
					</Grid.Column>
					<Grid.Column className="goodsSoldColumn">
						<div className={ `droppableField` }></div>
					</Grid.Column>
					<Grid.Column className="goodsSoldColumn">
						<div className={ `droppableField` }></div>
					</Grid.Column>
					<Grid.Column className="goodsSoldColumn">
						<div className={ `droppableField` }></div>
					</Grid.Column>
					<Grid.Column className="goodsSoldColumn">
						<div className={ `droppableField` }></div>
					</Grid.Column>
					<Grid.Column className="goodsSoldColumn">
						<div className={ `droppableField` }></div>
					</Grid.Column>
				</Grid>
			</div>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		board: state.board
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CostOfSales);
const state = {
	sales: "60",
	costOfGoodsSold: "24",
	contribution: "36",
	overhead: "18",
	development: "5",
	depreciation: "4",
	totalCommonCosts: "27",
	ebit: "9",
	interestExpense: "6",
	profitBeforeTaxes: "3",
	taxes: "1",
	netProfit: "2"
}

export default state;

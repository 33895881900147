const state = {
	cash: "9",
	accountRecievable: "20",
	materials: "8",
	workInProgress: "14",
	finishedGoods: "20",
	totalCurrentAssets: "71",
	plantAndEquipment: "16",
	realProperty: "12",
	totalFixedAssets: "28",
	assetsTotal: "99",
	shortTermLiabilities: "2",
	longTermLiabilities: "60",
	totalLiabilities: "62",
	beginningBalance: "33",
	netProfit: "4",
	totalEquity: "37",
	liabilityTotal: "99"
}

export default state;

const state = {
	sales: "82",
	costOfGoodsSold: "36",
	contribution: "46",
	overhead: "18",
	development: "9",
	depreciation: "5",
	totalCommonCosts: "32",
	ebit: "14",
	interestExpense: "2",
	profitBeforeTaxes: "12",
	taxes: "4",
	netProfit: "8"
}

export default state;

import React, { useState } from "react";
import {useDrag, useDrop} from 'react-dnd';

// Components
import DraggableCoin from "./DraggableCoin";
import PopupCoins from "./PopupCoins";

export default function DraggableContainer(props) {
	const [isShowingPopup, setIsShowingPopup] = useState(false);
	const [isUpdateSocket, setIsUpdateSocket] = useState(false);
	const [isUpdatePopup, setIsUpdatePopup] = useState(false);

	const [{ isDragging }, drag] = useDrag({
		item: { 
			type: "Container",
			coins: props.coins,
			fieldId: props.fieldId,
			block: props.block,
			alias: props.alias,
			id: props.id
		},
		collect: monitor => ({
			isDragging: !!monitor.isDragging()
		}),
		end: (dropResult, monitor) => {
			const doppableItem = monitor.getDropResult();
			if (doppableItem !== null) {
				if (doppableItem.type !== undefined && monitor.didDrop()) {
					props.removeContainer(props.alias, props.id, props.coins);
				}
			}

			props.canDropAction(true)

			setIsUpdateSocket(false)
			setIsUpdatePopup(false)

			// Update Dragging Status
			let dragObjData = {}
			dragObjData.alias = props.alias
			dragObjData.id = props.id
			dragObjData.coins = props.coins
			if (props.isDraggingHandler){
				if (props.draggingField === "CONTAINER") {
					props.isDraggingHandler("CONTAINER", false, dragObjData);
				}else{
					props.isDraggingHandler("POPUP_CONTAINER", false, dragObjData);
				}
			}
		}
	});

	const [, drop] = useDrop({
		accept: "Coin",
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			if (props.coins < props.maxCoins) {
				props.addCoin(props.alias, props.id, item.value);
				return item
			}
		},
	})

	let coinsObj = [];
	for (var i = 0; i < props.coins; i++) {
		coinsObj.push(
			<DraggableCoin 
				key={ i }
				alias={ props.alias }
				id={ props.id }
				value={ 1 }
				drawCoins={ props.drawCoins }
				addCoin={ props.addCoin }
				removeCoin={ props.removeCoin }
			/>
		)
	}

	let canPlayerDrag = drag;
	let canPlayerDrop = drop;

	let dragClass = "";
	

	return (
		<div style={{ width: "100%", height: "100%", position: "relative" }}>
			{
				((props.increaseCoin !== undefined && isShowingPopup)
				||
				(props.isDragging && props.draggingField === "POPUP_CONTAINER")) ?
					<PopupCoins 
						id={ props.id }
						block={ props.block }
						alias={ props.alias }
						coins={ props.modifiedCoins }
						revertModifiedContainer={ () => props.revertModifiedContainer(props.alias, props.id) }
						closePopup={ () =>  { props.onHoverLeave(); setIsShowingPopup(false); setIsUpdateSocket(false); setIsUpdatePopup(false); } }
						removeModifiedContainer={ props.removeModifiedContainer }
						increaseCoin={ props.increaseCoin }
						decreaseCoin={ props.decreaseCoin }
						isDraggingHandler={ props.isDraggingHandler }
						canPlayerDrag={ (canPlayerDrag) ? true : false }
						canDropAction={ props.canDropAction }
					/>
					:
					null
			}
			<div className={ `amountContainer ${ props.alias } ${ dragClass }` } ref={canPlayerDrag}>
				<div className="coinsContainer" ref={canPlayerDrop} onClick={ () => (props.coins > 0 && canPlayerDrag) ? setIsShowingPopup(!isShowingPopup) : "" }>
					{
						(props.drawCoins) ?
							<>
							 { coinsObj }
							</>
							:
							<DraggableCoin 
								block={ props.block }
								alias={ props.alias }
								id={ props.id }
								value={ props.coins }
								drawCoins={ props.drawCoins }
								addCoin={ props.addCoin }
								removeCoin={ props.removeCoin }
							/>
					}
				</div>
				<div className="coinValueOverlay" onClick={ () => (props.coins > 0) ? setIsShowingPopup(!isShowingPopup) : "" }>{ props.coins }</div>
			</div>
		</div>
	);
}
import React from 'react'
import { connect } from 'react-redux'
import { Grid, Checkbox, Image } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Dispatcher
import mapDispatchToProps from '../../reducers/dispatcher';
import RealtimeConnection from '../../utils/RealtimeConnection';

// Images
import DisabledPlayerIcon from '../../assets/Images/disabled-player-icon.svg';
import EnabledPlayerIcon from '../../assets/Images/enabled-player-icon.svg';
import LockIcon from '../../assets/Images/form-lock-icon.svg';
import UnLockIcon from '../../assets/Images/form-unlock-icon.svg';

class ProfitLossStatement extends React.Component {

	onFieldChange = (field, value) => {
		if (this.props.session.selectedFormYear.title === this.props.session.year.title) {
			this.props.updateForm("ProfitLossStatement", field, value)

			//if(this.props.session.isFormValidated.ProfitLossStatement) {
				RealtimeConnection.updateForm(
					this.props.session.year.title,
					"ProfitLossStatement",
					this.props.profitLoss,
					this.props.session.isFormSubmitted.ProfitLossStatement,
					this.props.session.isFormValidated.ProfitLossStatement
				);
			//}
		}
	}
	
	onCheckboxChange = (event) => {
		if (this.props.session.selectedFormYear.title === this.props.session.year.title) {
			RealtimeConnection.updateForm(
				this.props.session.year.title,
				"ProfitLossStatement",
				this.props.profitLoss,
				this.props.session.isFormSubmitted.ProfitLossStatement,
				!this.props.session.isFormValidated.ProfitLossStatement
			);

			this.props.updateValidatingStatus("ProfitLossStatement", !this.props.session.isFormValidated.ProfitLossStatement);
		}
	}

	onSubmitFormClicked = () => {
		if (this.props.session.selectedFormYear.title === this.props.session.year.title) {
			RealtimeConnection.updateForm(
				this.props.session.year.title,
				"ProfitLossStatement",
				this.props.profitLoss,
				!this.props.session.isFormSubmitted.ProfitLossStatement,
				this.props.session.isFormValidated.ProfitLossStatement
			);
	
			this.props.updateSubmittingStatus("ProfitLossStatement", !this.props.session.isFormSubmitted.ProfitLossStatement);
		}
	}

	render() {
		const staticFormValue = this.props.session.forms.ProfitLossStatement;
		let isFormLocked = this.props.session.isFormSubmitted.ProfitLossStatement;

		if (this.props.session.selectedFormYear.title !== this.props.session.year.title) {
			isFormLocked = true
		}

		let validateForm = {
			sales: {
				isValid: true,
				value: this.props.profitLoss.sales
			},
			costOfGoodsSold: {
				isValid: true,
				value: this.props.profitLoss.costOfGoodsSold
			},
			contribution: {
				isValid: true,
				value: this.props.profitLoss.contribution
			},
			overhead: {
				isValid: true,
				value: this.props.profitLoss.overhead
			},
			development: {
				isValid: true,
				value: this.props.profitLoss.development
			},
			depreciation: {
				isValid: true,
				value: this.props.profitLoss.depreciation
			},
			totalCommonCosts: {
				isValid: true,
				value: this.props.profitLoss.totalCommonCosts
			},
			ebit: {
				isValid: true,
				value: this.props.profitLoss.ebit
			},
			interestExpense: {
				isValid: true,
				value: this.props.profitLoss.interestExpense
			},
			profitBeforeTaxes: {
				isValid: true,
				value: this.props.profitLoss.profitBeforeTaxes
			},
			taxes: {
				isValid: true,
				value: this.props.profitLoss.taxes
			},
			netProfit: {
				isValid: true,
				value: this.props.profitLoss.netProfit
			}
		}

		let playersFormLength = []
		const playerIds = Object.keys(this.props.session.playersForm);
		playerIds.forEach( id => {
			if (this.props.session.playersForm[id].ProfitLossStatement) {
				playersFormLength.push(id)
			}
		})

		let validateText = <Trans>Forms__Team alignment</Trans>;
		if (this.props.session.isFormValidated.ProfitLossStatement && this.props.session.team.SessionPlayers) {
			let numberOfErrors = 0
			const playersForm = this.props.session.playersForm;
			for (const playerId in playersForm) {
				if (playersForm[playerId].ProfitLossStatement && playersForm[playerId].ProfitLossStatement.isFormValidated) {
					for (const field in playersForm[playerId].ProfitLossStatement.form) {
						if (validateForm[field].value !== "" && playersForm[playerId].ProfitLossStatement.form[field] !== "") {
							if (parseFloat(validateForm[field].value) !== parseFloat(playersForm[playerId].ProfitLossStatement.form[field])) {
								validateForm[field].isValid = false;
								numberOfErrors++;
							}
						}
					}
				}
			}

			if (this.props.session.team.SessionPlayers.length === (playersFormLength.length + 1)) {
				if (numberOfErrors === 0) {
					validateText = <Trans>Forms__Team aligned</Trans>
				}else{
					validateText = <Trans>Forms__Team not aligned</Trans>
				}
			}else{
				validateText = <Trans>Forms__Aligning...</Trans>
			}
		}

		const dir = this.props.selectedLanguage == 8 ? "rtl" : "ltr"

		return (
			<div dir={dir}>
				<div className="financial-actions">
					<Grid>
						<Grid.Column width={ 9 }>&nbsp;</Grid.Column>
						<Grid.Column width={ 1 }>&nbsp;</Grid.Column>
						<Grid.Column width={ 3 } className="columnBG">
							{
								(
									(this.props.session.selectedFormYear.title === "Year 1" && <p><Trans>Board__Year</Trans> 0</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 1 Improved" && <p><Trans>Forms__Before</Trans></p>)
									||
									(this.props.session.selectedFormYear.title === "Year 2" && <p><Trans>Board__Year</Trans> 1</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 3" && <p><Trans>Board__Year</Trans> 2</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 4" && <p><Trans>Board__Year</Trans> 3</p>)
								)
							}
						</Grid.Column>
						<Grid.Column width={ 3 }>
							{
								(
									(this.props.session.selectedFormYear.title === "Year 1" && <p><Trans>Board__Year</Trans> 1</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 1 Improved" && <p><Trans>Forms__After</Trans></p>)
									||
									(this.props.session.selectedFormYear.title === "Year 2" && <p><Trans>Board__Year</Trans> 2</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 3" && <p><Trans>Board__Year</Trans> 3</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 4" && <p><Trans>Board__Year</Trans> 4</p>)
								)
							}
						</Grid.Column>
					</Grid>
				</div>
				<div className="financial-values">
					<Grid columns={4}>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="borderTop form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__Sales</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">+</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="borderTop"></Grid.Column>
									<Grid.Column className="bottomLine borderTop form-display-flex">
										<p className="big bold center">{ staticFormValue.sales }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="borderTop"></Grid.Column>
									<Grid.Column className="borderTop form-display-flex">
										<p className="bold center">
											<input 
												value={ this.props.profitLoss.sales }
												onChange={ (e) => this.onFieldChange("sales", e.target.value )}
												className={ `textField ${ (!validateForm.sales.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Cost of goods sold</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">–</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.costOfGoodsSold }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input 
												value={ this.props.profitLoss.costOfGoodsSold }
												onChange={ (e) => this.onFieldChange("costOfGoodsSold", e.target.value )}
												className={ `textField ${ (!validateForm.costOfGoodsSold.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__Contribution</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">=</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="bottomLine columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="big bold center">{ staticFormValue.contribution }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.profitLoss.contribution }
												onChange={ (e) => this.onFieldChange("contribution", e.target.value )}
												className={ `textField ${ (!validateForm.contribution.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="borderTop form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Overhead</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">–</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.overhead }</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.profitLoss.overhead }
												onChange={ (e) => this.onFieldChange("overhead", e.target.value )}
												className={ `textField ${ (!validateForm.overhead.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Development</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">–</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.development }</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.profitLoss.development }
												onChange={ (e) => this.onFieldChange("development", e.target.value )}
												className={ `textField ${ (!validateForm.development.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Depreciation</Trans> (25%)</p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">–</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big center">{ staticFormValue.depreciation }</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.profitLoss.depreciation }
												onChange={ (e) => this.onFieldChange("depreciation", e.target.value )}
												className={ `textField ${ (!validateForm.depreciation.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
									<Grid.Column></Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="bottomLine form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__Total Common Costs</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">=</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="bottomLine columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="big bold center">{ staticFormValue.totalCommonCosts }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.profitLoss.totalCommonCosts }
												onChange={ (e) => this.onFieldChange("totalCommonCosts", e.target.value )}
												className={ `textField ${ (!validateForm.totalCommonCosts.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__Operating profit, EBIT</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">=</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big bold center">{ staticFormValue.ebit }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.profitLoss.ebit }
												onChange={ (e) => this.onFieldChange("ebit", e.target.value )}
												className={ `textField ${ (!validateForm.ebit.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="bottomLine form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Interest Expense</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">–</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="bottomLine columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="big center">{ staticFormValue.interestExpense }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.profitLoss.interestExpense }
												onChange={ (e) => this.onFieldChange("interestExpense", e.target.value )}
												className={ `textField ${ (!validateForm.interestExpense.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__Profit before taxes</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">=</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="bottomLine form-display-flex">
										<p className="big bold center">{ staticFormValue.profitBeforeTaxes }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.profitLoss.profitBeforeTaxes }
												onChange={ (e) => this.onFieldChange("profitBeforeTaxes", e.target.value )}
												className={ `textField ${ (!validateForm.profitBeforeTaxes.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="bottomLine form-display-flex">
								<p className="big form-display-flex-v-center"><Trans>Forms__Taxes</Trans> (33%)</p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">–</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="bottomLine columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="big center">{ staticFormValue.taxes }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.profitLoss.taxes }
												onChange={ (e) => this.onFieldChange("taxes", e.target.value )}
												className={ `textField ${ (!validateForm.taxes.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="form-row-height">
							<Grid.Column width={ 9 } className="form-display-flex">
								<p className="big bold form-display-flex-v-center"><Trans>Forms__Net profit/loss</Trans></p>
							</Grid.Column>
							<Grid.Column width={ 1 } className="form-display-flex">
								<p className="bold center">=</p>
							</Grid.Column>
							<Grid.Column width={ 3 } className="columnBG">
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="big bold center">{ staticFormValue.netProfit }</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
							<Grid.Column width={ 3 }>
								<Grid columns={2} className="fullHeight">
									<Grid.Column></Grid.Column>
									<Grid.Column className="form-display-flex">
										<p className="bold center">
											<input
												value={ this.props.profitLoss.netProfit }
												onChange={ (e) => this.onFieldChange("netProfit", e.target.value )}
												className={ `textField ${ (!validateForm.netProfit.isValid) ? "error" : "" }` }
												disabled={ isFormLocked }
											/>
										</p>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
				<div className="financial-validations">
					<div className="players-list">
						{
							(this.props.session.team.SessionPlayers) ?
								<>
								{
									this.props.session.team.SessionPlayers.map( (player, index) => {
										if(player.Player.id === window.playerId && this.props.session.isFormValidated.ProfitLossStatement) {
											return <Image src={ EnabledPlayerIcon } key={ index } />
										}else{
											if (this.props.session.playersForm[player.Player.id] && this.props.session.playersForm[player.Player.id].ProfitLossStatement) {
												return <Image src={ EnabledPlayerIcon } key={ index } />
											}else{
												return <Image src={ DisabledPlayerIcon } key={ index } />
											}
										}
									})
								}
								</>
								:
								null
						}
					</div>
					<div className="validateSwitch switch-container">
						<Checkbox onChange={ this.onCheckboxChange } toggle checked={ (this.props.session.isFormValidated.ProfitLossStatement) ? true : false } /> 
					</div>
					<div className={ `validationStatus ${ (this.props.session.isFormValidated.ProfitLossStatement) ? "validating" : "" }` }>
						{ validateText }
					</div>
					<div className="flex-space"></div>
					<div className="submit-form" onClick={ this.onSubmitFormClicked.bind(this) }>
						{
							(this.props.session.isFormSubmitted.ProfitLossStatement) ?
								<Image src={ LockIcon } />
								:
								<Image src={ UnLockIcon } />
						}
					</div>
				</div>
			</div>
		)
	}
}

//

const mapStateToProps = (state) => {
	return {
		session: state.session,
		profitLoss: state.form.ProfitLossStatement,
		selectedLanguage: state.view.selectedLanguage
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfitLossStatement);
import React from "react"

// Add Container
const addContainer = (block, options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		block: block,
		alias: options.alias,
		id: options.id,
		coins: options.coins
	}

	window.socket.emit('add-container', dataObj);
}

// Remove Container
const removeContainer = (block, options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		block: block,
		alias: options.alias,
		id: options.id,
		modifiedCoins: options.modifiedCoins
	}

	window.socket.emit('remove-container', dataObj);
}

// Remove Modified Container
const removeModifiedContainer = (block, options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		block: block,
		alias: options.alias,
		id: options.id,
		coins: options.coins
	}

	window.socket.emit('remove-modified-container', dataObj);
}

// Revert Modified Container
const revertModifiedContainer = (block, options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		block: block,
		alias: options.alias,
		id: options.id,
		modifiedCoins: options.modifiedCoins
	}

	window.socket.emit('revert-modified-container', dataObj);
}

// Add Coin
const addCoin = (block, options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		block: block,
		alias: options.alias,
		id: options.id,
		value: options.value
	}

	window.socket.emit('add-coin', dataObj);
}

// Remove Coin
const removeCoin = (block, options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		block: block,
		alias: options.alias,
		id: options.id,
		value: 1
	}

	window.socket.emit('remove-coin', dataObj);
}

// Add Custom Coin
const increaseCustomCoin = (block, options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		block: block,
		alias: options.alias,
		id: options.id,
		value: options.value
	}

	window.socket.emit('add-custom-coin', dataObj);
}

// Remove Custom Coin
const decreaseCustomCoin = (block, options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		block: block,
		alias: options.alias,
		id: options.id,
		value: options.value
	}

	window.socket.emit('remove-custom-coin', dataObj);
}

const updateCompletedChecklist = (checkListIndex) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		checkListIndex: checkListIndex
	}

	window.socket.emit('update-completed-checklist', dataObj);
}

const updateCurrentChecklist = (checkListIndex) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		checkListIndex: checkListIndex
	}

	window.socket.emit('update-current-checklist', dataObj);
}

const undoSessionChecklist = (checklistIndex) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		checkListIndex: checklistIndex
	}

	window.socket.emit('undo-session-checklist', dataObj);
}

const updateForm = (year, type, form, isSubmitted, isValidated) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		year: year,
		type: type,
		form: form,
		isFormSubmitted: isSubmitted,
		isFormValidated: isValidated
	}

	window.socket.emit('update-form', dataObj);
}

const updatePeriodNumber = (period) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		period: period
	}

	window.socket.emit('update-period-number', dataObj);
}

const updateStrategyView = (view) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		view: view
	}

	window.socket.emit('update-strategy-view', dataObj);
}

const updateSelectedStrategy = (strategy) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		strategy: strategy
	}

	window.socket.emit('update-selected-strategy', dataObj);
}

const containerDragging = (section, objectType, isDragging, data) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		section: section,
		objectType: objectType,
		isDragging: isDragging
	}
	dataObj = { ...data, ...dataObj }

	window.socket.emit('drag-container', dataObj);
}

const updateSelectedProject = (projectId, isSelect) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		project: projectId,
		isSelect: isSelect
	}

	window.socket.emit('update-selected-project', dataObj);
}

const updateProjectsView = (view, status) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		view: view,
		status: status
	}

	window.socket.emit('update-projects-view', dataObj);
}

const submitSelectedProjects = (projects) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		projects: projects
	}

	window.socket.emit('update-selected-projects', dataObj);
}

const requestHelp = (status, team) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		univers: team.univers,
		teamName: team.name,
		status: status
	}

	window.socket.emit('request-help', dataObj);
}

const updateFormYear = (yearTitle) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		yearTitle: yearTitle
	}

	window.socket.emit('fetch-form-form-year', dataObj)
}

const selectCustomerCard = (card) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		card: card
	}

	window.socket.emit('select-customer-card', dataObj);
}

const customerCardSelectConfirmed = (cards, sessionPeriod, remainingUnits) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		cards: cards,
		sessionPeriod: sessionPeriod,
		remainingUnits: remainingUnits
	}

	window.socket.emit('confirm-selected-customer-card', dataObj);
}

const changeConfirmationCardsPopupStatus = (status) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		status: status
	}

	window.socket.emit('change-cards-confirmation-popup-status', dataObj);
}


const cancelConfirmationCardsPopupStatus = () => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges"
	}

	window.socket.emit('cancel-selected-customer-card', dataObj);
}

const closeActionPopup = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		boardType: "Apples & Oranges",
		sales: options.sales,
		accountReceivable: options.accountReceivable,
		allocatedUnits: options.allocatedUnits,
		extraUnits: options.extraUnits
	}

	window.socket.emit('close-selected-customer-card-result', dataObj);
}

export default {
	addContainer,
	removeContainer,
	removeModifiedContainer,
	revertModifiedContainer,
	addCoin,
	removeCoin,
	increaseCustomCoin,
	decreaseCustomCoin,
	updateCompletedChecklist,
	updateCurrentChecklist,
	undoSessionChecklist,
	updateForm,
	updatePeriodNumber,
	updateStrategyView,
	updateSelectedStrategy,
	containerDragging,
	updateSelectedProject,
	updateProjectsView,
	submitSelectedProjects,
	requestHelp,
	updateFormYear,
	selectCustomerCard,
	customerCardSelectConfirmed,
	changeConfirmationCardsPopupStatus,
	closeActionPopup,
	cancelConfirmationCardsPopupStatus
}
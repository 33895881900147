import React from "react";
const Arrow = (props) => {
  return (
    <div
      className={props.ltr ? "cellArrow" : "cellArrowAr"}
      style={{ transform: props.transform, marginTop: props.marginTop }}
    >
      {props.small ? (
        <svg
          id="Group_1699"
          data-name="Group 1699"
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="20.557"
          viewBox="0 0 9 20.557"
        >
          <line
            id="Line_85"
            data-name="Line 85"
            y2="20.162"
            transform="translate(4.5 0)"
            fill="none"
            stroke="#144e5d"
            strokeWidth="1"
          />
          <path
            id="Path_1557"
            data-name="Path 1557"
            d="M646.522,413.542l4.5-5.855h-9Z"
            transform="translate(-642.022 -392.984)"
            fill="#144e5d"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="62.949"
          viewBox="0 0 9 62.949"
        >
          <g
            id="Group_1700"
            data-name="Group 1700"
            transform="translate(0 42.392)"
          >
            <line
              id="Line_85"
              data-name="Line 85"
              y2="62.553"
              transform="translate(4.5 -42.392)"
              fill="none"
              stroke="#144e5d"
              strokeWidth="1"
            />
            <path
              id="Path_1557"
              data-name="Path 1557"
              d="M646.522,413.542l4.5-5.855h-9Z"
              transform="translate(-642.022 -392.984)"
              fill="#144e5d"
            />
          </g>
        </svg>
      )}
    </div>
  );
};

export default Arrow;

import React from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Image, Icon, Popup, Button } from 'semantic-ui-react'
import { Trans } from 'react-i18next';

// Utils
import RealtimeConnection from '../../utils/RealtimeConnection';

// Dispatcher
import mapDispatchToProps from '../../reducers/dispatcher';

// Components
import MainDraggableContainer from '../common/MainDraggableContainer';
import Production from './blocks/Production';
import Development from './blocks/Development';
import Investment from './blocks/Investment';
import Cash from './blocks/Cash';
import Owners from './blocks/Owners';
import Lenders from './blocks/Lenders';
import Community from './blocks/Community';
import CostOfSales from './blocks/CostOfSales';
import Depreciation from './blocks/Depreciation';
import DevelopmentBlocks from './blocks/DevelopmentBlocks';
import Customers from './blocks/Customers';

// images
import blueArrow from '../../assets/Images/blue-arrow.png';
import orangeArrow from '../../assets/Images/orange-arrow.png';
import celemiImage from '../../assets/Images/celemi-board-image.svg';

import URLs from '../../utils/urls';

class MainBoard extends React.Component {

	addContainer = (alias, id, coins) => {
		//this.props.addContainer("suppliers", alias, id, coins);

		console.log(coins)
		// Update Socket
		RealtimeConnection.addContainer("suppliers", {
			alias: alias,
			id: id,
			coins: coins
		})
	}

	removeContainer = (alias, id) => {
		//this.props.removeContainer("suppliers", alias, id);

		// Update Socket
		RealtimeConnection.removeContainer("suppliers", {
			alias: alias,
			id: id
		})
	}

	draggingHandler = (objectType, isDragging, data) => {
		// Update Socket
		RealtimeConnection.containerDragging("suppliers", objectType, isDragging, data)
	}

	render() {
		const dir = this.props.selectedLanguage == 8 ? "rtl" : "ltr"
		return (
			<div dir={dir} className="atp-grid boardGrid">
				<div className={ `atp-row boardGridRow ${ (this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sequence") ? "minHeight" : "" }`}>
					<div className="atp-column leftBlockContainer" style={{ position: "relative" }}>
						<div className={dir === "ltr" ? "deliveryArrow" : "deliveryArrowAr" }>
							<Image src={ blueArrow } />
						</div>
						<div className={dir === "ltr" ? "deliveryText" : "deliveryTextAr" }>
							<Trans>Board__Delivery</Trans>
						</div>
						<div className={dir === "ltr" ? "deliveryDotted" : "deliveryDottedAr" }></div>
						<div className={dir === "ltr" ? "productionDotted2" : "productionDotted2Ar" }></div>
						<div className={dir === "ltr" ? "productionDotted" : "productionDottedAr" }></div>
						<div className={dir === "ltr" ? "developmentDotted" : "developmentDottedAr" }></div>
						<div className={dir === "ltr" ? "depreciationDotted" : "depreciationDottedAr" }></div>
						<div className={dir === "ltr" ? "purchaseDotted" : "purchaseDottedAr" }></div>
						<div className={dir === "ltr" ? "purchaseText" : "purchaseTextAr"}><Trans>Board__Purchase</Trans></div>
						<div className={dir === "ltr" ? "purchaseArrow" : "purchaseArrowAr" }>
							<Image src={ orangeArrow } />
						</div>
						<div className="suppliesContainer">
							<div className="materialContainer">
								<MainDraggableContainer 
									id={ -1 }
									field={ this.props.board.suppliers }
									isDragging={ this.props.board.suppliers.isDragging }
									draggingField={ this.props.board.suppliers.draggingField }
									playerId={ this.props.board.suppliers.playerId }
									playerName={ this.props.board.suppliers.playerName }
									block={ "suppliers" }
									alias={ "" }
									drawCoins={ false }
									maxCoins={ 100 }
									addContainer={ this.addContainer.bind(this) }
									removeContainer={ this.removeContainer.bind(this) }
									isDraggingHandler={ this.draggingHandler }
									boardState={ this.props.board }
								/>
								<p><Trans>Board__Buy Material</Trans></p>
							</div>
							<div className={dir === "ltr" ? "suppliesText" : "suppliesText Ar"}>
								<p>
									<Trans>Board__Suppliers</Trans> 
									<Popup position='top center' content={ <Trans>Glossary__Suppliers</Trans> } size='mini' trigger={<Icon name='plus circle' style={{ marginTop: "10px", fontSize: 9 }} />} />
								</p>
							</div>
						</div>

						{
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sequence2" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 1) ?
								<div className="sequenceGuidedTourPopup3">
									<div className="leftArrow"></div>
									<p>
										{ this.props.guidedTour.steps[6].message }
									</p>
									<div className="actions">
										{
											(this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 1 && 
												<>
													{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
													<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
												</>
											)
										}
									</div>
								</div>
								:
								null
						}
					</div>
					<div className={ `atp-column centerBlockContainer` }>
						<Container className="centerBlock">
							<Production />
							<Development />
							<Investment />
							<div className={ `whiteBlock ${ (this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sequence") ? "minHeight" : "" }` }>
								{
									(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sequence" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 0) ?
										<div className="centerGuidedTourPopup staticWidth">
											<div className="leftArrow"></div>
											<p>
												{ this.props.guidedTour.steps[0].message }
											</p>
											<div className="actions">
												{
													(this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 0) ?
														<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
														:
														null
												}
											</div>
										</div>
										:
										null
								}
							</div>
							<Cash />
						</Container>
					</div>
					<div className="atp-column rightBlocksContainers" style={{ position: "relative" }}>
						<div className="rightBlocks">
							<div className="blocksContainer">
								<div className={dir === "ltr" ? "text" : "textAr" }>
									<Trans>Board__Delivery</Trans>
								</div>
								<div className={dir === "ltr" ? "productionArrow" : "productionArrowAr" }>
									<Image src={ blueArrow } />
								</div>
								<div className={dir === "ltr" ? "productionArrowDotted" : "productionArrowDottedAr" }></div>
								<div className={dir === "ltr" ? "productionDotted" : "productionDottedAr" }></div>
								<div className={dir === "ltr" ? "developmentDotted" : "developmentDottedAr" }></div>
								<div className={dir === "ltr" ? "depreciationDotted" : "depreciationDottedAr" }></div>
								<Grid columns={3}>
									<Grid.Column className="depreciationContainer" style={{ position: "relative" }}>
										<Depreciation />
										{
											(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sequence2" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 3) ?
												<div className="sequenceGuidedTourPopup4 bottom">
													<div className="rightArrow"></div>
													<p>
														{ this.props.guidedTour.steps[8].message }
													</p>
													<div className="actions">
														{
															(this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 3 && 
																<>
																	{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
																	<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
																</>
															)
														}
													</div>
												</div>
												:
												null
										}
									</Grid.Column>
									<Grid.Column className="developmentContainer">
										<DevelopmentBlocks />
									</Grid.Column>
									<Grid.Column className="costOfSalesContainer">
										<CostOfSales />
									</Grid.Column>
								</Grid>
							</div>
							<div className="salesDashContainer">
								<div className={dir === "ltr" ? "borderBox" : "borderBoxAr" }></div>
								<div className={dir === "ltr" ? "purchaseArrow" : "purchaseArrowAr" }>
									<Image src={ orangeArrow } />
								</div>
								<div className={dir === "ltr" ? "text" : "textAr" }>
									<Trans>Board__Sales</Trans>
									</div>
							</div>
						</div>
						<Customers />

						{
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sequence2" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 2) ?
								<div className="sequenceGuidedTourPopup4">
									<div className="rightArrow"></div>
									<p>
										{ this.props.guidedTour.steps[7].message }
									</p>
									<div className="actions">
										{
											(this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 2 && 
												<>
													{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
													<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
												</>
											)
										}
									</div>
								</div>
								:
								null
						}
					</div>
				</div>
				<div className="atp-row boardGridRow">
					<div className="atp-column leftBlockContainer"></div>
					<div className="atp-column centerBlockContainer bottomCenterBlock">
						<div className="atp-grid">
							<div className="atp-row">
								<div className="atp-column" style={{ width: "33%" }}>
									<Owners />
								</div>
								<div className="atp-column" style={{ width: "1%" }}></div>
								<div className="atp-column" style={{ width: "66%" }}>
									<div className="atp-grid">
										<div className="atp-row">
											<div className="atp-column" style={{ width: "50%" }}>
												<Lenders />
											</div>
											<div className="atp-column" style={{ width: "3px" }}></div>
											<div className="atp-column" style={{ width: "50%" }}>
												<Community />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={dir === "ltr" ? "atp-column boardCelemiLogo" : "atp-column boardCelemiLogoAr"}>
						<Image src={ celemiImage } style={{ width: "90%" }} />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board,
		selectedLanguage: state.view.selectedLanguage,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainBoard);
import initState from "./initialStates/guided-tour-init-state";

const reducer = (state = initState, action) => {
	if (action.type === "UPDATE_PREV_STEP") {
		return {
			...state,
			currentStep: (state.currentStep - 1)
		}
	}else if (action.type === "UPDATE_STEP") {
		return {
			...state,
			currentStep: (state.currentStep + 1)
		}
	}

	return state;
}

export default reducer;
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// CSS
import '../../../css/boardLenders.css';

class Lenders extends React.Component {

	render() {
		const dir = this.props.view.selectedLanguage == 8 ? "rtl" : "ltr"

		return (
			<div className="lendersBlock">
				<Grid columns={3}>
					<Grid.Row>
						<Grid.Column className="noColumnPadding">
							<div className="blockTitle"><Trans>Board__Lenders</Trans></div>
						</Grid.Column>
						<Grid.Column className="center">
							<div className={ `droppableField` }></div>
						</Grid.Column>
						<Grid.Column className="center">
							<div className={ `droppableField` } style={{ position: 'relative' }}>
								<div className="cashflowPointer" onClick={ () => { this.props.onSelectQuestion(11); this.props.onToggleModal() }}>
									{
									(this.props.view.showCashflowExerciseResult) ?
										<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["12"] }` }>12</div>
										:
										<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["12"]) ? 'finished': '' }` }>12</div>
									}
								</div>
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column className="noColumnPadding">
							<Popup position='top center' content={ <Trans>Glossary__Lenders</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
						</Grid.Column>
						<Grid.Column className="flexColumn">
							<p><Trans>Board__Interest</Trans></p>
						</Grid.Column>
						<Grid.Column className="flexColumn">
							<p><Trans>Board__Liabilities</Trans></p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		view: state.view,
		session: state.session
	}
}

export default connect(mapStateToProps)(Lenders);
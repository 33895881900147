import React from "react";
import { connect } from 'react-redux';
import { Container, Tab, Menu } from "semantic-ui-react";
import { Trans } from "react-i18next";

// CSS
import '../../css/businessReview.css';
import "../../css/indicators.css";

import Close from "./partials/close";
import Dupont from "./partials/dupont";
import Indicators from "./partials/Indicators";
import IndicatorsFirstYear from "./partials/IndicatorsFirstYear";


// Dispatcher
import mapDispatchToProps from '../../reducers/dispatcher';

class BusinessReview extends React.Component {

	onClose = () => {
		this.props.updateFinancialFormStatus("businessReview", false)
	}

	render() {
		let panes = [];
		if (this.props.session.selectedFormYear.title !== "Year 1") {
			panes.push({
				menuItem: (
					<Menu.Item key='dupont'>
						<Trans>Forms__Dupont</Trans>
					</Menu.Item>
				),
				render: () => (
					<Tab.Pane style={{ display: "flex" }}>
						<Dupont />
					</Tab.Pane>
				)
			})
			panes.push({
				menuItem: (
					<Menu.Item key='otherIndicators'>
						<Trans>Forms__Other commonly used indicators</Trans>
					</Menu.Item>
				),
				render: () => (
					<Tab.Pane>
						<Indicators />
					</Tab.Pane>
				)
			})
		}else{
			panes.push({
				menuItem: (
					<Menu.Item key='businessReview'>
						<Trans>Forms__Business Review</Trans>
					</Menu.Item>
				),
				render: () => (
					<Tab.Pane>
						<IndicatorsFirstYear />
					</Tab.Pane>
				)
			})
		}
		
		const dir = this.props.view.selectedLanguage == 8 ? "rtl" : "ltr"


		return (
			<Container className="businessReviewContiner" dir={dir}>
				<Tab panes={panes} className="businessReviewTabs" />
				<div className={`closeSign ${dir}`} onClick={ this.onClose.bind(this) }>
					<Close />
				</div>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		view: state.view,
		session: state.session
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessReview);
import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import language1 from './localization/1/translate.json'
import language3 from './localization/3/translate.json'
import language4 from './localization/4/translate.json'
import language5 from './localization/5/translate.json'
import language6 from './localization/6/translate.json'
import language7 from './localization/7/translate.json'
import language8 from './localization/8/translate.json'
import language9 from './localization/9/translate.json'
import language11 from './localization/11/translate.json'
import language12 from './localization/12/translate.json'
import language13 from './localization/13/translate.json'
import language14 from './localization/14/translate.json'
import language15 from './localization/15/translate.json'
import language16 from './localization/16/translate.json'
import language17 from './localization/17/translate.json'
import language18 from './localization/18/translate.json'
import language19 from './localization/19/translate.json'
import language20 from './localization/20/translate.json'
import language21 from './localization/21/translate.json'
import language22 from './localization/22/translate.json'
import language23 from './localization/23/translate.json'
import language24 from './localization/24/translate.json'
import language25 from './localization/25/translate.json'
import language26 from './localization/26/translate.json'
import language27 from './localization/27/translate.json'
import language28 from './localization/28/translate.json'
//ANCHOR_IMPORT_END


const resources = {
	"1": { translations: language1 },
	"3": { translations: language3 },
	"4": { translations: language4 },
	"5": { translations: language5 },
	"6": { translations: language6 },
	"7": { translations: language7 },
	"8": { translations: language8 },
	"9": { translations: language9 },
	"11": { translations: language11 },
	"12": { translations: language12 },
	"13": { translations: language13 },
	"14": { translations: language14 },
	"15": { translations: language15 },
	"16": { translations: language16 },
	"17": { translations: language17 },
	"18": { translations: language18 },
	"19": { translations: language19 },
	"20": { translations: language20 },
	"21": { translations: language21 },
	"22": { translations: language22 },
	"23": { translations: language23 },
	"24": { translations: language24 },
	"25": { translations: language25 },
	"26": { translations: language26 },
	"27": { translations: language27 },
	"28": { translations: language28 },
	//ANCHOR_RESOURCE_END
}

i18n
.use(LanguageDetector)
.use(XHR)
.use(initReactI18next)
.init({
	resources: resources,
	/* default language when load the website in browser */
	//lng: "de",
	/* When react i18next not finding any language to as default in borwser */
	fallbackLng: "1",
	/* debugger For Development environment */
	debug: true,
	ns: ["translations"],
	defaultNS: "translations",
	keySeparator: "__",
	nsSeparator: false,
	interpolation: {
		escapeValue: false,
		formatSeparator: ","
	},
	react: {
		wait: true,
		bindI18n: 'languageChanged loaded',
		bindStore: 'added removed',
		nsMode: 'default'
	}
})

export default i18n;
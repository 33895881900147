import { combineReducers } from 'redux'

import viewReducer from './view-reducer'
import boardReducer from './board-reducer'
import sessionReducer from "./session-reducer";
import formReducer from "./form-reducer";
import chatReducer from "./chat-reducer";
import guidedTourReducer from "./guided-tour-reducer";

export default combineReducers({
	view: viewReducer,
	board: boardReducer,
	session: sessionReducer,
	form: formReducer,
	chat: chatReducer,
	guidedTour: guidedTourReducer
})
const state = {
	sales: "63",
	costOfGoodsSold: "24",
	contribution: "39",
	overhead: "19",
	development: "3",
	depreciation: "5",
	totalCommonCosts: "27",
	ebit: "12",
	interestExpense: "6",
	profitBeforeTaxes: "6",
	taxes: "2",
	netProfit: "4"
}

export default state;

const initState = {
	selectedLanguage: 1,
	guidedTourIntro: true,
	guidedTourEnd: false,
	financialStatementStatus: false,
	smallBoardStatus: false,
	openChat: false,
	checkliststatus: false,
	numberOfUnrealMessages: 0,
	financialTab: {
		profit: true,
		balanceSheet: false,
		cashflow: false,
		businessReview: false
	},
	formStatus: {
		financialStatement: false,
		cashFlow: false,
		businessReview: false,
		optionalCashflow: false,
		year3: false,
		cashflowInstantFeedback: false
	},
	showCashFlowExercise: false,
	showCashflowExerciseResult: false,
	showPresentationPopup: false,
	presentationImg: "",
	startStrategies: false,
	y2Strategies: {
		intro: false,
		tabs: {
			potential: false,
			risk: false,
			SMEstatment: false
		},
		selectedStrategy: ''
	},
	year3: {
		intro: true,
		confirmation: false,
		waitingView: false,
		resultView: false,
		projects: []
	}
};

export default initState;
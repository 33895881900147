import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid } from "semantic-ui-react";
import { Trans } from "react-i18next";

// Components
import Branching from "./branch";
import Cell from "./DupontCell";
import Arrow from "./arrow";

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';
import RealtimeConnection from '../../../utils/RealtimeConnection';

class Dupont extends Component {
	state = {};

	onFieldChange = (field, value) => {
		if (this.props.session.selectedFormYear.title === this.props.session.year.title) {
			this.props.updateFormWithKey("BusinessReview", "dupont", field, value)

			RealtimeConnection.updateForm(
				this.props.session.year.title,
				"BusinessReview",
				this.props.businessReview,
				true,
				true
			);
		}
	}

	render() {
		const staticFormValue = this.props.session.forms.BusinessReview.dupont;

		let lockForm = false
		if (this.props.session.selectedFormYear.title !== this.props.session.year.title) {
			lockForm = true
		}

		let validateForm = {
			sales: {
				isValid: true,
				value: this.props.businessReview.dupont.sales
			},
			costOfGoodsSold: {
				isValid: true,
				value: this.props.businessReview.dupont.costOfGoodsSold
			},
			contribution: {
				isValid: true,
				value: this.props.businessReview.dupont.contribution
			},
			commonCosts: {
				isValid: true,
				value: this.props.businessReview.dupont.commonCosts
			},
			operatingProfit: {
				isValid: true,
				value: this.props.businessReview.dupont.operatingProfit
			},
			interestAndTaxes: {
				isValid: true,
				value: this.props.businessReview.dupont.interestAndTaxes
			},
			netProfit: {
				isValid: true,
				value: this.props.businessReview.dupont.netProfit
			},
			returnONAssets: {
				isValid: true,
				value: this.props.businessReview.dupont.returnONAssets
			},
			returnONEquity: {
				isValid: true,
				value: this.props.businessReview.dupont.returnONEquity
			},
			currentAssets: {
				isValid: true,
				value: this.props.businessReview.dupont.currentAssets
			},
			fixedAssets: {
				isValid: true,
				value: this.props.businessReview.dupont.fixedAssets
			},
			totalAssets: {
				isValid: true,
				value: this.props.businessReview.dupont.totalAssets
			},
			equity: {
				isValid: true,
				value: this.props.businessReview.dupont.equity
			},
			liabilities: {
				isValid: true,
				value: this.props.businessReview.dupont.liabilities
			}
		}

		let playersFormLength = []
		const playerIds = Object.keys(this.props.session.playersForm);
		playerIds.forEach( id => {
			if (this.props.session.playersForm[id].BusinessReview && this.props.session.playersForm[id].BusinessReview.form.dupont) {
				playersFormLength.push(id)
			}
		})
		

		if (this.props.businessReview && this.props.businessReview.dupont && this.props.session.team.SessionPlayers) {
			const playersForm = this.props.session.playersForm;
			for (const playerId in playersForm) {
				if (playersForm[playerId].BusinessReview && playersForm[playerId].BusinessReview.form.dupont) {
					for (const field in playersForm[playerId].BusinessReview.form.dupont) {
						if (validateForm[field].value !== "" && playersForm[playerId].BusinessReview.form.dupont[field] !== "") {
							if (parseFloat(validateForm[field].value) !== parseFloat(playersForm[playerId].BusinessReview.form.dupont[field])) {
								validateForm[field].isValid = false;
							}
						}
					}
				}
			}
		}

		let returnONAssets = Math.round((parseFloat(validateForm.operatingProfit.value) / parseFloat(validateForm.totalAssets.value)) * 100)
		let returnONEquity = Math.round((parseFloat(validateForm.netProfit.value) / parseFloat(validateForm.equity.value)) * 100)

		if (isNaN(returnONAssets)) {
			returnONAssets = ""
		}
		if (isNaN(returnONEquity)) {
			returnONEquity = ""
		}

		const dir = this.props.selectedLanguage == 8 ? "rtl" : "ltr"

		return (
			<Grid columns={7}
					style={{ minHeight: "543px", margin: "auto", display: "flex"  }}> { /*padding: "52px 0px 0px 0px"*/ }
				<Grid.Row style={{ height: "470px", margin: "auto", paddingTop: "30px" }}>
					<div className="Year1b4">
						<div style={dir === "ltr" ? { marginLeft: '20px' } : {margin : "0 30px 0px 55px"}}>
							{
								(
									(this.props.session.selectedFormYear.title === "Year 1" && <p><Trans>Board__Year</Trans> 0</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 1 Improved" && <p><Trans>Forms__Before</Trans></p>)
									||
									(this.props.session.selectedFormYear.title === "Year 2" && <p><Trans>Board__Year</Trans> 1</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 3" && <p><Trans>Board__Year</Trans> 2</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 4" && <p><Trans>Board__Year</Trans> 3</p>)
								)
							}
							<span>&#x25BC;</span>
							{" "}
						</div>
						<div style={{ marginLeft: '20px' }}>
							{" "}
							{
								(
									(this.props.session.selectedFormYear.title === "Year 1" && <p><Trans>Board__Year</Trans> 1</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 1 Improved" && <p><Trans>Forms__After</Trans></p>)
									||
									(this.props.session.selectedFormYear.title === "Year 2" && <p><Trans>Board__Year</Trans> 2</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 3" && <p><Trans>Board__Year</Trans> 3</p>)
									||
									(this.props.session.selectedFormYear.title === "Year 4" && <p><Trans>Board__Year</Trans> 4</p>)
								)
							} 
							<span>&#x25BC;</span>
							{" "}
						</div>
					</div>
					<Grid.Column className="cellGroup">
						<div style={{ marginBottom: "38px" }}>
							<Cell 
								header={ <Trans>Forms__Sales</Trans> }
								defaultValue={ staticFormValue.sales } 
								value={ this.props.businessReview.dupont.sales } 
								fieldName="sales"
								className={ `${ (!validateForm.sales.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>

						<div>
							<Cell 
								header={ <Trans>Forms__Cost of goods sold</Trans> }
								defaultValue={ staticFormValue.costOfGoodsSold }
								value={ this.props.businessReview.dupont.costOfGoodsSold } 
								fieldName="costOfGoodsSold"
								className={ `${ (!validateForm.costOfGoodsSold.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>
					</Grid.Column>
					<Grid.Column className="Branching">
						<Branching style={dir === "rtl" ? {transform: "rotate(180deg)"} : {}} />
					</Grid.Column>
					<Grid.Column className="cellGroup n2dCol">
						<div style={{ marginBottom: "38px" }}>
							<Cell 
								header={ <Trans>Forms__Contribution</Trans> }
								defaultValue={ staticFormValue.contribution }
								value={ this.props.businessReview.dupont.contribution } 
								fieldName="contribution"
								className={ `${ (!validateForm.contribution.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>

						<div>
							<Cell 
								header={ <Trans>Forms__Common costs</Trans> }
								defaultValue={ staticFormValue.commonCosts }
								value={ this.props.businessReview.dupont.commonCosts } 
								fieldName="commonCosts"
								className={ `${ (!validateForm.commonCosts.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>
						<div style={{ marginBottom: "38px", marginTop: "123.4px" }}>
							<Cell 
								header={ <Trans>Forms__Current assets</Trans> }
								defaultValue={ staticFormValue.currentAssets }
								value={ this.props.businessReview.dupont.currentAssets } 
								fieldName="currentAssets"
								className={ `${ (!validateForm.currentAssets.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>

						<div>
							<Cell 
								header={ <Trans>Forms__Fixed assets</Trans> }
								defaultValue={ staticFormValue.fixedAssets }
								value={ this.props.businessReview.dupont.fixedAssets } 
								fieldName="fixedAssets"
								className={ `${ (!validateForm.fixedAssets.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>
					</Grid.Column>

					<Grid.Column className="Branching">
						<Branching style={dir === "rtl" ? {transform: "rotate(180deg)"} : {}}/>
						<Branching style={dir === "rtl" ? {transform: "rotate(180deg)"} : {}} marginTop="167px" string={ <Trans>Board__Plus</Trans> } />
					</Grid.Column>
					<Grid.Column className="cellGroup r3dCol">
						<div>
							<Cell 
								header={ <Trans>Forms__Operating profit</Trans> }
								defaultValue={ staticFormValue.operatingProfit }
								value={ this.props.businessReview.dupont.operatingProfit } 
								fieldName="operatingProfit"
								className={ `${ (!validateForm.operatingProfit.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>

						<div
							style={{
								marginTop: "78px",
								position: "relative",
							}}
						>
							<Arrow marginTop="-65px" />
							<Cell
								header={ <Trans>Forms__Return On Assets</Trans> }
								defaultValue={ staticFormValue.returnONAssets }
								backgroundColor="#D19100"
								value={ (returnONAssets !== '') ? returnONAssets + '%' : '' }  //this.props.businessReview.dupont.returnONAssets
								fieldName="returnONAssets"
								className={ `${ (!validateForm.returnONAssets.isValid) ? "error" : "" }` }
								//onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ false }
								ltr={dir === "ltr"}
							/>

							<Arrow ltr={dir === "ltr"} transform="rotate(180deg)" marginTop="15px" />
						</div>
						<div style={{ marginTop: "84.4px" }}>
							<Cell 
								header={ <Trans>Forms__Total assets</Trans> }
								defaultValue={ staticFormValue.totalAssets }
								value={ this.props.businessReview.dupont.totalAssets } 
								fieldName="totalAssets"
								className={ `${ (!validateForm.totalAssets.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>
					</Grid.Column>
					<Grid.Column
						className="Branching"
						style={{ transform: "scaleX(-1)" }}
					>
						<Branching style={dir === "rtl" ? {transform: "rotate(180deg)"} : {}} NoOperator />
						<Branching style={dir === "rtl" ? {transform: "rotate(180deg)"} : {}} marginTop="167px" NoOperator />
					</Grid.Column>
					<Grid.Column className="cellGroup t4hCol">
						<div style={{ marginBottom: "38px" }}>
							<Cell 
								header={ <Trans>Forms__Interest & Taxes</Trans> }
								defaultValue={ staticFormValue.interestAndTaxes }
								value={ this.props.businessReview.dupont.interestAndTaxes } 
								fieldName="interestAndTaxes"
								className={ `${ (!validateForm.interestAndTaxes.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>

						<div>
							<Cell 
								header={ <Trans>Forms__Net profit</Trans> }
								defaultValue={ staticFormValue.netProfit }
								value={ this.props.businessReview.dupont.netProfit } 
								fieldName="netProfit"
								className={ `${ (!validateForm.netProfit.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>
						<div
							style={{
								marginTop: "38px",
								position: "relative",
								marginBottom: "-100px",
							}}
						>
							<Arrow ltr={dir === "ltr"} marginTop="-23px" small />
							<Cell
								header={ <Trans>Forms__Return On Equity</Trans> }
								defaultValue={ staticFormValue.returnONEquity }
								backgroundColor="#D19100"
								value={ (returnONEquity !== '') ? returnONEquity + '%' : '' }
								//value={ this.props.businessReview.dupont.returnONEquity } 
								fieldName="returnONEquity"
								className={ `${ (!validateForm.returnONEquity.isValid) ? "error" : "" }` }
								//onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ false }
								ltr={dir === "ltr"}
							/>

							<Arrow ltr={dir === "ltr"} transform="rotate(180deg)" marginTop="13px" small />
						</div>
						<div style={{ marginBottom: "38px", marginTop: "123.4px" }}>
							<Cell 
								header={ <Trans>Forms__Equity</Trans> }
								defaultValue={ staticFormValue.equity }
								value={ this.props.businessReview.dupont.equity } 
								fieldName="equity"
								className={ `${ (!validateForm.equity.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>

						<div>
							<Cell 
								header={ <Trans>Forms__Liabilities</Trans> }
								defaultValue={ staticFormValue.liabilities }
								value={ this.props.businessReview.dupont.liabilities } 
								fieldName="liabilities"
								className={ `${ (!validateForm.liabilities.isValid) ? "error" : "" }` }
								onFieldChange={ this.onFieldChange.bind(this) } 
								disabled={ lockForm }
								ltr={dir === "ltr"}
							/>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		businessReview: state.form.BusinessReview,
		selectedLanguage: state.view.selectedLanguage
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dupont);

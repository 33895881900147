import { bindActionCreators } from "redux";
import { getChats, afterPostMessage } from "./chat-action";

const dispatchToProps = (dispatch) => {
	return {
		setSelectedLanguage: (id) => dispatch({ type: "CHANGE_LANGUAGE", id }),
		teamNames: (data) => dispatch({ type: "TEAMS_NAME", data }),
		requestHelp: (status) => dispatch({ type: "REQUEST_HELP", status }),
		restoreSession: (data) => dispatch({ type: "UPDATE_STATE", data: data }),
		updateYear: (year, title) => dispatch({ type: "UPDATE_YEAR", year: year, title: title }),
		updatePeriod: (period) => dispatch({ type: "UPDATE_PERIOD", period: period }),
		updateSelectedPeriod: (period) => dispatch({ type: "UPDATE_SELECTED_PERIOD", period: period }),

		formStatus: (form, status) => dispatch({ type: "FORM_STATUS", form, status }),
		restoreFormStatus: (forms) => dispatch({ type: "RESTORE_FORM_STATUS", forms }),

		updateFinancialViewStatus: (status) => dispatch({type: 'UPDATE_FINANCIAL_STATUS', status: status}),
		updateFinancialTabStatus: (tab, status) => dispatch({type: 'UPDATE_FINANCIAL_TAB', tab: tab, status: status}),
		updateFinancialFormStatus: (tab, status) => dispatch({ type: 'UPDATE_FINSNACIAL_FORM_STATUS', tab, status}),
		updateChecklistStatus: (status) => dispatch({type: 'UPDATE_CHECKLIST_STATUS', status: status}),
		updateChatViewStatus: (status) => dispatch({type: 'UPDATE_CHAT_VIEW_STATUS', status: status}),
		updatePresentationStatus: (status, image) => dispatch({ type: 'UPDATE_PRESENTATION_STATUS', status: status, image: image }),

		addContainer: (block, alias, id, coins, playerId, playerName) => dispatch({type: 'ADD_CONTAINER', block: block, alias: alias, id: id, coins: coins, playerId: playerId, playerName: playerName }),
		removeContainer: (block, alias, id) => dispatch({type: 'REMOVE_CONTAINER', block: block, alias: alias, id: id }),
		containerDragging: (data) => dispatch({ type: 'DRAGGING', data: data }),
		removeModifiedContainer: (block, alias, id) => dispatch({type: 'REMOVE_MODIFIED_CONTAINER', block: block, alias: alias, id: id }),
		revertModifiedContainer: (block, alias, id) => dispatch({type: 'REVERT_MODIFIED_CONTAINER', block: block, alias: alias, id: id }),
		addCoin: (block, alias, id, value) => dispatch({type: 'ADD_COIN', block: block, alias: alias, id: id, value: value }),
		removeCoin: (block, alias, id) => dispatch({type: 'REMOVE_COIN', block: block, alias: alias, id: id }),
		increaseCustomCoin: (block, alias, id, value) => dispatch({type: 'ADD_CUSTOM_COIN', block: block, alias: alias, id: id, value: value }),
		decreaseCustomCoin: (block, alias, id, value) => dispatch({type: 'REMOVE_CUSTOM_COIN', block: block, alias: alias, id: id, value: value }),
		removeDroppedTime: (block, alias, id) => dispatch({ type: "REMOVE_DROP_TIME", block, alias, id }),

		restoreChecklist: (data) => dispatch({ type: 'RESTORE_CHECKLIST', data: data}),
		restoreCurrentChecklist: (data) => dispatch({ type: 'RESTORE_CURRENT_CHECKLIST', data: data}),
		updateCurrentChecklist: (index) => dispatch({ type: 'UPDATE_CURRENT_CHECKLIST', index }),
		updateCompletedChecklist: (data) => dispatch({ type: 'RESTORE_COMPLETED_CHECKLIST', data }),

		updateValidatingStatus: (form, value) => dispatch({ type: 'UPDATE_VALIDATE_STATUS', form, value: value }),
		updateSubmittingStatus: (form, value) => dispatch({ type: 'UPDATE_SUBMIT_STATUS', form, value: value }),

		connectedPlayers: (data) => dispatch({ type: 'CONNECTED_PLAYER', data: data }),
		playerConnected: (data) => dispatch({ type: 'PLAYER_CONNECTED', data: data }),
		playerDisconnect: (data) => dispatch({ type: 'PLAYER_DISCONNECT', data: data }),

		removeForm: () => dispatch({type: "REMOVE_FORM" }),
		restoreForm: (type, value) => dispatch({type: "UPDATE_FORM", form: type, value: value }),
		updateForm: (type, field, value) => dispatch({type: type, field: field, value: value}),
		updateFormWithKey: (type, key, field, value) => dispatch({type: type, key: key, field: field, value: value}),
		updatePlayerForm: (data) => dispatch({ type: 'PLAYER_FORM', data }),
		removePlayerForm: (data) => dispatch({ type: 'REMOVE_PLAYER_FORM', data }),

		finishedStratsIntro: () => dispatch({type: 'FINISHED_STRATS_INTRO'}),
		showY2StratsIntro: () => dispatch({type: 'SHOW_STRATS_INTRO'}),
		updateStratsView: (tab) => dispatch({type: 'UPDATE_STRATS_VIEW', tab}),
		chooseStrategy: (payload) => dispatch({type: 'CHOOSE_STRATEGY', payload}),
		hideStrategies: () => dispatch({ type: 'HIDE_STRATEGIES' }),

		restoreStrategies: (data) => dispatch({ type: 'RESTORE_STRATEGIES', data}),
		selectedStrategy: (data) => dispatch({ type: 'SELECTED_STRATEGY', data}),

		restoreProjects: (data) => dispatch({ type: 'RESTORE_PROJECTS', data}),
		restoreDepartments: (data) => dispatch({ type: 'RESTORE_PROJECTS_DEPARTMENTS', data}),
		selectProject: (data) => dispatch({ type: "SELECT_PROJECT", data: data }),
		updateTeamProject: (playerId, project, status) => dispatch({ type: "UPDATE_TEAM_PROJECT", playerId, project, status }),
		restorePlayerProjects: (projects) => dispatch({ type: "RESTORE_PLAYER_PROJECTS", projects }),
		restoreTeamProjects: (playerId, projects) => dispatch({ type: "RESTORE_TEAM_PROJECTS", playerId, projects }),
		selectedTeamsProjects: (data) => dispatch({ type: "SELECTED_TEAMS_PROJECTS", data }),

		updateTeam: (data) => dispatch({ type: 'UPDATE_TEAM', data}),

		updateProjectsView: (view, status) => dispatch({ type: 'UPDATE_PROJECT_VIEW', view, status }),
		approveProject: (payload) => dispatch({type: 'PROJECT_APPROVED_BY_ALL', payload}),

		getChats: bindActionCreators(getChats, dispatch),
		afterPostMessage: bindActionCreators(afterPostMessage, dispatch),
		chatNotification: () => dispatch({type: 'UPDATE_CHAT_NOTIFICATION'}),
		clearChatNotification: () => dispatch({type: 'CLEAR_CHAT_NOTIFICATION'}),

		changeFormYear: (title, year) => dispatch({ type: 'CHANGE_FORM_YEAR', title, year }),

		showCashflowExercise: (status) => dispatch({ type: 'SHOW_CASHFLOW_EXERCISE', status }),
		updateCashflowAnswers: (id, cash, profit) => dispatch({ type: 'UPDATE_CASHFLOW_ANSWER', id, cash, profit }),
		showCashflowAnswer: () => dispatch({type: 'SHOW_CASH_FLOW_ANSWERS'}),

		year3ProjectsStep: (step, status) => dispatch({ type: 'CHANGE_YEAR_3_DEBRIEF', step, status }),
		changeDownloadFileStatus: (status) => dispatch({ type: 'CHANGE_DOWNLOAD_FILE_STATUS', status}),


		guidedTourView: (view, status) => dispatch({ type: "UPDATE_GUIDED_TOUR_VIEW", view, status }),

		nextGuidedTour: () => dispatch({ type: "UPDATE_STEP" }),
		prevGuidedTour: () => dispatch({ type: "UPDATE_PREV_STEP" })
	}
}

export default dispatchToProps;
const state = {
	cash: "8",
	accountRecievable: "10",
	materials: "4",
	workInProgress: "8",
	finishedGoods: "12",
	totalCurrentAssets: "42",
	plantAndEquipment: "12",
	realProperty: "12",
	totalFixedAssets: "24",
	assetsTotal: "66",
	shortTermLiabilities: "3",
	longTermLiabilities: "20",
	totalLiabilities: "23",
	beginningBalance: "37",
	netProfit: "6",
	totalEquity: "43",
	liabilityTotal: "66"
}

export default state;

const state = {
	cash: "14",
	accountRecievable: "20",
	materials: "8",
	workInProgress: "14",
	finishedGoods: "20",
	totalCurrentAssets: "76",
	plantAndEquipment: "12",
	realProperty: "12",
	totalFixedAssets: "24",
	assetsTotal: "100",
	shortTermLiabilities: "1",
	longTermLiabilities: "60",
	totalLiabilities: "61",
	beginningBalance: "37",
	netProfit: "2",
	totalEquity: "39",
	liabilityTotal: "100"
}

export default state;

import React from 'react';
import { connect } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// Components
import DroppableField from '../../common/DroppableField';


class Customers extends React.Component {

	draggingHandler = (objectType, isDragging, data) => {
		// Update Socket
		//RealtimeConnection.containerDragging("customers", objectType, isDragging, data)
	}

	addContainer = (alias, id, coins) => {
		this.props.addContainer("customers", alias, id, coins);
	}

	removeContainer = (alias, id) => {
		this.props.removeContainer("customers", alias, id);
	}

	removeModifiedContainer = (alias, id) => {
		this.props.removeModifiedContainer("customers", alias, id);
	}

	revertModifiedContainer = (alias, id) => {
		this.props.revertModifiedContainer("customers", alias, id);
	}

	addCoin = (alias, id, value) => {
		this.props.addCoin("customers", alias, id, value);
	}

	removeCoin = (alias, id) => {
		this.props.removeCoin("customers", alias, id);
	}

	increaseCoin = (alias, id) => {
		this.props.increaseCustomCoin("customers", alias, id, 1);
	}

	decreaseCoin = (alias, id) => {
		this.props.decreaseCustomCoin("customers", alias, id, 1);
	}

	render() {
		const dir = this.props.selectedLanguage == 8 ? "rtl" : "ltr"
		return (
			<div className="customersContainer">
				<div className={dir === "ltr" ? "customersText" : "customersText Ar"}>
					<p>
						<Trans>Board__Customers</Trans> 
						<Popup position='top center' content={ <Trans>Glossary__Customers</Trans> } size='mini' trigger={<Icon name='plus circle' style={{ marginTop: "10px", fontSize: 9 }} />} />
					</p>
				</div>
				<div className="remainingContainer">
					<p><Trans>Board__Remaining market potential</Trans></p>
					{
						<DroppableField
							id={ -1 }
							field={ this.props.board.customers.remainingMarket }
							block={ "customers" }
							alias={ "remainingMarket" }
							drawCoins={ false }
							maxCoins={ 100 }
							addContainer={ this.addContainer.bind(this) }
							removeContainer={ this.removeContainer.bind(this) }
							removeModifiedContainer={ this.removeModifiedContainer.bind(this) }
							revertModifiedContainer={ this.revertModifiedContainer.bind(this) }
							addCoin={ this.addCoin.bind(this) }
							removeCoin={ this.removeCoin.bind(this) }
							increaseCoin={ this.increaseCoin.bind(this) }
							decreaseCoin={ this.decreaseCoin.bind(this) }
							isDraggingHandler={ this.draggingHandler }
							boardState={ this.props.board }
						/>
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board,
		selectedLanguage: state.view.selectedLanguage
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
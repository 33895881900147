import React from 'react';
import { Image, Icon, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

// Dispatcher
import mapDispatchToProps from '../../../reducers/dispatcher';

// CSS
import '../../../css/boardDevelopment.css';

// Images
import bigGreenArrow from "../../../assets/Images/big-green-arrow.png";
import handShakeIcon from "../../../assets/Images/handshake-icon.svg";
import supportIcon from "../../../assets/Images/support-icon.svg";
import managementIcon from "../../../assets/Images/management-icon.svg";

class Development extends React.Component {
	render() {
		const dir = this.props.selectedLanguage == 8 ? "rtl" : "ltr"
		return (
			<div className="greenBlock" style={{ position: "relative" }}>
				<div className={dir === "ltr" ? "blockArrow" : "blockArrowAr"}>
					<Image src={ bigGreenArrow } />
				</div>
				<div className="atp-grid">
					<div className="atp-row">
						<div className="atp-column" style={{ flex: 1 }}>
							<div className="atp-row">
								<div className="atp-column" style={{ flex: 1 }}>
									<p className={dir === "ltr" ? "blockTitle" : "blockTitleAr"}><Trans>Board__Overhead & Development</Trans></p>
								</div>
							</div>
							<div className="atp-row developmentRow">
								<div className="atp-column" style={{ flex: 1 }}>
									<Popup position='top center' content={ <Trans>Glossary__Overhead & Development</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ flex: 1, paddingTop: 3 }}>
							<div className="atp-row">
								<div 
									className="atp-column" 
									style={
										dir === "ltr"
										? { display: "flex", height: 30, paddingRight: 10 }
										: { display: "flex", height: 30, paddingLeft: 10 }
								}>
									<Image 
										src={ handShakeIcon } 
										className="handshakeIcon" 
										style={dir === "ltr" ? { marginRight: 10 } : { marginLeft: 10 }} 
									/>
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle"><Trans>Board__Marketing & Sales</Trans></p>
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ flex: 1, paddingTop: 3 }}>
							<div className="atp-row">
								<div 
									className="atp-column" 
									style={
										dir === "ltr"
										? { display: "flex", height: 30, paddingRight: 10 }
										: { display: "flex", height: 30, paddingLeft: 10 }
								}>
									<Image 
										src={ supportIcon } 
										style={dir === "ltr" ? { marginRight: 10 } : { marginLeft: 10 }}
									/>
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle"><Trans>Board__Support</Trans></p>
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ flex: 1, paddingTop: 3 }}>
							<div className="atp-row">
								<div className="atp-column" style={{ display: "flex", height: 30 }}>
									<Image 
										src={ managementIcon } 
										style={dir === "ltr" ? { marginRight: 10 } : { marginLeft: 10 }} 
									/>
									<div className="dottedBorder"></div>
								</div>
								<div className="atp-column">
									<p className="blockSubTitle"><Trans>Board__Management</Trans></p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sequence" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 2) ?
						<div className="sequenceGuidedTourPopup">
							<div className="leftArrow"></div>
							<p>
								{ this.props.guidedTour.steps[2].message }
							</p>
							<div className="actions">
								{
									(this.props.guidedTour.steps[this.props.guidedTour.currentStep].step === 2 && 
										<>
											{ /*<div className="prevBtnContainer" onClick={ () => this.props.prevGuidedTour() }></div>*/ }
											<div className="nextBtnContainer" onClick={ () => this.props.nextGuidedTour() }></div>
										</>
									)
								}
							</div>
						</div>
						:
						null
				}
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		selectedLanguage: state.view.selectedLanguage,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Development);
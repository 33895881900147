import React from 'react';
import { Container, Grid, Image } from 'semantic-ui-react'
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

import URLs from '../../utils/urls';

// Components
import Production from './blocks/Production';
import Development from './blocks/Development';
import Investment from './blocks/Investment';
import Cash from './blocks/Cash';
import Owners from './blocks/Owners';
import Lenders from './blocks/Lenders';
import Community from './blocks/Community';
import CostOfSales from './blocks/CostOfSales';
import Depreciation from './blocks/Depreciation';
import DevelopmentBlocks from './blocks/DevelopmentBlocks';
import Customers from './blocks/Customers';

// images
import blueArrow from '../../assets/Images/blue-arrow.png';
import orangeArrow from '../../assets/Images/orange-arrow.png';
import celemiImage from '../../assets/Images/celemi-board-image.svg';

class MainBoard extends React.Component {

	render() {
		const dir = this.props.selectedLanguage == 8 ? "rtl" : "ltr"
		return (
			<div className="atp-grid boardGrid scaleDown" dir={dir}>
				<div className="atp-row boardGridRow">
					<div className="atp-column centerBlockContainer">
						<Container className="centerBlock">
							<Production />
							<Development />
							<Investment />
							<div className="whiteBlock">
								{
									(window.isDemo) ?
										<div className="demoWatermark">
											<h3><Trans>Board__Demo Version</Trans></h3>
											<p><Trans>Board__This version is not for sale.</Trans></p>
										</div>
										:
										null
								}
								{
									(window.partnerLogo) ?
										<div className="demoWatermark">
											<img src={ URLs.backendURL + window.partnerLogo } />
										</div>
										:
										null
								}
							</div>
							<Cash />
						</Container>
					</div>
					<div className="atp-column rightBlocksContainers">
						<div className="rightBlocks">
							<div className="blocksContainer">
								<div className={dir === "ltr" ? "text" : "textAr" }>
									<Trans>Board__Delivery</Trans>
								</div>
								<div className={dir === "ltr" ? "productionArrow" : "productionArrowAr" }>
									<Image src={ blueArrow } />
								</div>
								<div className={dir === "ltr" ? "productionArrowDotted" : "productionArrowDottedAr" }></div>
								<div className={dir === "ltr" ? "productionDotted" : "productionDottedAr" }></div>
								<div className={dir === "ltr" ? "developmentDotted" : "developmentDottedAr" }></div>
								<div className={dir === "ltr" ? "depreciationDotted" : "depreciationDottedAr" }></div>
								<Grid columns={3}>
									<Grid.Column className="depreciationContainer">
										<Depreciation />
									</Grid.Column>
									<Grid.Column className="developmentContainer">
										<DevelopmentBlocks />
									</Grid.Column>
									<Grid.Column className="costOfSalesContainer">
										<CostOfSales />
									</Grid.Column>
								</Grid>
							</div>
							<div className="salesDashContainer">
								<div className={dir === "ltr" ? "borderBox" : "borderBoxAr" }></div>
								<div className={dir === "ltr" ? "purchaseArrow" : "purchaseArrowAr" }>
									<Image src={ orangeArrow } />
								</div>
								<div className={dir === "ltr" ? "text" : "textAr" }>
									<Trans>Board__Sales</Trans>
								</div>
							</div>
						</div>
						<Customers />
					</div>
				</div>
				<div className="atp-row boardGridRow">
					<div className="atp-column centerBlockContainer bottomCenterBlock">
						<div className="atp-grid" style={{ height: "100%" }}>
							<div className="atp-row">
								<div className="atp-column" style={{ width: "33%" }}>
									<Owners />
								</div>
								<div className="atp-column" style={{ width: "1%" }}></div>
								<div className="atp-column" style={{ width: "66%" }}>
									<div className="atp-grid" style={{ height: "100%" }}>
										<div className="atp-row">
											<div className="atp-column" style={{ width: "50%" }}>
												<Lenders />
											</div>
											<div className="atp-column" style={{ width: "3px" }}></div>
											<div className="atp-column" style={{ width: "50%" }}>
												<Community />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={dir === "ltr" ? "atp-column boardCelemiLogo" : "atp-column boardCelemiLogoAr"}>
						<Image src={ celemiImage } style={{ width: "90%" }} />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		selectedLanguage: state.view.selectedLanguage
	}
}

export default connect(mapStateToProps)(MainBoard);
import mainState from "./initialStates/empty-form-state";
import initState from "./initialStates/form-state";

const reducer = (state = initState, action) => {
	if (action.type === "REMOVE_FORM") {
		const profitLossDefault = { ...mainState.ProfitLossStatement }
		const balanceSheetDefault = { ...mainState.BalanceSheet }
		const businessReviewDefault = { 
			dupont: { ...mainState.BusinessReview.dupont },
			indicators: { ...mainState.BusinessReview.indicators }
		}
		const cashFlowDefault = {
			workingCapital: { ...mainState.CashFlow.workingCapital },
			operations: { ...mainState.CashFlow.operations },
			investment: { ...mainState.CashFlow.investment },
			financing: { ...mainState.CashFlow.financing },
			cashBalance: { ...mainState.CashFlow.cashBalance }
		}

		return {
			...state,
			ProfitLossStatement: { ...profitLossDefault },
			BalanceSheet: { ...balanceSheetDefault },
			BusinessReview: { ...businessReviewDefault },
			CashFlow: { ...cashFlowDefault }
		}
	}else if (action.type === "UPDATE_FORM") {
		return {
			...state,
			[action.form]: action.value
		}
	}else if (action.type === "ProfitLossStatement") {
		state[action.type][action.field] = action.value
		
		return {
			...state,
			ProfitLossStatement: {
				...state.ProfitLossStatement
			}
		}
	}else if (action.type === "BalanceSheet") {
		state[action.type][action.field] = action.value;

		return {
			...state,
			BalanceSheet: {
				...state.BalanceSheet
			}
		}
	}else if(action.type === "BusinessReview") {
		state[action.type][action.key][action.field] = action.value;

		return {
			...state,
			BusinessReview: {
				...state.BusinessReview
			}
		}
	}
	else if(action.type === "CashFlow") {
		state[action.type][action.key][action.field] = action.value;

		return {
			...state,
			CashFlow: {
				...state.CashFlow
			}
		}
	}

	return state;
}

export default reducer;
import React from 'react';
import axios from 'axios';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import promiseMiddleware from "redux-promise";
import ReduxThunk from "redux-thunk";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'

// CSS
import 'semantic-ui-css/semantic.min.css'
import './css/main.css';
import './css/board.css';
import './css/smallBoard.css';
import './css/financialStatementBlock.css';
import './css/grid.css';
import 'rc-tooltip/assets/bootstrap.css';
import "./css/cashFlow.css";
import "./css/chat.css";
import "./css/optionalCashFlow.css";

// Components
import MainPage from './components/MainPage';

// Utils
import reducer from "./reducers/index";
import URLs from "./utils/urls";

const createStoreWithMiddleware = applyMiddleware(
	promiseMiddleware,
	ReduxThunk
)(createStore);
//const store = createStore(reducer);

/*function findGetParameter(parameterName) {
	let result = null,
		tmp = [];
	let items = window.location.search.substr(1).split("&");
	for (let index = 0; index < items.length; index++) {
		tmp = items[index].split("=");
		if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
	}
	return result;
}*/

class App extends React.Component {

	state = {
		languages: []
	}

	componentDidMount() {
		
	}

	render() {
		return (
			<Provider store={createStoreWithMiddleware(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())}>
				<DndProvider backend={HTML5Backend}>
					<I18nextProvider i18n={i18n}>
						<MainPage languages={ this.state.languages } />
					</I18nextProvider>
				</DndProvider>
			</Provider>
		);
	}
}

export default App;

import React, { Component } from "react";
import { Grid } from "semantic-ui-react";

class DupontCell extends Component {
	state = {};
	render() {
		return (
			<Grid
				columns={2}
				className="dupontCell"
				style={{
					backgroundColor: this.props.backgroundColor
						? this.props.backgroundColor
						: "#144e5d",
				}}
			>
				<Grid.Row>
					<Grid.Column className="cellTitle">
						{" "}
						<div>{this.props.header}</div>{" "}
					</Grid.Column>
				</Grid.Row>

				<Grid.Row>
					<Grid.Column>
						{" "}
						<div>{this.props.defaultValue}</div>{" "}
					</Grid.Column>
					<Grid.Column>
						<Grid.Column>
							<p>
								<input 
									className={ `${this.props.ltr ? "cellField" : "cellFieldAr"} ${ this.props.className }` }
									value={ this.props.value } 
									onChange={ e => { if(this.props.onFieldChange) this.props.onFieldChange(this.props.fieldName, e.target.value) } } 
									disabled={ this.props.disabled }
								/>
							</p>
						</Grid.Column>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

export default DupontCell;

import React from 'react';
import { connect } from 'react-redux';
import { Header, Icon, Image, Popup } from 'semantic-ui-react'
import { Trans } from 'react-i18next';

// CSS
import '../../../css/boardProduction.css';

// Images
import bigBlueArrow from "../../../assets/Images/big-blue-arrow.png";


class Production extends React.Component {
	
	render() {
		const dir = this.props.view.selectedLanguage == 8 ? "rtl" : "ltr"
		return (
			<div className="blueBlock">
				<Header as='h4' className="blockTitle white">
					<Trans>Board__Production</Trans>
					<Popup position='top center' content={ <Trans>Glossary__Production</Trans> } size='mini' trigger={<Icon name='plus circle' />} />
				</Header>
				<div className="blockArrow">
					<Image src={bigBlueArrow} />
				</div>
				<div className="atp-grid">
					<div className="atp-row" style={{ height: "20px" }}>
						<div className="atp-column" style={{ width: "128px" }}></div>
						<div className="atp-column" style={{ flex: 1 }}></div>
						<div className="atp-column" style={{ width: "calc(100% - 332px)"}}>
							<p className="smallSubtitle">
								<Trans>Board__Manufacturing & Assembly</Trans>
							</p>
						</div>
						<div className="atp-column" style={{ flex: 1 }}></div>
						<div className="atp-column" style={{ width: "204px" }}></div>
					</div>
					<div className="atp-row">
						<div className="atp-column" style={{ width: "128px", marginRight: "5px" }}>
							<div className="centerContainers">
								<div className="stockOfMatrialsContainer">
									<div className={ `droppableField` }></div>
									<div className={ `droppableField` } style={{ position: "relative" }}>
										<div className="cashflowPointer" onClick={ () => { this.props.onSelectQuestion(0); this.props.onToggleModal() }}>
											{
											(this.props.view.showCashflowExerciseResult) ?
												<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["1"] }` }>1</div>
												:
												<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["1"]) ? 'finished': '' }` }>1</div>
											}
										</div>
									</div>
									<div className={ `droppableField` }></div>
									<div className={ `droppableField` }></div>
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ flex: 1, display: "flex" }}>
							<div className="dottedBorder"></div>
						</div>
						<div className="atp-column" style={{ width: "140px"}}>
							<div className="centerContainers">
								<div className="manufacturingContainer" style={{ position: 'relative' }}>
									<div className="cashflowPointer" onClick={ () => { this.props.onSelectQuestion(1); this.props.onToggleModal() }}>
										{
										(this.props.view.showCashflowExerciseResult) ?
											<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["2"] }` }>2</div>
											:
											<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["2"]) ? 'finished': '' }` }>2</div>
										}
									</div>
									<div className={ `droppableField` }></div>
									<div className={ `droppableField` }></div>
									<div className="cashflowPointer second" onClick={ () => { this.props.onSelectQuestion(2); this.props.onToggleModal() }}>
										{
										(this.props.view.showCashflowExerciseResult) ?
											<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["3"] }` }>3</div>
											:
											<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["3"]) ? 'finished': '' }` }>3</div>
										}
									</div>
								</div>
							</div>
						</div>
						<div className="atp-column" style={{ flex: 1, display: "flex" }}>
							<div className="dottedBorder"></div>
						</div>
						<div className="atp-column" style={{ width: "204px", marginLeft: "5px" }}>
							<div className="centerContainers">
								<div className="stockOfMatrialsContainer">
									<div className={ `droppableField` } style={{ position: 'relative' }}>
										<div className="cashflowPointer" onClick={ () => { this.props.onSelectQuestion(3); this.props.onToggleModal() }}>
											{
											(this.props.view.showCashflowExerciseResult) ?
												<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["4"] }` }>4</div>
												:
												<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["4"]) ? 'finished': '' }` }>4</div>
											}
										</div>
									</div>
									<div className={ `droppableField` }></div>
									<div className={ `droppableField` }></div>
									<div className={ `droppableField` } style={{ position: 'relative' }}>
										<div className="cashflowPointer" onClick={ () => { this.props.onSelectQuestion(5); this.props.onToggleModal() }}>
											{
											(this.props.view.showCashflowExerciseResult) ?
												<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ this.props.correctAnswers["6"] }` }>6</div>
												:
												<div className={ `${dir === "rtl" ? "circle-rtl" : "circle"} ${ (this.props.session.optionalCashFlow["6"]) ? 'finished': '' }` }>6</div>
											}
										</div>
									</div>
									<div className={ `droppableField` }></div>
									<div className={ `droppableField` }></div>
								</div>
							</div>
						</div>
					</div>
					<div className="atp-row" style={{ height: "25px" }}>
						<div className="atp-column" style={{ width: "128px", display: "flex" }}>
							<p className="containerText"><Trans>Board__Stock of Materials</Trans></p>
						</div>
						<div className="atp-column" style={{ flex: 1 }}></div>
						<div className="atp-column" style={{ width: "calc(100% - 332px)", display: "flex" }}>
							<p className="centerContainerText"><Trans>Board__Work in progress</Trans></p>
						</div>
						<div className="atp-column" style={{ flex: 1 }}></div>
						<div className="atp-column" style={{ width: "204px", display: "flex" }}>
							<p className="containerText"><Trans>Board__Stock of finished goods</Trans></p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		view: state.view,
		session: state.session
	}
}

export default connect(mapStateToProps)(Production);
import React, { useState } from "react";
import { useDrop } from 'react-dnd';
import moment from 'moment';
import Tooltip from 'rc-tooltip';

// Components
import DraggableContainer from "./DraggableContainer";

export default function DroppableField(props) {
	const [canDrop, setCanDrop] = useState(true);
	const [isHover, setIsHover] = useState(false);

	const [{ fieldId }, drop] = useDrop({
		accept: "Container",
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			//canDrop: monitor.canDrop(),
			fieldId: monitor.getHandlerId()
		}),
		drop: (item, monitor) => {
			if (monitor.getHandlerId() === item.fieldId) {
				return {};
			}
			
			if (props.field.hasContainer) {
				if (props.alias === "liabilities") {
					props.addContainer(props.alias, props.id, -item.coins);
				}else{
					props.addContainer(props.alias, props.id, item.coins);
				}
				return item
			}else{
				props.addContainer(props.alias, props.id, item.coins);
				return item
			}
		},
	})

	function handleCanDropChange(newValue) {
		setCanDrop(newValue);
	}

	let allowToDrop = (props.alias === "goodsSold" && props.field.hasContainer) ? null : drop;

	let glowFieldClass = ""
	if (props.field.droppedDate) {
		const currentDate = moment()
		const duration = moment.duration(currentDate.diff(props.field.droppedDate));
		if (duration.as('seconds') < 5) {
			glowFieldClass = "glow"
		}
	}

	// && props.field.type === "CONTAINER"
	let showTooltip = (props.field.isDragging) ? true : false
	if (!showTooltip) {
		if (isHover && props.field.playerId) {
			showTooltip = true
		}
	}/*else if (props.data.playerId === window.playerId){
		showTooltip = false
	}*/

	if (props.field.isDragging) {
		allowToDrop = null
	}

	return (
		<div
			className={ `${props.ltr ? "droppableField" : "droppableField Ar"} ${ (props.field.hasContainer) ? "whiteBG" : "" } ${ glowFieldClass }` }
			style={ (props.id === 8) ? { position: "relative", zIndex: 1 } : {} }
			ref={ (canDrop) ? allowToDrop : null }
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			{(
				(props.field.hasContainer) ?
					<Tooltip
						placement="bottom"
						align={{
							offset: [0, 5],
						}}
						visible={ showTooltip }
						overlay={<span>{ props.field.playerName }</span>}
					>
						<DraggableContainer 
							fieldId={ fieldId }
							block={ props.block }
							alias={ props.alias }
							id={ props.id }
							coins={ props.field.coins } 
							modifiedCoins={ props.field.modifiedCoins }
							isDragging={ props.field.isDragging }
							draggingField={ props.field.draggingField }
							playerId={ props.field.playerId }
							playerName={ props.field.playerName }
							drawCoins={ props.drawCoins }
							maxCoins={ props.maxCoins }
							removeContainer={ props.removeContainer }
							removeModifiedContainer={ props.removeModifiedContainer }
							revertModifiedContainer={ props.revertModifiedContainer }
							addCoin={ props.addCoin }
							removeCoin={ props.removeCoin }
							increaseCoin={ props.increaseCoin }
							decreaseCoin={ props.decreaseCoin }
							canDropValue={ canDrop }
							canDropAction={ handleCanDropChange }
							onHoverLeave={ () => setIsHover(false)}
							isDraggingHandler={ props.isDraggingHandler }
						/>
					</Tooltip>
					:
					null
			)}
		</div>
	);
}
const initState = {
	teamsName: {},
	requestHelp: false,
	year: {
		title: "Year 1",
		value: 1
	},
	selectedFormYear: {
		title: "Year 1",
		value: 1
	},
	period: 1,
	selectedPeriod: 1,
	checklist: [],
	currentChecklistIndex: 0,
	completedChecklistIndex: -1,
	team: {
		name: "Alpha 1",
		SessionPlayers: [{
			Player: {
				name: "Player 1"
			}
		}]
	},
	players: [],
	playersForm: {},
	isFormValidated: {
		ProfitLossStatement: false,
		BalanceSheet: false
	},
	isFormSubmitted: {
		ProfitLossStatement: false,
		BalanceSheet: false
	},
	strategies: [],
	selectedStrategy: {},
	projects: [],
	projectsDepartments: [],
	selectedProjects: [],
	teamSelectedProjects: {},
	teamsProjects: [],
	forms: {
		ProfitLossStatement: {
			sales: "",
			costOfGoodsSold: "",
			contribution: "",
			overhead: "",
			development: "",
			depreciation: "",
			totalCommonCosts: "",
			ebit: "",
			interestExpense: "",
			profitBeforeTaxes: "",
			taxes: "",
			netProfit: ""
		},
		BalanceSheet: {
			cash: "",
			accountRecievable: "",
			materials: "",
			workInProgress: "",
			finishedGoods: "",
			totalCurrentAssets: "",
			plantAndEquipment: "",
			realProperty: "",
			totalFixedAssets: "",
			assetsTotal: "",
			shortTermLiabilities: "",
			longTermLiabilities: "",
			totalLiabilities: "",
			beginningBalance: "",
			netProfit: "",
			totalEquity: "",
			liabilityTotal: ""
		},
		BusinessReview: {
			dupont: {
				sales: "",
				costOfGoodsSold: "",
				contribution: "",
				commonCosts: "",
				operatingProfit: "",
				interestAndTaxes: "",
				netProfit: "",
				returnONAssets: "",
				returnONEquity: "",
				currentAssets: "",
				fixedAssets: "",
				totalAssets: "",
				equity: "",
				liabilities: "",
			},
			indicators: {
				contribution: "",
				contributionSales: "",
				operatingProfit: "",
				operatingSales: "",
				equity: "",
				assets: ""
			}
		},
		CashFlow: {
			workingCapital: {
				accountsReceivable: "",
				materials: "",
				workInProgress: "",
				finishedGoods: "",
				accountsPayable: "",
				workingCapital: "",
				changeInWorkingCapital: ""
			},
			operations: {
				sales: "",
				costOfGoodsSold: "",
				overheads: "",
				development: "",
				taxPaidForYear1: "",
				interestExpenses: "",
				changeInWorkingCapital: "",
				cashFlowFromOperations: ""
			},
			investment: {
				plantAndEquipment: "",
				realProperty: "",
				intellectualProperty: "",
				cashFlowFromInvestments: ""
			},
			financing: {
				changeInLoans: "",
				dividends: "",
				cashFlowFromFinancing: ""
			},
			cashBalance: {
				cashAtTheStart: "",
				cashFlowThisYear: "",
				cashAtYearEnd: ""
			}
		}
	},
	optionalCashFlow: {},
	enableOptionalCashFlowShowResultBtn: false,
	showYear3Step: {
		totalImprovements: false,
		year3Ebit: false,
		year2Ebit: false,
		totalEbit: false
	},
	downloadFile: false
};

export default initState;